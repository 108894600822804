import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { TextField, Button, Typography, Container, Grid, MenuItem, FormControl, InputLabel, Select } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';  // Fixed the import for toast
import 'react-toastify/dist/ReactToastify.css';
import bgImage from '../../../assets/images/5040007.jpg';

import { usePost } from '../../../helpers/axios/useApi';
import { useSelector } from 'react-redux';
import Loading from '../../../assets/Loading/Index';

// Validation schema
const schema = yup.object().shape({
  companyName: yup.string().required('Company name is required'),
  street: yup.string().required('Street is required'),
  state: yup.string().required('State is required'),
  postalCode: yup.string().required('Postal code is required'),
  country: yup.string().required('Country is required'),
  city: yup.string().required('City is required'),
  businessType: yup.string().required('Business type is required'),
});





/**
 * UserInformation component
 *
 * This component is used to collect additional user information during the
 * registration process. It is called after the user has successfully verified
 * their email address.
 *
 * @param {function} onStageComplete - Called when the user clicks the "Submit"
 *   button. It should call the next stage of the registration process.
 *
 * @returns {React.Component} - The UserInformation component.
 */

function UserInformation({ onStageComplete }) {
  const { control, handleSubmit, setValue, getValues, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
  });
  const ragistrationProgress = useSelector(state => state.ragistrationProgress);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [loading, setLoading] = useState(false);
  const handleRagister = usePost('/user/ragister');
  
  // Define business types
  const businessTypes = [
    { value: 'agriculture', label: 'Agriculture' },
    { value: 'automotive', label: 'Automotive' },
    { value: 'construction', label: 'Construction' },
    { value: 'consulting', label: 'Consulting' },
    { value: 'education', label: 'Education' },
    { value: 'entertainment', label: 'Entertainment' },
    { value: 'finance', label: 'Finance' },
    { value: 'healthcare', label: 'Healthcare' },
    { value: 'hospitality', label: 'Hospitality' },
    { value: 'information_technology', label: 'Information Technology' },
    { value: 'insurance', label: 'Insurance' },
    { value: 'legal', label: 'Legal' },
    { value: 'manufacturing', label: 'Manufacturing' },
    { value: 'marketing', label: 'Marketing' },
    { value: 'media', label: 'Media' },
    { value: 'nonprofit', label: 'Nonprofit' },
    { value: 'real_estate', label: 'Real Estate' },
    { value: 'retail', label: 'Retail' },
    { value: 'services', label: 'Services' },
    { value: 'technology', label: 'Technology' },
    { value: 'transportation', label: 'Transportation' },
    { value: 'utilities', label: 'Utilities' },
    { value: 'wholesale', label: 'Wholesale' },
    // Add more business types as needed
  ];
  

  useEffect(() => {
    const countryOptions = Country.getAllCountries().map(country => ({
      value: country.isoCode,
      label: country.name
    }));
    setCountries(countryOptions);
  }, []);

  const handleCountryChange = (event) => {
    const selectedCountry = event.target.value;
    setValue('country', selectedCountry);

    // Fetch states based on selected country
    const stateOptions = State.getStatesOfCountry(selectedCountry).map(state => ({
      value: state.isoCode,
      label: state.name
    }));
    setStates(stateOptions);
    setValue('state', '');
    setCities([]);
  };

  const handleStateChange = (event) => {
    const selectedState = event.target.value;
    setValue('state', selectedState);

    // Fetch cities based on selected state
    const selectedCountry = getValues('country');
    const cityOptions = City.getCitiesOfState(selectedCountry, selectedState).map(city => ({
      value: city.name,
      label: city.name
    }));
    setCities(cityOptions);
  };

  const onSubmit = async (data) => {
    try {
      const submitData = {
        email: ragistrationProgress.email,
        phone: ragistrationProgress.phoneNumber,
        password: ragistrationProgress.password,
        companyName: data.companyName,
        companyAddress: {
          street: data.street,
          state: data.state,
          postalCode: data.postalCode,
          country: data.country,
          city: data.city,
        },
        businessType: data.businessType // Add businessType to submission data
      };
     
      setLoading(true);
      const res = await handleRagister.mutateAsync(submitData);

      if (res.data !== null) {
        toast.success(res.data.message);
        localStorage.setItem("user", JSON.stringify({ data: res?.data?.data}));
        onStageComplete();
      } else {
        toast.error(res.error.message);
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      toast.error('An error occurred');
    } finally {
      setLoading(false);
    }
  };

  // Inline styles
  const backgroundContainerStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundImage: `url(${bgImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    zIndex: 2,
  };

  const overlayStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    zIndex: 1,
  };

  const formContainerStyle = {
    position: 'absolute',
    zIndex: 999,
    top: '55%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '8px',
    maxWidth: '600px',
    width: '100%',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
  };

  return (
    <div style={backgroundContainerStyle}>
      <div style={overlayStyle} />
      {loading && <Loading />}
      <ToastContainer />
      <Container component="main" style={formContainerStyle}>
        <form onSubmit={handleSubmit(onSubmit)} style={{ padding: '30px' }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                name="companyName"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    fullWidth
                    label="Company Name"
                    error={!!errors.companyName}
                    helperText={errors.companyName ? errors.companyName.message : ''}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="street"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    fullWidth
                    label="Street"
                    error={!!errors.street}
                    helperText={errors.street ? errors.street.message : ''}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="country"
                control={control}
                render={({ field }) => (
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel>Country</InputLabel>
                    <Select
                      {...field}
                      value={field.value || ''}
                      onChange={handleCountryChange}
                      label="Country"
                    >
                      {countries.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                    {errors.country && <Typography color="error">{errors.country.message}</Typography>}
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="state"
                control={control}
                render={({ field }) => (
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel>State</InputLabel>
                    <Select
                      {...field}
                      value={field.value || ''}
                      onChange={handleStateChange}
                      label="State"
                      disabled={!states.length}
                    >
                      {states.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                    {errors.state && <Typography color="error">{errors.state.message}</Typography>}
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="city"
                control={control}
                render={({ field }) => (
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel>City</InputLabel>
                    <Select
                      {...field}
                      value={field.value || ''}
                      label="City"
                      disabled={!cities.length}
                    >
                      {cities.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                    {errors.city && <Typography color="error">{errors.city.message}</Typography>}
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="postalCode"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    fullWidth
                    label="Postal Code"
                    error={!!errors.postalCode}
                    helperText={errors.postalCode ? errors.postalCode.message : ''}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="businessType"
                control={control}
                render={({ field }) => (
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel>Business Type</InputLabel>
                    <Select
                      {...field}
                      value={field.value || ''}
                      label="Business Type"
                    >
                      {businessTypes.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                    {errors.businessType && <Typography color="error">{errors.businessType.message}</Typography>}
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                sx={{
                    padding:"10px 20px",
                    width:"40%",
                    
                  }}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </div>
  );
}

export default UserInformation;
