import React from "react";
import { useAuth } from "../../middlewares/auth";
import { useGet, usePost } from "../../helpers/axios/useApi";
import { Grid } from "@mui/system";
import { CircularProgress, IconButton } from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';  // Approve icon
import CancelIcon from '@mui/icons-material/Cancel';  // Reject icon
const HRMPopover = ({ data }) => {
    if (!data) return null;
    const {user} = useAuth() 
   console.log(data,'data')

    const {
      data: employees,
      refetch: refetchEmployees,
      isLoading,
    } = useGet("employee/all", {}, {}, { queryKey: "employees" });

    const getEmployeeName = (id) => {
      if (!employees?.data?.message || !Array.isArray(employees?.data?.message[0])) {
        return "Unknown";
      }
  
      const employee = employees?.data?.message[0].find((emp) => emp._id === id);
      return employee ? `${employee.name}` : "Unknown";
    };

  
    
    return (
      <div id="popoveHRM"
        style={{
          position: "absolute",
  
          top: "45%",
          left: "83%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "#fff",
          width: "250px",  // Adjusted width to be smaller
          maxHeight: "280px",  // Maximum height fixed at 280px
          color: "#333",
          padding: "12px",
          borderRadius: "20px",
          boxShadow: "0 4px 12px rgba(0, 0, 0, 0.2)",
          zIndex: 10,
          fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
          overflowY: "auto", // Allows scrolling if content exceeds height
        }}
      >
        {data?.map((emp) => (
          <div
            key={emp._id}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
              flexDirection: "column",
              marginBottom: "12px", // Reduced margin for a smaller card
              padding: "12px",  // Reduced padding
              borderRadius: "8px",
              backgroundColor: "#f4f6f9",
              boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
              fontSize: "14px",
              fontWeight: "400",
              transition: "transform 0.2s, box-shadow 0.2s",
            }}
          >
            <div style={{ flex: 1 }}>
              <div
                style={{
                  fontSize: "12px",
                  fontWeight: "600",
                  color: "#333",
                  maxWidth: "150px",
                  marginBottom: "5px",
                }}
              >
                {getEmployeeName(emp[0]?.userId ? emp[0]?.userId : emp?.userId)} {/* Employee Name */}
              </div>
  
              
            </div>
  
            {emp[0]?.firstPunchIn ? (
                <div
                style={{
                  marginLeft: "12px", // Reduced margin for the right side
                  textAlign: "right",
                  flexShrink: 0,
                }}
              >
                <div
                  style={{
                    fontSize: "12px",  // Reduced font size
                    color: "#444",
                    marginBottom: "5px",
                    fontWeight: "bold",
                  }}
                >
                    
                  {new Date(emp[0]?.firstPunchIn).toLocaleString()} {/* Format the date */}
                </div>
              </div>
            ):(
                <div
                style={{
                  fontSize: "12px",  // Reduced font size
                  color: "#444",
                  display: "flex",
                  gap: "5px",
                }}
              >
                <strong>Status:</strong>
                <span
                  style={{
                    color: "#d9534f",
                    fontWeight: "500",
                  }}
                >
                  {'Absent'}
                </span>
              </div>
            )}
   
          </div>
          
        ))}
      </div>
    );
  };

  export default HRMPopover