import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, CircularProgress, TextField, Paper, Snackbar, Alert } from '@mui/material';
import { Visibility, Send, CheckCircle, Edit, Link as LinkIcon, ColorLens, Image as ImageIcon ,Delete as DeleteIcon} from '@mui/icons-material';
import { usePost } from '../../../../helpers/axios/useApi';
import ReactFroalaEditor from 'react-froala-wysiwyg'; // Import Froala editor
import 'froala-editor/css/froala_editor.pkgd.min.css'; // Import Froala styles
import 'froala-editor/css/froala_style.min.css'; // Froala default styles
import 'froala-editor/js/plugins.pkgd.min.js'; // Froala plugins

const ProposalDialog = ({ proposal, open, close ,leadDetail, updated}) => {
  const [loading, setLoading] = useState(false);
  const [approvalStatus, setApprovalStatus] = useState(proposal.approvalStatus);
  const [status, setStatus] = useState(proposal.status);
  const [isEditing, setIsEditing] = useState(false);
  const [editedProposalData, setEditedProposalData] = useState(proposal.proposalData);
  const [linkUrl, setLinkUrl] = useState(null);
  const [backgroundUrl, setBackgroundUrl] = useState('');
  const [backgroundColor, setBackgroundColor] = useState('');
  const [emailDialogOpen, setEmailDialogOpen] = useState(false);
    // Snackbar state
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [emailDetails, setEmailDetails] = useState({
    to: leadDetail?.email,
    subject: '',
    cc: '',
    bcc: ''
  });
  const handleApprovalStatusChange = usePost('/leads/proposal/update-approvel-status');
  const handleSubmitProposal = usePost('/leads/proposal/update');
 const updateStatus = usePost('/leads/proposal/update-status')
  const handleClose = () => close();
  const handleVideoUpload = () => {}; // Define video upload logic

  

  const handleEditToggle = () => setIsEditing(!isEditing);

  const handleProposalChange = (newValue) => setEditedProposalData(newValue);

  const handleApprove = async () => {
    setLoading(true);
    try {
      const data = { proposalId: proposal._id, approvalStatus: 'Approved' };
      const res = await handleApprovalStatusChange.mutateAsync(data);
      if (res?.data) {
        setSnackbarMessage('Proposal approved successfully!');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
        updated()
      }
    } catch (error) {
      console.error('Failed to update approval status', error);
      setSnackbarMessage('Failed to approve proposal.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const handleReview = async () => {
    setLoading(true);
    try {
      const data = { proposalId: proposal._id, approvalStatus: 'Review' };
      const res = await handleApprovalStatusChange.mutateAsync(data);
      if (res?.data) {
        setSnackbarMessage('Proposal sent for review!');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
        updated()
      }
    } catch (error) {
      console.error('Failed to update approval status', error);
      setSnackbarMessage('Failed to send proposal for review.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const handleSend = async () => {
    setLoading(true);
    try {
      const data = {
        proposalId: proposal?._id,
        status: 'Sent',
        emailDetails
      };
      const res = await updateStatus.mutateAsync(data);
      if (res?.data) {
        setStatus('Sent');
        setSnackbarMessage('Proposal sent successfully!');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
        updated()
      }
    } catch (error) {
      console.error('Failed to update status', error);
      setSnackbarMessage('Failed to send proposal.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitEdit = async () => {
    setLoading(true);
    try {
      const data = { proposalId: proposal._id, proposalData: editedProposalData };
      console.log('Proposal submitted with data:', data); 
      const res = await handleSubmitProposal.mutateAsync(data);
      if (res?.data) {
        proposal.proposalData = editedProposalData;
        setIsEditing(false);
        setSnackbarMessage('Proposal updated successfully!');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
        updated()
      }
    } catch (error) {
      console.error('Failed to submit changes', error);
      setSnackbarMessage('Failed to update proposal.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setApprovalStatus(proposal.approvalStatus);
    setStatus(proposal.status);
    setEditedProposalData(proposal.proposalData);
  }, [proposal]);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleImageUpload = (file, insertImage) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      insertImage(reader.result); // Insert Base64 image
    };
    reader.readAsDataURL(file);
  };

  const handleLinkInsert = (linkUrl) => {
    if (linkUrl) {
      setEditedProposalData((prevData) => {
        const button = `
          <button 
            class="open-link-btn" 
            style="padding: 10px 20px; background-color: #de6fae; color: #fff; border-radius: 8px; cursor: pointer; font-size: 16px; transition: background-color 0.3s ease;">
            Open Link
          </button>
        `;
        
        const lastDivIndex = prevData.lastIndexOf('</div>');
           
        if (lastDivIndex !== -1) {
          return prevData.slice(0, lastDivIndex) + button + prevData.slice(lastDivIndex);
        } else {
          return prevData + button;
        }
      });
    }
  };
  
  // Add the event listener using useEffect
  useEffect(() => {
    // Select the button by its class name
    const button = document.querySelector('.open-link-btn');
    if (button) {
      button.addEventListener('click', () => {
        window.open(linkUrl, '_blank');
      });
    }
  
    // Cleanup the event listener when the component re-renders
    return () => {
      if (button) {
        button.removeEventListener('click', () => {
          window.open(linkUrl, '_blank');
        });
      }
    };
  }, [editedProposalData]);

  const handleBackgroundChange = () => {
    if (backgroundUrl) {
      // Insert background image with a transparent white overlay (watermark effect)
      setEditedProposalData(`
        <div style="position: relative; background-image: url('${backgroundUrl}'); background-size: cover; background-position: center; padding: 20px; color: #333; font-size: 16px; line-height: 1.5;">
          <div style="position: absolute; top: 0; left: 0; right: 0; bottom: 0; background: rgba(255, 255, 255, 0.3); pointer-events: none;"></div>
          <div style="position: relative; z-index: 1;">
            ${editedProposalData}
          </div>
        </div>
      `);
    } else if (backgroundColor) {
      // Insert background color with a transparent white overlay
      setEditedProposalData(`
        <div style="background-color: ${backgroundColor}; padding: 20px; color: #333; font-size: 16px; line-height: 1.5;">
          <div style="position: absolute; top: 0; left: 0; right: 0; bottom: 0; background: rgba(255, 255, 255, 0.3); pointer-events: none;"></div>
          <div style="position: relative; z-index: 1;">
            ${editedProposalData}
          </div>
        </div>
      `);
    }
  };
  const handleRemoveBackgroundImage = () => {
    setBackgroundUrl('');
    // Remove background image and transparent overlay
    const updatedProposalData = editedProposalData.replace(
      /<div[^>]*style="[^"]*background-image[^"]*"[^>]*>/, // Match div with background-image style
      '<div style="'
    ).replace(
      /background-image[^"]*;?[^>]*>/, // Remove background-image style from div
      '">'
    );
    
    setEditedProposalData(updatedProposalData);
  };
  
  const handleRemoveBackgroundColor = () => {
    setBackgroundColor('');
    // Remove background color and transparent overlay
    const updatedProposalData = editedProposalData.replace(
      /<div[^>]*style="[^"]*background-color[^"]*"[^>]*>/, // Match div with background-color style
      '<div style="'
    ).replace(
      /background-color[^"]*;?[^>]*>/, // Remove background-color style from div
      '">'
    );
    
    setEditedProposalData(updatedProposalData);
  };

  const getStatusStyle = (statusType) => {
    switch (statusType) {
      case 'Pending': return { backgroundColor: '#e8e0c1', color: '#f5c60a', borderRadius: '8px', padding: '4px 8px', margin: 3 };
      case 'Sent': return { backgroundColor: '#caebcb', color: '#47de4f', borderRadius: '8px', padding: '4px 8px', margin: 3 };
      default: return { backgroundColor: '#e8e0c1', color: '#f5c60a', borderRadius: '8px', padding: '4px 8px', margin: 3 };
    }
  };

  const getApprovalStatusStyle = (approvalStatusType) => {
    switch (approvalStatusType) {
      case 'Pending': return { backgroundColor: '#ffecb3', color: '#ff6f00', borderRadius: '8px', padding: '4px 8px', margin: 3 };
      case 'Review': return { backgroundColor: '#fff3e0', color: '#ff9100', borderRadius: '8px', padding: '4px 8px', margin: 3 };
      case 'Approved': return { backgroundColor: '#c8e6c9', color: '#388e3c', borderRadius: '8px', padding: '4px 8px', margin: 3 };
      default: return { backgroundColor: '#ffecb3', color: '#ff6f00', borderRadius: '8px', padding: '4px 8px', margin: 3 };
    }
  };
  const handleEmailDialogClose = () => setEmailDialogOpen(false);

  const handleEmailChange = (e) => {
    const { name, value } = e.target;
    setEmailDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value
    }));
  };
  const handleOpenEmailDialog = () => setEmailDialogOpen(true);
  return (
    <>
     <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
<Dialog
  open={open}
  onClose={handleClose}
  PaperProps={{
    sx: {
      width: "130vh",  // You can modify this if you want a specific width
      padding: 2,
      height: "70vh",  // Set height or let it adjust automatically
      borderRadius: '20px',
      boxShadow: '0 8px 16px rgba(0,0,0,0.2)',
      transform: 'perspective(1px) translateZ(0)',
      transition: 'all 0.3s ease-in-out',
      resize: 'both',   // This allows resizing both horizontally and vertically
      overflow: 'auto', // Ensure content scrolls if needed
      maxWidth: '100%', // Prevent exceeding the parent width
      maxHeight: '100vh', // Prevent exceeding the viewport height
    },
  }}
>
        <DialogTitle>Proposal Details</DialogTitle>
        <DialogContent>
          {isEditing ? (
            <>
              <ReactFroalaEditor
                model={editedProposalData}
                onModelChange={handleProposalChange}
                config={{
                  heightMin: 400,
                  placeholderText: 'Edit proposal content here...',
                  toolbarButtons: [
                    'bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript',
                    'fontSize', 'color', 'align', 'formatOL', 'formatUL', 'outdent', 'indent',
                    'insertLink', 'insertImage', 'html', 'backgroundColor', 'imageRemove', 'undo', 'redo',
                    'fontFamily', 'fontAwesome', 'insertTable', 'quote', 'specialCharacters', 'emoticons', 'video', 'file', 'fullscreen',
                    'clearFormatting', 'alignLeft', 'alignCenter', 'alignRight', 'alignJustify', 'insertHR', 'insertPageBreak', 'imageEditButtons', 'help'
                  ],
                  imageMultipleStyles: true,
                  imageResize: true,
                  imageAllowedTypes: ['jpeg', 'jpg', 'png', 'gif'],
                  imageMaxSize: 5 * 1024 * 1024,
                  imageUploadMethod: 'base64',
                  imageUpload: handleImageUpload,
                  imageManager: true,
                  imageMove: true,
                  imageDrop: true,
                  linkEditButtons: ['linkOpen', 'linkRemove'],
                  linkInsertButtons: ['linkBack', 'linkInsert'],
                  videoInsertButtons: ['videoBack', 'videoInsert'],
                  videoUpload: handleVideoUpload,
                  fullscreen: true,
                  helpButton: true,
                  autoSave: true,
                  autoSaveInterval: 3000,
                  undo: true,
                  redo: true,
                  language: 'en',
                  htmlAllowRemoteLinks: true,
                  htmlUntouched: true,
                }}
              />
              <div style={{ padding: '20px', maxWidth: '800px', margin: 'auto', display:'flex', gap:'20px' }}>
      {/* Link Insert Section */}
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
          width:'40%',
          marginBottom: '20px',
        }}
      >
        <input
          type="url"
          placeholder="Enter URL for link"
          value={linkUrl}
          onChange={(e) => setLinkUrl(e.target.value)}
          style={{
            padding: '10px',
            fontSize: '16px',
            borderRadius: '8px',
            border: '1px solid #ccc',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
            width: '100%',
            outline: 'none',
            transition: 'border-color 0.3s',
          }}
        />
        <Button
          variant="outlined"
          color="primary"
          startIcon={<LinkIcon />}
          onClick={()=>handleLinkInsert(linkUrl)}
          style={{
            padding: '5px 10px',
            borderRadius: '8px',
            
            fontSize: '12px',
            fontWeight: '500',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            transition: 'transform 0.3s ease',
          }}
          onMouseEnter={(e) => e.target.style.transform = 'scale(1.05)'}
          onMouseLeave={(e) => e.target.style.transform = 'scale(1)'}
        >
          Insert Button
        </Button>
  
      </div>

      {/* Background Change Section */}
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
          width:'40%'

        }}
      >
        <div
          style={{
            display: 'flex',
            gap: '10px',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <input
            type="color"
            value={backgroundColor}
            onChange={(e) => setBackgroundColor(e.target.value)}
            style={{
              padding: '10px',
              borderRadius: '8px',
              cursor: 'pointer',
              border: '1px solid #ccc',
              height: '40px',
              width: '40px',
              transition: 'background-color 0.3s ease',
            }}
          />
          <input
            type="url"
            placeholder="Enter background image URL"
            value={backgroundUrl}
            onChange={(e) => setBackgroundUrl(e.target.value)}
            style={{
              padding: '10px',
              fontSize: '12px',
              borderRadius: '8px',
              border: '1px solid #ccc',
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
              width: '100%',
              outline: 'none',
              transition: 'border-color 0.3s',
            }}
          />
        </div>
        <Button
          variant="outlined"
          color="secondary"
          startIcon={<ImageIcon />}
          onClick={handleBackgroundChange}
          style={{
            padding: '5px 10px',

            borderRadius: '8px',
            fontSize: '12px',
            fontWeight: '500',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            transition: 'transform 0.3s ease',
          }}
          onMouseEnter={(e) => e.target.style.transform = 'scale(1.05)'}
          onMouseLeave={(e) => e.target.style.transform = 'scale(1)'}
        >
          Set Background
        </Button>
        <Button 
  startIcon={<DeleteIcon />} 
  onClick={handleRemoveBackgroundImage} 
  variant="outlined" 
  style={{
    padding: '5px 10px',

    borderRadius: '8px',
    fontSize: '10px',
    fontWeight: '500',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    transition: 'transform 0.3s ease',
  }}
  onMouseEnter={(e) => e.target.style.transform = 'scale(1.05)'}
  onMouseLeave={(e) => e.target.style.transform = 'scale(1)'}
>
  Remove Background Image
  
</Button>
<Button 
  startIcon={<DeleteIcon />} 
  onClick={handleRemoveBackgroundColor} 
  variant="outlined" 
  style={{
    padding: '5px 10px',

    borderRadius: '8px',
    fontSize: '10px',
    fontWeight: '500',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    transition: 'transform 0.3s ease',
  }}
  onMouseEnter={(e) => e.target.style.transform = 'scale(1.05)'}
  onMouseLeave={(e) => e.target.style.transform = 'scale(1)'}
>
  Remove Background Color
</Button>
      </div>
    </div>
            </>
          ) : (
            <div dangerouslySetInnerHTML={{ __html: proposal.proposalData }} />
          )}
          
          <div style={getStatusStyle(status)}>
            <strong>Status: </strong>{status}
          </div>
          <div style={getApprovalStatusStyle(approvalStatus)}>
            <strong>Approval Status: </strong>{approvalStatus}
          </div>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={handleApprove}
            color="success"
            startIcon={loading ? <CircularProgress size={24} /> : <CheckCircle />}
            disabled={loading || proposal?.approvalStatus === 'Approved'}
            style={{
              boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', 
              transition: 'transform 0.3s ease', 
              borderRadius: '8px'
            }}
            onMouseEnter={(e) => e.target.style.transform = 'scale(1.05)'}
            onMouseLeave={(e) => e.target.style.transform = 'scale(1)'}
          >
            Approve
          </Button>
          <Button
            onClick={handleReview}
            color="warning"
            startIcon={loading ? <CircularProgress size={24} /> : <Visibility />}
            disabled={loading || proposal?.approvalStatus === 'Review' || proposal?.status === 'Sent'}
            style={{
              boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', 
              transition: 'transform 0.3s ease', 
              borderRadius: '8px'
            }}
            onMouseEnter={(e) => e.target.style.transform = 'scale(1.05)'}
            onMouseLeave={(e) => e.target.style.transform = 'scale(1)'}
          >
            Review
          </Button>
          <Button
            onClick={handleOpenEmailDialog}
            color="primary"
            startIcon={loading ? <CircularProgress size={24} /> : <Send />}
            disabled={loading  || proposal?.status === 'Sent' || proposal?.approvalStatus === 'Review' || proposal?.approvalStatus === 'Pending'}
            style={{
              boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', 
              transition: 'transform 0.3s ease', 
              borderRadius: '8px'
            }}
            onMouseEnter={(e) => e.target.style.transform = 'scale(1.05)'}
            onMouseLeave={(e) => e.target.style.transform = 'scale(1)'}
          >
            Send
          </Button>
          {isEditing ? (
            <Button
              onClick={handleSubmitEdit}
              color="secondary"
              startIcon={loading ? <CircularProgress size={24} /> : <CheckCircle />}
              disabled={loading}
              style={{
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', 
                transition: 'transform 0.3s ease', 
                borderRadius: '8px'
              }}
              onMouseEnter={(e) => e.target.style.transform = 'scale(1.05)'}
              onMouseLeave={(e) => e.target.style.transform = 'scale(1)'}
            >
              Submit
            </Button>
          ) : (
            <Button
              onClick={handleEditToggle}
              color="default"
              startIcon={<Edit />}
              disabled = {proposal?.status === 'Sent'}
              style={{
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', 
                transition: 'transform 0.3s ease', 
                borderRadius: '8px'
              }}
              onMouseEnter={(e) => e.target.style.transform = 'scale(1.05)'}
              onMouseLeave={(e) => e.target.style.transform = 'scale(1)'}
            >
              Edit
            </Button>
          )}
          <Button
            onClick={handleClose}
            style={{
              boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', 
              transition: 'transform 0.3s ease', 
              borderRadius: '8px'
            }}
            onMouseEnter={(e) => e.target.style.transform = 'scale(1.05)'}
            onMouseLeave={(e) => e.target.style.transform = 'scale(1)'}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={emailDialogOpen} onClose={handleEmailDialogClose}>
      <DialogTitle>Send Proposal via Email</DialogTitle>
      <DialogContent>
        <Paper elevation={3} style={{ padding: '20px', borderRadius: '8px' }}>
          <TextField
            label="To"
            name="to"
            value={emailDetails.to}
            onChange={handleEmailChange}
            fullWidth
            margin="normal"
            variant="outlined"
            style={{ marginBottom: '10px' }}
          />
          <TextField
            label="Subject"
            name="subject"
            value={emailDetails.subject}
            onChange={handleEmailChange}
            fullWidth
            margin="normal"
            variant="outlined"
            style={{ marginBottom: '10px' }}
          />
          <TextField
            label="CC"
            name="cc"
            value={emailDetails.cc}
            onChange={handleEmailChange}
            fullWidth
            margin="normal"
            variant="outlined"
            style={{ marginBottom: '10px' }}
          />
          <TextField
            label="BCC"
            name="bcc"
            value={emailDetails.bcc}
            onChange={handleEmailChange}
            fullWidth
            margin="normal"
            variant="outlined"
            style={{ marginBottom: '20px' }}
          />
        </Paper>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleEmailDialogClose}
          style={buttonStyles}
          onMouseEnter={(e) => handleButtonHover(e, true)}
          onMouseLeave={(e) => handleButtonHover(e, false)}
        >
          Close
        </Button>
        <Button
          onClick={handleSend}
          color="primary"
          startIcon={loading ? <CircularProgress size={24} /> : <Send />}
          disabled={loading || proposal?.approvalStatus === 'Review' || proposal?.approvalStatus === 'Pending'}
          style={buttonStyles}
          onMouseEnter={(e) => handleButtonHover(e, true)}
          onMouseLeave={(e) => handleButtonHover(e, false)}
        >
          Send
        </Button>
      </DialogActions>
    </Dialog>
    </>
  );
};
const buttonStyles = {
  boxShadow: '0 6px 12px rgba(0, 0, 0, 0.1)', // Shadow for 3D effect
  borderRadius: '12px',
  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
  padding: '10px 20px',
  fontWeight: '600',
  backgroundColor: '#00796b', // Primary button color
  color: '#fff',
  textTransform: 'none', // Keep button text case as is
};

const handleButtonHover = (e, isHovered) => {
  e.target.style.transform = isHovered ? 'scale(1.1)' : 'scale(1)';
  e.target.style.boxShadow = isHovered
    ? '0 10px 20px rgba(0, 0, 0, 0.2)'
    : '0 6px 12px rgba(0, 0, 0, 0.1)';
};
export default ProposalDialog;
