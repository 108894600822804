import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Grid,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useGet, usePost } from "../../../helpers/axios/useApi";

function UpdateSalerySlips({ employees , month , year }) {
  const [employeeData, setEmployeeData] = useState([]);
  const {data : saleries} = useGet('/company/salery/get-salery',{
     month :month+1,
     year
  })
  const handleSaleryUpdate = usePost('/company/salery/create-or-update-salery')

  useEffect(() => {
 
    const initialEmployeeData = employees.map((employee) =>{ 
      const findSaleryForEmplyoee = saleries?.data?.data.find(salery => salery.employeeId === employee._id)

  return     ({
      ...employee,
      basePay:findSaleryForEmplyoee?.basePay ||  0,
      bonus:findSaleryForEmplyoee?.bonus || 0,
      deductions:findSaleryForEmplyoee?.deduction || 0,
      tax:findSaleryForEmplyoee?.tax || 0,
      allowance:findSaleryForEmplyoee?.allowance || 0,
      netPay:findSaleryForEmplyoee?.netPay || 0,
      absentDays:findSaleryForEmplyoee?.absentDays || 0,
      extraHours:findSaleryForEmplyoee?.extraHours || 0,
      extraHoursPayRate:findSaleryForEmplyoee?.extraHoursPayRate || 0,
      totalIncentive:findSaleryForEmplyoee?.totalIncentive ||  0,
      customAllowances: findSaleryForEmplyoee?.customAllowances || [],
      customDeductions: findSaleryForEmplyoee?.customDeductions || [],
    })});
    setEmployeeData(initialEmployeeData);
  }, [employees]);

  const handleChange = (index, field, value) => {
    const updatedData = [...employeeData];
    updatedData[index][field] = value;
    setEmployeeData(updatedData);
  };

  const handleCustomAllowanceChange = (index, allowanceIndex, field, value) => {
    const updatedData = [...employeeData];
    updatedData[index].customAllowances[allowanceIndex][field] = value;
    setEmployeeData(updatedData);
  };

  const handleCustomDeductionChange = (index, deductionIndex, field, value) => {
    const updatedData = [...employeeData];
    updatedData[index].customDeductions[deductionIndex][field] = value;
    setEmployeeData(updatedData);
  };

  const addCustomAllowance = (index) => {
    const updatedData = [...employeeData];
    updatedData[index].customAllowances.push({ type: "", value: "" });
    setEmployeeData(updatedData);
  };

  const addCustomDeduction = (index) => {
    const updatedData = [...employeeData];
    updatedData[index].customDeductions.push({ type: "", value: "" });
    setEmployeeData(updatedData);
  };

  const removeCustomAllowance = (index, allowanceIndex) => {
    const updatedData = [...employeeData];
    updatedData[index].customAllowances.splice(allowanceIndex, 1);
    setEmployeeData(updatedData);
  };

  const removeCustomDeduction = (index, deductionIndex) => {
    const updatedData = [...employeeData];
    updatedData[index].customDeductions.splice(deductionIndex, 1);
    setEmployeeData(updatedData);
  };

  const handleSave = () => {
    try {

    employeeData.forEach((employee) => {
         const saleryData = {
            employeeId: employee._id,
            employeeName: employee.name,
            basePay: employee.basePay,
            bonus: employee.bonus,
            deductions: employee.deductions,
            tax: employee.tax,
            allowance: employee.allowance,
            netPay: employee.netPay,
            totalIncentive: employee.totalIncentive,
            absentDays: employee.absentDays,
            extraHours: employee.extraHours,
            extraHoursPayRate: employee.extraHoursPayRate,
            totalExtraHoursPay: employee.totalExtraHoursPay,
            customAllowances: employee.customAllowances,
            customDeductions: employee.customDeductions,
            month: month + 1,
            year: year    
         }

         const res = handleSaleryUpdate.mutateAsync({saleryData})

         if(res?.data !== null){
    
         }else{
          throw new Error(res?.error?.error)
         }
         
    });
    }
    catch (error) {
        console.error("Error saving data:", error);
    }
    console.log("Saved Data:", employeeData);
  };

  return (
    <Box sx={{ mt: 2, overflowX: "auto" }}>
      <TableContainer component={Paper} elevation={1}>
        <Table
          sx={{ minWidth: 1500, borderCollapse: "collapse" }}
          aria-label="employee salary table"
        >
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  fontSize: "12px",
                  textAlign: "center",
                }}
              >
                Employee Name
              </TableCell>
              {[
                "Base Pay",
                "Bonus",
                "Deductions",
                "Tax",
                "Allowance",
                "Net Pay",
                "Absent Days",
                "Extra Hours",
                "Extra Hours Pay Rate",
                "Total Incentive",
              ].map((header) => (
                <TableCell
                  key={header}
                  sx={{
                    fontWeight: "bold",
                    fontSize: "12px",
                    textAlign: "center",
                  }}
                >
                  {header}
                </TableCell>
              ))}
              <TableCell
                sx={{
                  fontWeight: "bold",
                  fontSize: "12px",
                  textAlign: "center",
                }}
              >
                Custom Allowances
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  fontSize: "12px",
                  textAlign: "center",
                }}
              >
                Custom Deductions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {employeeData.map((employee, index) => (
              <TableRow key={employee._id}>
                <TableCell sx={{ textAlign: "center", fontSize: "12px" }}>
                  {employee.name}
                </TableCell>
                {[
                  "basePay",
                  "bonus",
                  "deductions",
                  "tax",
                  "allowance",
                  "netPay",
                  "absentDays",
                  "extraHours",
                  "extraHoursPayRate",
                  "totalIncentive",
                ].map((field) => (
                  <TableCell
                    key={field}
                    sx={{ textAlign: "center", minWidth: "100px" }}
                  >
                    <TextField
                      value={employee[field]}
                      onChange={(e) =>
                        handleChange(index, field, e.target.value)
                      }
                      // disabled={field === "deductions"}
                      type="text" // Keep text type for precise control
                      size="small"
                      sx={{ width: "100%" }}
                      InputProps={{
                        style: { fontSize: "9px" }, // Apply font size here
                      }}
                      inputProps={{
                        pattern: "[0-9]*", // Restrict input to numeric only
                        inputMode: "numeric", // Enhance support for mobile number keyboards
                      }}
                      onInput={(e) => {
                        // Ensure only numeric characters are entered
                        e.target.value = e.target.value.replace(/[^0-9]/g, "");
                      }}
                    />
                  </TableCell>
                ))}
                <Grid sx={{
                  display: "flex",
                }}>
                <TableCell sx={{ textAlign: "center", display: "flex" }}>
                  <Grid>

                  
                  {employee.customAllowances.map(
                    (allowance, allowanceIndex) => (
                      <Grid
                        container
                        key={allowanceIndex}
                        spacing={1}
                        sx={{ mb: 0 }}
                      >
                        <Grid item xs={6}>
                          <TextField
                            value={allowance.type}
                            onChange={(e) =>
                              handleCustomAllowanceChange(
                                index,
                                allowanceIndex,
                                "type",
                                e.target.value
                              )
                            }
                            label="Type"
                            size="small"
                            sx={{ width: "100%", fontSize: "8px" }}
                            InputLabelProps={{
                              style: {
                                fontSize: "9px",
                              },
                            }}
                            InputProps={{
                              style: { fontSize: "9px" }, // Apply font size here
                            }}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            value={allowance.value}
                            onChange={(e) =>
                              handleCustomAllowanceChange(
                                index,
                                allowanceIndex,
                                "value",
                                e.target.value
                              )
                            }
                            label="Amount"
                            type="text" // Keep text type for precise control
                            size="small"
                            sx={{ width: "100%" }}
                            InputProps={{
                              style: { fontSize: "9px" }, // Apply font size here
                            }}
                            inputProps={{
                              pattern: "[0-9]*", // Restrict input to numeric only
                              inputMode: "numeric", // Enhance support for mobile number keyboards
                            }}
                            InputLabelProps={{style:{
                              fontSize:'9px'
                            }}}
                            onInput={(e) => {
                              // Ensure only numeric characters are entered
                              e.target.value = e.target.value.replace(/[^0-9]/g, "");
                            }}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={1}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <IconButton
                            onClick={() =>
                              removeCustomAllowance(index, allowanceIndex)
                            }
                            sx={{ ml: 10 }}
                            color="error"
                          >
                            <RemoveIcon />
                          </IconButton>
                        
                         
                        </Grid>
                      </Grid>
                    )
                  )}
                  </Grid>
                    <IconButton
                            onClick={() => addCustomAllowance(index)}
                            color="primary"
                            sx={{ ml: 4 }}
                          >
                            <AddIcon />
                            </IconButton>
                </TableCell>
                <TableCell sx={{ textAlign: "center", display:'flex' }}>
                  <Grid>
                  {employee.customDeductions.map(
                    (deduction, deductionIndex) => (
                      <Grid
                        container
                        key={deductionIndex}
                        spacing={1}
                        sx={{ mb: 0 }}
                      >
                        <Grid item xs={6}>
                          <TextField
                            value={deduction.type}
                            onChange={(e) =>
                              handleCustomDeductionChange(
                                index,
                                deductionIndex,
                                "type",
                                e.target.value
                              )
                            }
                            InputLabelProps={{
                              style: {
                                fontSize: "9px",
                              },
                            }}
                            label="Type"
                            size="small"
                            sx={{ width: "100%", fontSize: "8px" }}
                            InputProps={{
                              style: { fontSize: "9px" }, // Apply font size here
                            }}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            value={deduction.value}
                            onChange={(e) =>
                              handleCustomDeductionChange(
                                index,
                                deductionIndex,
                                "value",
                                e.target.value
                              )
                            }
                            
                            label="Amount"
                            type="text" // Keep text type for precise control
                            size="small"
                            sx={{ width: "100%" }}
                            InputProps={{
                              style: { fontSize: "9px" }, // Apply font size here
                            }}
                            inputProps={{
                              pattern: "[0-9]*", // Restrict input to numeric only
                              inputMode: "numeric", // Enhance support for mobile number keyboards
                            }}
                            InputLabelProps={{style:{
                              fontSize:'9px'
                            }}}
                            onInput={(e) => {
                              // Ensure only numeric characters are entered
                              e.target.value = e.target.value.replace(/[^0-9]/g, "");
                            }}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={2}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <IconButton
                            onClick={() =>
                              removeCustomDeduction(index, deductionIndex)
                            }
                            color="error"
                            sx={{ ml: 10 }}
                          >
                            <RemoveIcon />
                          </IconButton>
                         
                        </Grid>
                      </Grid>
                    )
                  )}
                  </Grid>
                   <IconButton
                            onClick={() => addCustomDeduction(index)}
                            color="primary"
                            sx={{ ml: 5 }}
                          >
                            <AddIcon />
                          </IconButton>
                </TableCell>
                </Grid>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Box sx={{ textAlign: "right", mt: 4 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSave}
          startIcon={<SaveIcon />}
          sx={{ fontSize: "14px" }}
        >
          Save All Changes
        </Button>
      </Box>
    </Box>
  );
}

export default UpdateSalerySlips;
