import React, { useState } from 'react';
import { Switch, Box, Typography } from '@mui/material';
import { styled } from '@mui/system';

// Custom 3D switch with label inside
const AnimatedSwitch = styled(Switch)(({ theme }) => ({
  width: 70, // Custom width for the switch
  height: 30, // Custom height for the switch
  padding: 0, // Remove padding
  borderRadius: 50, // Fully rounded corners
  position: 'relative', // For positioning the label inside the switch
  transition: 'all 0.3s ease', // Smooth transition

  '& .MuiSwitch-switchBase': {
    padding: 4,
    borderRadius: '50%',
    '&.Mui-checked': {
      transform: 'translateX(40px)', // Thumb movement when checked
      boxShadow: '0px 6px 15px rgba(0, 0, 0, 0.2)', // Shadow when checked
    },
    '&.MuiSwitch-thumb': {
      width: 38, // Thumb size
      height: 30, // Thumb size
      backgroundColor: theme.palette.common.white, // Thumb color
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)', // Shadow effect on the thumb
      transition: 'all 0.3s ease', // Smooth transition on thumb movement
    },
  },

  '& .MuiSwitch-track': {
    borderRadius: 50, // Rounded track
    backgroundColor: theme.palette.grey[400], // Default track color
    boxShadow: 'inset 0px 0px 6px rgba(0, 0, 0, 0.1)', // Inner shadow effect
    transition: 'background-color 0.3s ease', // Smooth background transition
    '&.Mui-checked': {
      backgroundColor: theme.palette.primary.main, // Track color when checked
      boxShadow: 'inset 0px 0px 8px rgba(0, 0, 0, 0.2)', // Stronger inner shadow when checked
    },
  },
}));

const SwitchTeamSelfButton = ({isTeam, onChange}) => {
  

  const handleSwitchChange = (event) => {
    onChange(event.target.checked);
  };

  return (
    <Box sx={{ position: 'relative', display: 'inline-block', backgroundColor: 'white', borderRadius: '50px', marginTop: '0px', marginLeft: '10px' }}>
      <AnimatedSwitch
        checked={isTeam}
        onChange={handleSwitchChange}
        color="primary"
        disableRipple
      />
      <Typography
        variant="body1"
        sx={{
          position: 'absolute',
          top: '50%',
          left: isTeam ? '30%' : '60%',
          transform: 'translate(-50%, -50%)', // Center the text
          fontSize: '12px',
          fontWeight: 'bold',
          color: isTeam ? 'white' : 'black',
          pointerEvents: 'none', // Disable interaction with text
        }}
      >
        {isTeam ? 'Team' : 'Self'}
      </Typography>
    </Box>
  );
};

export default SwitchTeamSelfButton;
