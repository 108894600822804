import React, { createContext, useContext, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import CryptoJS from "crypto-js"; // Import crypto-js for encryption/decryption

const AuthContext = createContext();

// Helper functions for encryption and decryption
const encryptData = (data) => {
  const secretKey = process.env.REACT_APP_SECRET_KEY || "defaultSecretKey";
  return CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
};

const decryptData = (encryptedData) => {
  const secretKey = process.env.REACT_APP_SECRET_KEY || "defaultSecretKey";
  const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
};

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(true);  // Add a loading state to prevent redirects on first render
  const navigate = useNavigate();
  const location = useLocation();

  // Decrypt function to retrieve user and token from localStorage and decrypt it
  const getDecryptedUserData = () => {
    const encryptedUserData = localStorage.getItem("user");
    if (encryptedUserData) {
      try {
        return decryptData(encryptedUserData);
      } catch (err) {
      
        return null;
      }
    }
    return null;
  };

  const getDecryptedToken = () => {
    const encryptedToken = localStorage.getItem("authToken");
    if (encryptedToken) {
      try {
        return decryptData(encryptedToken);
      } catch (err) {
    
        return null;
      }
    }
    return null;
  };

  useEffect(() => {
    // Retrieve and decrypt user data and token from localStorage
    const decryptedUserData = getDecryptedUserData();
    const decryptedToken = getDecryptedToken();

    // Handle user data expiration logic
    if (decryptedUserData) {
      const { data, expiry } = decryptedUserData;
      if (new Date().getTime() > expiry) {
        // Remove expired UserData
        localStorage.removeItem("user");
        setUser(null);
        if (location.pathname !== "/register") {
          navigate("/login");
        }
      } else {
        setUser(data);
      }
    }

    if (decryptedToken) {
      setToken(decryptedToken);
    }

    // Set loading state to false once the user data has been processed
    setLoading(false);
  }, [navigate, location]);

  // Login function to store encrypted data
  const login = (User, token) => {
    const expiry = new Date().getTime() + 12 * 3600000; // 12 hours expiration
    const userData = { data: User, expiry };
    const encryptedUserData = encryptData(userData);
    const encryptedToken = encryptData(token);
   
    localStorage.setItem("user", encryptedUserData);
    localStorage.setItem("authToken", encryptedToken);

    setUser(User);
    setToken(token);
  };

  // Logout function to clear localStorage and reset state
  const logout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("authToken");
    setUser(null);
    setToken(null);
    navigate("/login");
  };

  // Provide the decrypted user and token values via context
  return (
    <AuthContext.Provider value={{ user, token, login, logout }}>
      {!loading ? children : null}  {/* Only render children after user data is loaded */}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  return context;
};
