import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Radio, RadioGroup, FormControlLabel, TextField, FormControl, InputLabel, Select, MenuItem } from '@mui/material';

const IncantiveFormDialog = ({open, close}) => {
 
  const [selectedOption, setSelectedOption] = useState('weekly');
  const [inputValue, setInputValue] = useState('');

  // Handle opening and closing of the dialog
 
  const handleClose = () => {
    close(false);
  };

  // Handle radio button change
  const handleRadioChange = (event) => {
    setSelectedOption(event.target.value);
    setInputValue(''); // Reset input value when radio button changes
  };

  // Handle input change
  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  return (
    <div>
      {/* Button to open dialog */}
      

      {/* Dialog Component */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Subscription Form</DialogTitle>
        <DialogContent>
          {/* Radio buttons for selecting the frequency */}
          <FormControl component="fieldset" fullWidth>
            <RadioGroup
              value={selectedOption}
              onChange={handleRadioChange}
              row
            >
              <FormControlLabel value="weekly" control={<Radio />} label="Weekly" />
              <FormControlLabel value="monthly" control={<Radio />} label="Monthly" />
              <FormControlLabel value="yearly" control={<Radio />} label="Yearly" />
              <FormControlLabel value="quarterly" control={<Radio />} label="Quarterly" />
              <FormControlLabel value="halfYearly" control={<Radio />} label="Half-Yearly" />
            </RadioGroup>
          </FormControl>
          

          {/* Conditional rendering of input fields based on selected option */}
          {selectedOption === 'weekly' && (
            <TextField
              label="Enter Weekly Frequency"
              variant="outlined"
              fullWidth
              value={inputValue}
              onChange={handleInputChange}
              type="number"
              InputProps={{ inputProps: { min: 1 } }}
            />
          )}
          {selectedOption === 'monthly' && (
            <TextField
              label="Enter Monthly Frequency"
              variant="outlined"
              fullWidth
              value={inputValue}
              onChange={handleInputChange}
              type="number"
              InputProps={{ inputProps: { min: 1 } }}
            />
          )}
          {selectedOption === 'yearly' && (
            <TextField
              label="Enter Yearly Frequency"
              variant="outlined"
              fullWidth
              value={inputValue}
              onChange={handleInputChange}
              type="number"
              InputProps={{ inputProps: { min: 1 } }}
            />
          )}
          {selectedOption === 'quarterly' && (
            <TextField
              label="Enter Quarterly Frequency"
              variant="outlined"
              fullWidth
              value={inputValue}
              onChange={handleInputChange}
              type="number"
              InputProps={{ inputProps: { min: 1 } }}
            />
          )}
          {selectedOption === 'halfYearly' && (
            <FormControl fullWidth>
              <InputLabel>Choose Half-Yearly Option</InputLabel>
              <Select
                value={inputValue}
                onChange={handleInputChange}
              >
                <MenuItem value="option1">Option 1</MenuItem>
                <MenuItem value="option2">Option 2</MenuItem>
                <MenuItem value="option3">Option 3</MenuItem>
              </Select>
            </FormControl>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">Cancel</Button>
          <Button onClick={handleClose} color="primary">Submit</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default IncantiveFormDialog;
