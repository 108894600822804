import React, { useEffect, useState } from "react";
import { useGet } from "../../helpers/axios/useApi";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./Calender.css";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
// Set up the localizer
const localizer = momentLocalizer(moment);

function MeetingCalender() {
  const [events, setEvents] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1); // Initial month (1-12)
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear()); // Initial year
  const [currentDate, setCurrentDate] = useState(new Date(selectedYear, selectedMonth - 1));

  // Fetch meetings when month/year change
  const { data: getMeetings, refetch } = useGet("meetings/get", {
    month: selectedMonth,
    year: selectedYear,
  });

  useEffect(() => {
    if (getMeetings?.data?.data) {
      const eventsData = getMeetings.data.data.map((meeting) => ({
        title: meeting.meetingName,
        start: new Date(meeting.meetingDate),
        end: new Date(new Date(meeting.meetingDate).setHours(new Date(meeting.meetingDate).getHours() + 1)),
        link: meeting.meetingLink,
        color: getLightColor(), // Use light colors
      }));
      setEvents(eventsData);
    }
  }, [getMeetings, selectedMonth, selectedYear]);

  const getLightColor = () => {
    const lightColors = [
      "#FAD02E", // Light yellow
      "#F28D35", // Light orange
      "#FF9E9D", // Light pink
      "#A2D5F2", // Light blue
      "#C8E6C9", // Light green
      "#E0BBE4", // Light lavender
      "#FFE156", // Pale yellow
      "#6D8B3D", // Olive green
      "#FFB3C1", // Pastel pink
      "#A3D9B1", // Mint green
      "#D6AEDD", // Lavender purple
      "#D9E4F5", // Soft blue
      "#F3C5C5", // Soft coral
      "#FFB6A1", // Peach
      "#C5E3F6", // Light cyan
    ];
    return lightColors[Math.floor(Math.random() * lightColors.length)];
  };

  const handleNextWeek = () => {
    const nextWeekStart = moment(currentDate).add(1, "week");
    const nextDate = nextWeekStart.toDate();

    setCurrentDate(nextDate);
    setSelectedMonth(nextDate.getMonth() + 1); // Set the selected month (1-12)
    setSelectedYear(nextDate.getFullYear()); // Set the selected year
  };

  const handlePreviousWeek = () => {
    const previousWeekStart = moment(currentDate).subtract(1, "week");
    const previousDate = previousWeekStart.toDate();

    setCurrentDate(previousDate);
    setSelectedMonth(previousDate.getMonth() + 1); // Set the selected month (1-12)
    setSelectedYear(previousDate.getFullYear()); // Set the selected year
  };

  const handleMonthChange = (e) => {
    setSelectedMonth(parseInt(e.target.value, 10));
    setCurrentDate(new Date(selectedYear, parseInt(e.target.value, 10) - 1));
  };

  const handleYearChange = (e) => {
    setSelectedYear(parseInt(e.target.value, 10));
    setCurrentDate(new Date(parseInt(e.target.value, 10), selectedMonth - 1));
  };

  useEffect(() => {
    refetch(); // Trigger the refetch function to load meetings for the updated month/year
  }, [selectedMonth, selectedYear, refetch]);

  return (
    <div style={styles.dashboardContainer}>
      <div style={styles.chartContainer}>
        {/* Date Navigation and Month/Year Select */}
        <div style={styles.dateSelection}>
          {/* Previous Week Button */}
         

          {/* Month and Year Selectors */}
          <select
            value={selectedMonth}
            onChange={handleMonthChange}
            style={styles.select}
          >
            {["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"].map((monthName, index) => (
              <option key={index} value={index + 1}>
                {monthName}
              </option>
            ))}
          </select>

          <select
            value={selectedYear}
            onChange={handleYearChange}
            style={styles.select}
          >
            {Array.from({ length: 10 }, (_, i) => (
              <option key={i} value={2020 + i}>
                {2020 + i}
              </option>
            ))}
          </select>
         

          {/* Create Meeting Button */}
          <Button
            sx={{
              backgroundColor: "white",
              borderRadius: "10px",
              fontSize: "8px",
              position: "absolute",
              right: "190px",
              border: "1px solid black",
              color: "black",
            }}
          >
            Create Meeting
          </Button>
        </div>

        {/* Calendar Component showing only Week View */}
        <Calendar
  localizer={localizer}
  events={events}
  startAccessor="start"
  endAccessor="end"
  style={{ height: 500, marginTop: "20px" }}
  eventPropGetter={(event, start, end, isSelected) => {
    return {
      style: {
        backgroundColor: 'white', // Set background color (very light colors)
        borderRadius: "20px",
        color: "#333", // Dark text for contrast
        fontSize: "14px", // Increase font size for readability
        padding: "10px", // More padding inside the event block
        whiteSpace: "normal", // Allow text wrapping for long titles
        marginBottom: "5px", // Space between stacked events
        zIndex: 10,
        border: 'none',
        position: "relative",
      },
    };
  }}
  views={["agenda"]} // Only show agenda view
  defaultView="agenda" // Set default view to agenda
  date={currentDate} // Set the calendar's current date based on selected month and year
  components={{
    event: ({ event }) => (
      <div style={{display:'flex'}}>
        <div
          style={{
            padding: "10px",
            backgroundColor: event.color,
            borderRadius: "5px",
            fontWeight: "bold",
            boxShadow:'none',
            color: "#333", // Dark text for visibility on light backgrounds
            fontSize: "14px", // Adjust font size
            whiteSpace: "normal", // Allow text to wrap if it's too long
            overflow: "hidden",
            textOverflow: "ellipsis", // Avoid text overflow
            transition: "all 0.3s ease", // Smooth hover transition
            position: "absolute",
            top: "30px",
            display: "flex",
            gap: "1rem",
            alignItems: "center",
          }}
        >
          <div style={{ fontWeight: "bold", fontSize: "14px" }}>
            {event.title}
          </div>
          {/* Add a span for additional content inside the event */}
          <Link to={`${event.link}`} target="_blank">
                      <OpenInNewIcon
                        sx={{
                          color: "white",
                          "&:hover": { color: "#66b3ff" },
                          "&:active": { color: "#0056b3" },
                          transition: "color 0.3s ease",
                        }}
                      />
                    </Link>
        </div>
        <div>

       
        </div>
</div>
    ),
    toolbar: () => null, // Remove the default header toolbar (today, next, previous buttons)
  }}
/>

      </div>
    </div>
  );
}

const styles = {
  dashboardContainer: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    marginTop: "-20px",
    width: "100%",
    maxWidth: "800px",
    borderRadius: "20px",
  },
  chartContainer: {
    display: "flex",
    position: "relative",
    justifyContent: "center",
    flexDirection: "column",
    width: "100%",
    maxWidth: "800px",
    padding: "10px",
    borderRadius: "20px",
    position: "relative",
  },
  dateSelection: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    position: "relative",
    marginBottom: "20px",
  },
  select: {
    margin: "0 10px",
    padding: "5px",
    fontSize: "12px",
    borderRadius : "10px",
  },
};

export default MeetingCalender;
