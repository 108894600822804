import { CircularProgress, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

function GetProgress({ employee, date, progress }) {
  const progresses = progress?.find(
    (p) => p.date?.split("T")[0] === date && p.employeeId === employee._id
  );

  if(!progresses?.process){
    return ''
 } 
   
 
 const progressValue = parseFloat(progresses?.process?.toFixed(2));


  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress variant="determinate" value={progressValue} color= {progressValue < 75 ? 'error' : 'success'} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="caption"
          component="div"
          sx={{ color: "text.secondary" }}
        >{`${Math.round(progressValue)}%`}</Typography>
      </Box>
    </Box>
  );
}

export default GetProgress;
