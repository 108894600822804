import React, { useEffect, useState } from 'react'; 
import { useGet } from '../../helpers/axios/useApi'; 
import { useParams } from 'react-router-dom'; 
import { Grid, Card, CardMedia, Dialog, DialogActions, DialogContent, Button, LinearProgress, Typography } from '@mui/material'; 
import { Box } from '@mui/system'; 
import { ArrowBack, ArrowForward, Close } from '@mui/icons-material';

// Function to get current date in YYYY-MM-DD format
function getCurrentDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}

const CustomLinearProgress = ({ value }) => {
    return (
        <Box sx={{ position: 'relative', width: '80%', margin: '0 auto' }}>
            <LinearProgress
                variant="determinate"
                value={value}
                sx={{
                    height: 15,
                    borderRadius: 8,
                    width: '100%',
                }}
            />
            <Box
                sx={{
                    position: 'absolute',
                    top: -1,
                    left: '50%',
                    transform: 'translateX(-50%)',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Typography sx={{ color: 'black', fontWeight: 'bold', fontSize: '12px' }}>
                    {`${Math.round(value)}%`}
                </Typography>
            </Box>
        </Box>
    );
};

function WorkHistory() {
    const id = useParams().id;
    const [files, setFiles] = useState([]);
    const [selectedDate, setSelectedDate] = useState(getCurrentDate());
    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(0);
    const [open, setOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');
    const [currentImageIndex, setCurrentImageIndex] = useState(null);
    const [nextFiles, setNextFiles] = useState([]);

    const { data: trackingData, loading: apiLoading } = useGet('/employee/tracking-get', {
        employeeId: id,
        date: selectedDate,
    });

    const { data: progressData } = useGet('/emplyoee/daily-work/work-progress-get-date-wise', {
        employeeId: id,
        date: selectedDate,
    });

    useEffect(() => {
        if (progressData?.data) {
            setProgress(progressData?.data?.process);
        }
    }, [progressData]);

    useEffect(() => {
        setLoading(true);
        if (trackingData?.data?.data) {
            const newFiles = trackingData?.data?.data[0]?.files || [];
            setFiles(newFiles);
        } else {
            setFiles([]);
        }
        setLoading(false);
    }, [trackingData, selectedDate]);

    // Open image in the modal
    const handleImageClick = (imageUrl, index) => {
        setSelectedImage(imageUrl);
        setCurrentImageIndex(index);
        setNextFiles(files.slice(index + 1, index + 7)); // Get next 5 files
        setOpen(true);
    };

    // Close modal
    const handleClose = () => {
        setOpen(false);
        setSelectedImage('');
        setCurrentImageIndex(null); // Clear the current image index
    };

    // Navigate to next image
    const handleNextImage = () => {
        if (currentImageIndex < files.length - 1) {
            const newIndex = currentImageIndex + 1;
            setCurrentImageIndex(newIndex);
            setSelectedImage(files[newIndex]);
            setNextFiles(files.slice(newIndex + 1, newIndex + 7)); // Get next 5 files
        }
    };

    // Navigate to previous image
    const handlePrevImage = () => {
        if (currentImageIndex > 0) {
            const newIndex = currentImageIndex - 1;
            setCurrentImageIndex(newIndex);
            setSelectedImage(files[newIndex]);
            setNextFiles(files.slice(newIndex + 1, newIndex + 7)); // Get next 5 files
        }
    };

    // Handle Date Picker change
    const handleDateChange = (event) => {
        setSelectedDate(event.target.value); // Update selectedDate when the user picks a new date
    };

    return (
        <div style={{ padding: '20px' }}>
            <Grid sx={{ width: '100%', display: 'flex', gap: '1rem' }}>
                <Grid sx={{ width: '20%' }}>
                    <input
                        id="date-picker"
                        type="date"
                        value={selectedDate}
                        onChange={handleDateChange}
                        style={{ width: '100%', padding: '10px', marginBottom: '20px' }}
                    />
                </Grid>
                <CustomLinearProgress value={progress} />
            </Grid>

            {loading || apiLoading ? (
                <div>Loading...</div>
            ) : (
                <>
                    <Grid container spacing={2} height={'70vh'} overflow={'auto'}>
                        {files?.map((file, index) => (
                            <Grid item xs={12} sm={6} md={2} key={index}>
                                <Card sx={{ height: '20vh', width: '100%' }}>
                                    <CardMedia
                                        component="img"
                                        width={'100%'}
                                        height="100%"
                                        sx={{
                                            objectFit: 'cover',
                                            width: '100%',
                                        }}
                                        image={file}
                                        alt={`Screenshot ${index + 1}`}
                                        onClick={() => handleImageClick(file, index)}
                                    />
                                </Card>
                            </Grid>
                        ))}
                    </Grid>

                    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
                        <DialogContent>
                            <img
                                src={selectedImage}
                                alt="Full screen"
                                style={{ width: '100%', height: 'auto' }}
                            />

                            <Grid container spacing={2} height={'22vh'} overflow={'auto'} padding={'1rem'}>
                                {nextFiles?.map((file, index) => (
                                    <Grid item xs={12} sm={6} md={2} key={index}>
                                        <Card sx={{ height: '20vh', width: '100%' }}>
                                            <CardMedia
                                                component="img"
                                                width={'100%'}
                                                height="100%"
                                                sx={{
                                                    objectFit: 'cover',
                                                    width: '100%',
                                                }}
                                                image={file}
                                                alt={`Screenshot ${index + 1}`}
                                                onClick={() => handleImageClick(file, index)}
                                            />
                                        </Card>
                                    </Grid>
                                ))}
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                        <Button
    onClick={handlePrevImage}
    color="primary"
    disabled={currentImageIndex === 0}
    startIcon={<ArrowBack />}
    sx={{
      padding: "10px 20px",
      borderRadius: "30px",
      fontWeight: "bold",
      letterSpacing: "1px",
      textTransform: "none",
      fontSize: "16px",
      transition: "transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1)",
      "&:hover": {
        backgroundColor: "primary.dark",
        boxShadow: "0 6px 12px rgba(0, 0, 0, 0.2)",
        transform: "scale(1.05)",
      },
      "&:disabled": {
        backgroundColor: "grey.400",
        color: "common.white",
        boxShadow: "none",
        cursor: "not-allowed",
      }
    }}
  >
    Prev
  </Button>
                            <Button
    onClick={handleNextImage}
    color="primary"
    disabled={currentImageIndex === files.length - 1}
    endIcon={<ArrowForward />}
    sx={{
      padding: "10px 20px",
      borderRadius: "30px",
      fontWeight: "bold",
      letterSpacing: "1px",
      textTransform: "none",
      fontSize: "16px",
      transition: "transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1)",
      "&:hover": {
        backgroundColor: "primary.dark",
        boxShadow: "0 6px 12px rgba(0, 0, 0, 0.2)",
        transform: "scale(1.05)",
      },
      "&:disabled": {
        backgroundColor: "grey.400",
        color: "common.white",
        boxShadow: "none",
        cursor: "not-allowed",
      }
    }}
  >
    Next
  </Button>
                            <Button
    onClick={handleClose}
    color="secondary"
    startIcon={<Close />}
    sx={{
      padding: "10px 20px",
      borderRadius: "30px",
      fontWeight: "bold",
      letterSpacing: "1px",
      textTransform: "none",
      fontSize: "16px",
      transition: "transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1)",
      "&:hover": {
        backgroundColor: "secondary.dark",
        boxShadow: "0 6px 12px rgba(0, 0, 0, 0.2)",
        transform: "scale(1.05)",
      },
      "&:disabled": {
        backgroundColor: "grey.400",
        color: "common.white",
        boxShadow: "none",
        cursor: "not-allowed",
      }
    }}
  >
    Close
  </Button>
                        </DialogActions>
                    </Dialog>
                </>
            )}
        </div>
    );
}

export default WorkHistory;
