import React, { useState, useEffect, useCallback } from "react";
import { Button, Card, FormControl, InputAdornment, TextField, Grid, Typography, Select, MenuItem, Box, IconButton, FormHelperText } from "@mui/material";
import InvoiceItem from "./invoiceItem";
import InvoiceModal from "./invoiceModel";
import DeleteIcon from "@mui/icons-material/Delete";
import { useAuth } from "../../../../middlewares/auth";

const InvoiceForm = ({leadDetail,payment}) => {
    const {user } = useAuth();
  const [isOpen, setIsOpen] = useState(false);
  const [currency, setCurrency] = useState("₹");
  const [currentDate, setCurrentDate] = useState((Date(payment?.paymentDate)) || Date().toLocaleDateString());
  const [invoiceNumber, setInvoiceNumber] = useState(payment?.invoiceNumber || '');
  const [dateOfIssue, setDateOfIssue] = useState("");
  const [billTo, setBillTo] = useState(leadDetail?.leadName || leadDetail?.name || "");
  const [billToEmail, setBillToEmail] = useState(leadDetail?.email || "");
  const [billToAddress, setBillToAddress] = useState(leadDetail?.address || leadDetail?.userFields?.address || "");
  const [billFrom, setBillFrom] = useState(user?.companyName || "");
  const [billFromEmail, setBillFromEmail] = useState(user?.companyEmail || "");
  const [billFromAddress, setBillFromAddress] = useState(`${user?.companyAddress?.street}, ${user?.companyAddress?.city}, ${user?.companyAddress?.state}, ${user?.companyAddress?.postalCode}, ${user?.companyAddress?.country} ` || "");
  const [billFromPhone, setBillFromPhone] = useState(user?.companyPhone || "");
  const [billToPhone, setBillToPhone] = useState(leadDetail?.phone || leadDetail?.userFields?.phone || leadDetail?.mobile || leadDetail?.userFields?.mobile || leadDetail?.userFields?.phone || "");
  const [notes, setNotes] = useState("Thank you for doing business with us. Have a great day!");
  const [tearmAndCondition, setTearmAndCondition] = useState("No Tearms and Conditions");
  const [total, setTotal] = useState("0.00");
  const [subTotal, setSubTotal] = useState("0.00");
  const [taxRate, setTaxRate] = useState(payment?.taxPercent || 0);
  const [taxAmount, setTaxAmount] = useState("0.00");
  const [discountRate, setDiscountRate] = useState(payment?.discountPercent || 0);
  const [discountAmount, setDiscountAmount] = useState("0.00");
  const [logo, setLogo] = useState(null);
  const [header, setHeader] = useState([    { id: "serial", label: "Serial" },
    { id: "service", label: "Service" },
    { id: "price", label: "PRICE/RATE" },
    { id: "action", label: "ACTION" },]);
  const [billFromGst, setBillFromGst] = useState(user?.companyGstNumber || "");
  const [billToGst, setBillToGst] = useState("");
  const [items, setItems] = useState([
  
  ]);

  const handleCalculateTotal = useCallback(() => {
   
   
    let newTaxAmount = (Number(payment.amount) * (Number(taxRate) / 100)).toFixed(2);
    let newDiscountAmount = (Number(payment.amount) * (Number(discountRate) / 100)).toFixed(2);
    let amount = ((Number(payment.amount) - Number(taxAmount)) + Number(newDiscountAmount)).toFixed(2);


    const newTotal = Number(amount) + Number(newTaxAmount) - Number(newDiscountAmount);

    setSubTotal(amount);
    setTaxAmount(newTaxAmount);
    setDiscountAmount(newDiscountAmount);
    setTotal(newTotal);
  }, [items, taxRate, discountRate]);

  useEffect(() => {
    handleCalculateTotal();
  }, [handleCalculateTotal]);

  const handleRowDel = (item) => {
    const updatedItems = items.filter((i) => i.id !== item.id);
    setItems(updatedItems);
  };

  useEffect(() => {
      if (payment) {
          setItems([...items, {
              id: (+new Date() + Math.floor(Math.random() * 999999)).toString(36),
              service: payment.serviceName,
               amount : ((payment.amount - (taxRate / 100) * payment.amount) + (discountRate / 100) * payment.amount).toFixed(2)


          }])
      }
  },[payment])
   console.log(items, 'items', payment.taxPercent, payment.amount)
  const handleAddEvent = () => {
    const id = (+new Date() + Math.floor(Math.random() * 999999)).toString(36);
   
    const newItem = {
        id,
        
    }
    setItems([...items, newItem]);
  };

  const onItemizedItemEdit = (evt) => {
    const { id, name, value } = evt.target;

    const updatedItems = items.map((item) =>
      item.id === id ? { ...item, [name]: value } : item
    );
    setItems(updatedItems);
    
  };

  console.log(items,'items')
 const handleSetModelHeader = (headers) => {
       setHeader(headers)
 }
  const handleChange = (setter) => (event) => {
    setter(event.target.value);
    handleCalculateTotal();
  };

  const openModal = (event) => {
    event.preventDefault();
    handleCalculateTotal();
    setIsOpen(true);
    console.log(items,'items')
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleLogoUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setLogo(reader.result);
      };
      reader.readAsDataURL(file);
    }
    console.log(logo,'logo')
  };

  return (
    <form onSubmit={openModal}>
      <Grid container spacing={4} sx={{ boxSizing: 'border-box' }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, width: '100%' ,mt:4, ml:4}}>
      
      <InvoiceModal
        showModal={isOpen}
        closeModal={closeModal}
        info={{
          dateOfIssue,
          invoiceNumber,
          billTo,
          billToEmail,
          billToAddress,
          billFrom,
          billFromEmail,
          billFromAddress,
          billToGst,
          billFromGst,
          notes,
          tearmAndCondition,
          billFromPhone,
          billToPhone
          
        }}
        items={items}
        leadId = {leadDetail._id}
        paymentId = {payment?._id}
        currency={currency}
        subTotal={subTotal}
        taxAmount={taxAmount}
        discountAmount={discountAmount}
        total={total}
        logo={logo}
        header={header}
        currentDate={currentDate}
      />
      
      {/* Currency Select */}
      <FormControl fullWidth sx={{ width: 100  }}>
        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>Currency:</Typography>
        <Select
          value={currency}
          onChange={(e) => setCurrency(e.target.value)}
          sx={{
            fontSize: '0.875rem',
            height: 36,
            borderRadius: 2,
          }}
        >
          <MenuItem value="$">USD (United States Dollar)</MenuItem>
          <MenuItem value="£">GBP (British Pound Sterling)</MenuItem>
          <MenuItem value="₹">INR (Indian Rupee)</MenuItem>
          <MenuItem value="¥">JPY (Japanese Yen)</MenuItem>
          <MenuItem value="₿">BTC (Bitcoin)</MenuItem>
        </Select>
      </FormControl>

      {/* Logo Upload Section */}
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>Upload Company Logo:</Typography>
        <input
          type="file"
          accept="image/*"
          onChange={handleLogoUpload}
          style={{
            marginTop: 8,
            fontSize: '1rem',
            padding: '8px',
            borderRadius: 8,
          }}
        />
        {logo && <img src={user?.companyLogo || logo} alt="Logo" style={{ maxWidth: 150, marginTop: 8 }} />}
      </Box>

      {/* Tax and Discount Rates */}

        <FormControl fullWidth sx={{ width: 100  }}>
          <Typography variant="body2" sx={{ fontWeight: 'bold' }}>Tax rate:</Typography>
          <TextField
            value={taxRate}
            onChange={(e) => setTaxRate(e.target.value)}
            type="number"
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            size="small"
            sx={{
              fontSize: '0.875rem',
              height: 36,
              borderRadius: 2,
              backgroundColor: 'white',
              boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
            }}
          />
        </FormControl>

        <FormControl fullWidth sx={{ width: 100 }}>
          <Typography variant="body2" sx={{ fontWeight: 'bold' }}>Discount rate:</Typography>
          <TextField
            value={discountRate}
            onChange={(e) => setDiscountRate(e.target.value)}
            type="number"
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            size="small"
            sx={{
              fontSize: '0.875rem',
              height: 36,
              borderRadius: 2,
              backgroundColor: 'white',
              boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
            }}
          />
        </FormControl>
   

      {/* Review Invoice Button */}
      <Button
        variant="contained"
        color="primary"
        type="submit"
        sx={{
          mt: 2.3,
          mb:2,
          fontSize: '0.7rem',
          height: '5vh',
          padding: '2px 20px',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          borderRadius: '8px',
          textTransform: 'none',
          '&:hover': {
            backgroundColor: '#3f51b5',
            boxShadow: '0 6px 8px rgba(0, 0, 0, 0.2)',
          },
        }}
      >
        Review Invoice
      </Button>
    </Box>
        <Grid item xs={12} md={12}>
          <Card sx={{ p: 4, mt: 3, boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', borderRadius: '10px' }}>
            <Box sx={{ display: "flex", justifyContent: "space-between", mb: 3 }}>
              <Box>
                <Typography variant="h6" sx={{ fontSize: '1rem' }}>Current Date: {currentDate}</Typography>
               
              </Box>
              <Box>
                <Typography variant="body2">Invoice Number:</Typography>
                <TextField
                  value={invoiceNumber}
                  name="invoiceNumber"
                  onChange={handleChange(setInvoiceNumber)}
                  sx={{
                    maxWidth: 200,
                    borderRadius: '8px',
                    fontSize: '1rem',
                    width: '50vh',
                    height: '36px',
                  }}
                  min="1"
                  required
                  size="small"
                />
              </Box>
            </Box>

            {/* Billing Sections */}
            <Box sx={{ mb: 5 }}>
              <Grid container spacing={4}>
                <Grid item xs={12} md={6} display={"flex"} flexDirection={"column"} gap={2}>
                  <Typography variant="body1" sx={{ fontWeight: "bold" }}>Bill From:</Typography>
                  <TextField
                    placeholder="Who is this invoice from?"
                    value={billFrom}
                    onChange={handleChange(setBillFrom)}
                    fullWidth
                    required
                    size="small"
                    sx={{
                      borderRadius: '8px',
                      boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                    }}
                  />
                  <TextField
                    placeholder="Email address"
                    value={billFromEmail}
                    type="email"
                    onChange={handleChange(setBillFromEmail)}
                    fullWidth
                    required
                    size="small"
                    sx={{
                      borderRadius: '8px',
                      boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                    }}
                  />
                    <TextField
                    placeholder="Phone Number"
                    value={billFromPhone}
                    onChange={handleChange(setBillFromPhone)}
                    fullWidth
                    required
                    size="small"
                    sx={{
                      borderRadius: '8px',
                      boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                    }}
                  />
                  <TextField
                    placeholder="Billing address"
                    value={billFromAddress}
                    onChange={handleChange(setBillFromAddress)}
                    fullWidth
                    required
                    size="small"
                    sx={{
                      borderRadius: '8px',
                      boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                    }}
                  />
                     <TextField
                    placeholder="GST Number"
                    value={billFromGst}
                    onChange={handleChange(setBillFromGst)}
                    fullWidth
                    required
                    size="small"
                    sx={{
                      borderRadius: '8px',
                      boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                    }}
                  />
                  
                </Grid>
                <Grid item xs={12} md={6} display={"flex"} flexDirection={"column"} gap={2}>
                  <Typography variant="body1" sx={{ fontWeight: "bold" }}>Bill To:</Typography>
                  <TextField
                    placeholder="Who is this invoice to?"
                    value={billTo}
                    onChange={handleChange(setBillTo)}
                    fullWidth
                    required
                    size="small"
                    sx={{
                      borderRadius: '8px',
                      boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                    }}
                  />
                  <TextField
                    placeholder="Email address"
                    value={billToEmail}
                    type="email"
                    onChange={handleChange(setBillToEmail)}
                    fullWidth
                    required
                    size="small"
                    sx={{
                      borderRadius: '8px',
                      boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                    }}
                  />
                  <TextField
                    placeholder="Phone Number"
                    value={billToPhone}
                    onChange={handleChange(setBillToPhone)}
                    fullWidth
                    required
                    size="small"
                    sx={{
                      borderRadius: '8px',
                      boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                    }}
                  />
                  <TextField
                    placeholder="Billing address"
                    value={billToAddress}
                    onChange={handleChange(setBillToAddress)}
                    fullWidth
                    required
                    size="small"
                    sx={{
                      borderRadius: '8px',
                      boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                    }}
                  />
                    <TextField
                    placeholder="GST Number"
                    value={billToGst}
                    onChange={handleChange(setBillToGst)}
                    fullWidth
                    required
                    size="small"
                    sx={{
                      borderRadius: '8px',
                      boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                    }}
                  />
                </Grid>
              </Grid>
            </Box>

            {/* Invoice Item Table */}
            <InvoiceItem
              onItemizedItemEdit={onItemizedItemEdit}
              onRowAdd={handleAddEvent}
              onRowDel={handleRowDel}
              currency={currency}
              items={items}
              setModelHeader={handleSetModelHeader}
              payment={payment}
            />

            {/* Invoice Summary */}
            <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 4 }}>
              <Box sx={{ width: "50%" }}>
                <Typography variant="body2" sx={{ fontWeight: "bold" }}>Amount:</Typography>
                <Typography>{currency}{subTotal}</Typography>
                <Typography variant="body2" sx={{ fontWeight: "bold" }}>Discount:</Typography>
                <Typography>{currency}{discountAmount || 0} ({discountRate || 0}%)</Typography>
                <Typography variant="body2" sx={{ fontWeight: "bold" }}>Tax:</Typography>
                <Typography>{currency}{taxAmount || 0} ({taxRate || 0}%)</Typography>
                <hr />
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>Total:</Typography>
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  {currency}{total || 0}
                </Typography>
              </Box>
            </Box>

            {/* Notes Section */}
            <TextField
              label="Notes"
              value={notes}
              onChange={handleChange(setNotes)}
              multiline
              rows={2}
              fullWidth
              size="small"
              sx={{
                mt: 3,
                borderRadius: '8px',
                boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
              }}
            />
              <TextField
              label="Tearms and Conditions"
              value={tearmAndCondition}
              onChange={handleChange(setTearmAndCondition)}
              multiline
              rows={2}
              fullWidth
              size="small"
              sx={{
                mt: 3,
                borderRadius: '8px',
                boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
              }}
            />
          </Card>
        </Grid>
      </Grid>
    </form>
  );
};

export default InvoiceForm;
