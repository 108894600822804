import axios from 'axios';
import axiosRetry from 'axios-retry';
import { server_url } from '../../utils/server';
import CryptoJS from 'crypto-js';

const apiClient = axios.create({
  baseURL: server_url,
  timeout: 150000, // 10 seconds timeout
});

axiosRetry(apiClient, {
  retries: 1, // Number of retries
  retryDelay: (retryCount) => retryCount * 10000, // Delay between retries (in milliseconds)
  retryCondition: (error) => {
    return axiosRetry.isNetworkOrIdempotentRequestError(error) ||
           (error.response && error.response.status >= 500);
  },
});

// Request Interceptor
apiClient.interceptors.request.use(
  (config) => {
    const tokenDate = localStorage.getItem('authToken');
   
    // Decrypt the token
    const decryptData = (encryptedData) => {
      const secretKey = process.env.REACT_APP_SECRET_KEY
      try {
        const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
        const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
        
        // If decryption failed (empty string), return null
        if (!decryptedData) {
          throw new Error('Decryption failed or malformed data');
        }

        return JSON.parse(decryptedData); // Assuming the token is a JSON string
      } catch (error) {
        console.error('Decryption Error:', error);
        return null;
      }
    };

    const token = decryptData(tokenDate);

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    } else {
      console.warn('No valid token found or decryption failed');
    }

    return config;
  },
  (error) => {
    console.error('Request Error:', error);
    return Promise.reject(error);
  }
);

// Response Interceptor
apiClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.error('Response Error:', error);
    return Promise.reject(error);
  }
);

export default apiClient;
