import React, { useEffect, useState } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import OfflineAlert from './OfflineAlert';
import Navbar from './navbar/nav';
import { LoginPage, RagisterUser } from './pages/auth';
import Employees from './pages/employee';
import Leads from './pages/leads';
import LeadDetails from './pages/leads/details';
import { PrivateRoute } from './middlewares/auth';
import Video from './pages/video';

import SetUpFileds from './pages/leads/setUpFileds';
import AdminRoute from './middlewares/auth/AdminRoute';
import FileUpload from './components/uploadFiles';
import EmplyoeeDetails from './pages/employee/details';
import Calender from './pages/calender';
import ScreenRecording from './pages/auth/puch-in-out';
import PayrollForm from './components/company/payrolleSetup';
import BirthDay from './BirthDay';
import { useGet } from './helpers/axios/useApi';
import TokenExpire from './TokenExpire';
import MainDashboard from './pages/DashBoard';
import Tearms from './pages/TearmAndCondition/Tearms';
import PrivacyPolicy from './pages/TearmAndCondition/Privecy';

const Nav = () => {
  const location = useLocation();
  const currentPath = location.pathname;
  return currentPath === '/login' || currentPath === '/register' || currentPath === '/tearms&conditions' || currentPath === '/privacy&policy' ? null : <Navbar />;
}

const App = () => {
  const [isEvent, setIsEvent] = useState(false);
  const { data: events } = useGet('events/today-events');  // Your hook to fetch events
  const { data: isAuth } = useGet('health');  // Your hook to fetch events
  const [showBirthday, setShowBirthday] = useState(false);

  // Function to clear localStorage at midnight
  const clearLocalStorageAtMidnight = () => {
    const now = new Date();
    const midnight = new Date(now);
    midnight.setHours(24, 0, 0, 0);  // Set time to midnight (12:00 AM) today

    const timeUntilMidnight = midnight.getTime() - now.getTime();

    // Set a timeout to clear localStorage at midnight
    setTimeout(() => {
      localStorage.removeItem('isEvent');
      localStorage.removeItem('isBirthdayShown'); // Clear birthday flag at midnight
      setIsEvent(false);  // Optionally reset state
      console.log('Cleared isEvent and isBirthdayShown from localStorage at midnight');
    }, timeUntilMidnight);
  };
console.log(process.env)
  // Handle setting the `isEvent` flag in localStorage
  useEffect(() => {
    const storedIsEvent = localStorage.getItem('isEvent');
    if (storedIsEvent) {
      setIsEvent(true);
    } else {
      setIsEvent(false);
    }

    // Set the isEvent flag after 10 seconds if not already set
    if (!storedIsEvent) {
      setTimeout(() => {
        localStorage.setItem('isEvent', true);
        setIsEvent(true);
      }, 10000);
    }

    // Clear localStorage at midnight
    clearLocalStorageAtMidnight();

    // Check if the birthday should be shown from localStorage
    const birthdayShown = localStorage.getItem('isBirthdayShown');
    if (!birthdayShown && events?.data?.data?.length > 0) {
      setShowBirthday(true);
      localStorage.setItem('isBirthdayShown', 'true');  // Mark that the birthday has been shown

      // Set a timer to hide the birthday after 10 seconds
      setTimeout(() => {
        setShowBirthday(false);
      }, 10000);  // 10 seconds
    }
  }, [events]);

  // Force re-check birthday on route change (using useLocation hook)
  const location = useLocation();
  
  useEffect(() => {
    const birthdayShown = localStorage.getItem('isBirthdayShown');
    if (!birthdayShown && events?.data?.data?.length > 0) {
      setShowBirthday(true);
      localStorage.setItem('isBirthdayShown', 'true');  // Mark birthday as shown

      // Hide birthday after 10 seconds
      setTimeout(() => {
        setShowBirthday(false);
      }, 15000);
    }
  }, [location, events]); // Trigger when the route changes

  return (
    <>
      {showBirthday ? (
        <BirthDay events={events?.data?.data} />
      ) : (
        <>
          <OfflineAlert />
          {(isAuth?.error?.message ===  "Invalid or expired token." || isAuth?.error?.message ===  'Authentication  is required.' ) && (location.pathname !== '/login' && location.pathname !== '/register') ? <TokenExpire/> : null}
          <Nav />
          <Routes>
          
            <Route path="/sales/dashboard" element={< MainDashboard/>} />
            <Route path="/register" element={<RagisterUser />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path='/video' element={<Video />} />
            <Route path="/leads" element={<PrivateRoute><Leads /></PrivateRoute>} />
            <Route path="/leads/:id" element={<PrivateRoute><LeadDetails /></PrivateRoute>} />
            <Route path="/employees" element={<PrivateRoute><AdminRoute><Employees /></AdminRoute></PrivateRoute>} />
            <Route path="/employees/:id" element={<PrivateRoute><EmplyoeeDetails /></PrivateRoute>} />
            <Route path="/leads/form/set-up-fields" element={<PrivateRoute><AdminRoute><SetUpFileds /></AdminRoute></PrivateRoute>} />
            <Route path="/calendar" element={<PrivateRoute><Calender /></PrivateRoute>} />
            <Route path="/tearms&conditions" element={< Tearms/>} />
            <Route path="/privacy&policy" element={< PrivacyPolicy/>} />
          </Routes>
        </>
      )}
    </>
  );
};

export default App;
