import React, { useEffect, useState } from "react";
import { useGet } from "../../helpers/axios/useApi";
import { Grid } from "@mui/system";
import { Button, Typography } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Link } from "react-router-dom";
function Meetings() {
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1); // Default to current month
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear()); // Default to current year
  const [events, setEvents] = useState([]);

  const { data: getMeetings, refetch } = useGet("meetings/get", {
    month: selectedMonth,
    year: selectedYear,
  });
  const hanldeMonthChange = (e) => {
    setSelectedMonth(e.target.value);
    refetch();
  };
  const hanldeYearChange = (e) => {
    setSelectedYear(e.target.value);
    refetch();
  };
  useEffect(() => {
    if (getMeetings?.data?.data) {
      setEvents(getMeetings.data.data);
    }
  }, [getMeetings]);
  return (
    <div style={styles.dashboardContainer}>
      <div style={styles.chartContainer}>
        {/* Date Selection */}
        <div style={styles.dateSelection}>
          <select
            value={selectedMonth}
            onChange={(e) => hanldeMonthChange(e)}
            style={styles.select}
          >
            {[
              "January",
              "February",
              "March",
              "April",
              "May",
              "June",
              "July",
              "August",
              "September",
              "October",
              "November",
              "December",
            ].map((monthName, index) => (
              <option key={index} value={index + 1}>
                {monthName}
              </option>
            ))}
          </select>
          <select
            value={selectedYear}
            onChange={(e) => hanldeYearChange(e)}
            style={styles.select}
          >
            {Array.from({ length: 10 }, (_, i) => (
              <option key={i} value={2020 + i}>
                {2020 + i}
              </option>
            ))}
          </select>
          <Button
            sx={{
              backgroundColor: "white",
              borderRadius: "10px",
              fontSize: "12px",
              position: "absolute",
              right: "10px",
              border: "1px solid black",
              color: "black",
            }}
          >
            Create Meeting
          </Button>
        </div>
        <Grid
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            height: "30vh",
            overflow: "scroll",
            padding: "5px",
          }}
        >
          {events &&
            events.map((meeting) => {
              return (
                <Grid
                  display={"flex"}
                  position={"relative"}
                  alignItems={"center"}
                  gap={"10px"}
                  sx={{
                    backgroundColor: "white",
                    padding: "10px",
                    borderRadius: "15px",
                    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.05)",
                  }}
                >
                  <Grid
                    sx={{
                      height: "60px",
                      width: "60px",
                      backgroundColor: "white",
                      boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
                      paddingTop: "10px",
                      borderRadius: "10px",
                    }}
                  >
                    <Typography color="FFA600">
                      {
                        new Date(meeting.meetingDate)
                          .toDateString()
                          .split(" ")[0]
                      }{" "}
                      {/* Day of the week */}
                    </Typography>
                    <Typography
                      color="black"
                      fontWeight={"bold"}
                      fontSize={"12px"}
                    >
                      {
                        new Date(meeting.meetingDate)
                          .toLocaleDateString()
                          .split("/")[0]
                      }{" "}
                      {/* Full date */}
                    </Typography>
                  </Grid>
                  <Grid
                    display={"flex"}
                    flexDirection={"column"}
                    justifyContent={"flex-start"}
                  >
                    <Typography
                      fontSize={"14px"}
                      fontWeight={"bold"}
                      color="black"
                      width={"100%"}
                    >
                      {meeting.meetingName}
                    </Typography>
                    <Typography
                      fontSize={"12px"}
                      color="black"
                      width={"100%"}
                      textAlign={"left"}
                    >
                      {(() => {
                        const date = new Date(meeting.meetingDate);
                        let hours = date.getHours();
                        const minutes = String(date.getMinutes()).padStart(
                          2,
                          "0"
                        );

                        // Determine AM/PM and adjust hours
                        const period = hours >= 12 ? "PM" : "AM";
                        hours = hours % 12 || 12; // Convert 24-hour to 12-hour format

                        return `${hours}:${minutes} ${period}`;
                      })()}
                    </Typography>
                  </Grid>
                  <Grid position={"absolute"} right={"10px"}>
                    <Link to={`${meeting.meetingLink}`} target="_blank">
                      <OpenInNewIcon
                        sx={{
                          color: "#007BFF",
                          "&:hover": { color: "#66b3ff" },
                          "&:active": { color: "#0056b3" },
                          transition: "color 0.3s ease",
                        }}
                      />
                    </Link>
                  </Grid>
                </Grid>
              );
            })}
        </Grid>
      </div>
    </div>
  );
}
const styles = {
  dashboardContainer: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    marginTop: "-20px",
    width: "100%",
    maxWidth: "800px",
    borderRadius: "20px",
  },
  chartContainer: {
    display: "flex",
    position: "relative",
    justifyContent: "center",
    flexDirection: "column",
    width: "100%",
    maxWidth: "800px",
    padding: "10px",
    borderRadius: "20px",
    position: "relative",
  },

  chartWrapper: {
    width: "100%",
    marginTop: "-10px",
  },
  dateSelection: {
    display: "flex",
    justifyContent: "left",
    color: "grey",
    height: "30px",
    marginBottom: "20px",
    alignItems: "top",
  },
  select: {
    fontSize: "14px",
    borderRadius: "5px",
    outLine: "none",
    border: "none",
    color: "grey",
    backgroundColor: "transparent",
  },
  button: {
    padding: "8px 15px",
    backgroundColor: "#00E396",
    color: "#fff",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },
};

export default Meetings;
