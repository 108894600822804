import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  Box,
  Button,
  Typography,
  IconButton,
  Paper,
  Fab,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useTheme,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import BackHandIcon from "@mui/icons-material/BackHand";
import GridLayout from "react-grid-layout"; // For drag-and-drop and resizing
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import DeleteIcon from "@mui/icons-material/Delete";
import ProfileNav from "../../components/user/profiveNav";
import TotalCalls from "./TotalCalls";
import TotalCallsAndSessions from "./TotalCallsAndSessions";
import TotalSessions from "./TotalSessions";
import TotalBookings from "./TotalBookings";
import TotalCallsAndBookings from "./TotalCallsAndBookings";
import TotalBookingsAndSessions from "./TotalBookingsAndSessions";
import CallAndBookingAndSession from "./CallAndBookingAndSession";
import { Grid } from "@mui/system";
import { useLocation } from "react-router-dom";
import { FaHandRock } from "react-icons/fa";
import GlassEffect from "../../theme/glassEffect";
import HRMDashboard from "./HRMDashboard";
import Leaves from "./Leaves";
import SwitchTeamSelfButton from "./Switch";
import TotalCallGague from "./TotalCallGague";
import Meetings from "./Meetings";
import MeetingCalender from "./MeetingCalender";

// Sample Widget List
const widgetList = [
  { id: "TotalCalls", title: "Total Calls", component: TotalCalls , height : 12, width : 6},
  { id: "TotalSessions", title: "Total Sessions", component: TotalSessions ,height : 12},
  { id: "TotalBookings", title: "Total Bookings", component: TotalBookings , height : 12},
  {
    id: "TotalCallsAndSessions",
    title: "Total Sessions & Calls",
    component: TotalCallsAndSessions,
    height : 12
  },
  {
    id: "TotalCallsAndBookings",
    title: "Total Bookings & Calls",
    component: TotalCallsAndBookings,
    height : 12
  },
  {
    id: "TotalBookingsAndSessions",
    title: "Total Bookings & Sessions",
    component: TotalBookingsAndSessions,
    height : 12
  },
  {
    id: "CallAndBookingAndSession",
    title: "Total Bookings , Calls & Sessions",
    component: CallAndBookingAndSession,
    height : 12
  },
  { id: "Leaves", title: "Leaves", component: Leaves ,height : 8,width : 4},
  { id: "HRMDashboard", title: "Punctuality", component: HRMDashboard ,height : 8.3,width : 4},
  { id : "TotalTeamTarget", title: "Target Achievement", component: TotalCallGague,height : 9.5 , width : 8},
  {id: "meetings", title:"Meetings", component : Meetings, height : 11,width : 4},
  {id: "meetingsCalender", title:"Meetings Calender", component : MeetingCalender, height : 17},
];

const MainDashboard = ({ employeesData, employees, leaves }) => {
  const [widgets, setWidgets] = useState([]); // Store widget data
  const [layout, setLayout] = useState([]); // Store layout data (position, size)
  const [openDialog, setOpenDialog] = useState(false);
  const [draggingWidgetId, setDraggingWidgetId] = useState(false); // Track dragging state
 const theme = useTheme();
  const location = useLocation();
  const [isTeam, setIsTeam] = useState(false);
  const currentPath = location.pathname;
  // Memoize widget list to avoid unnecessary recalculations
  const memoizedWidgetList = useMemo(() => widgetList, []);
 
  const isDarkMode = theme.palette.mode === 'dark';
  // Load widgets and layout from localStorage
  // Load widgets and layout from localStorage
  useEffect(() => {
    const storedWidgets = JSON.parse(localStorage.getItem("widgets")) || [];
    const storedLayout = JSON.parse(localStorage.getItem("layout")) || [];

    // Load widgets with the correct size and visibility state
    if (storedWidgets.length > 0) {
      const updatedWidgets = storedWidgets.map((item) => {
        const widgetData = widgetList.find((widget) => widget.id === item.id);
        if (widgetData) {
          return {
            ...widgetData,
            size: item.size,
            isOpen: item.isOpen,
          };
        }
        return item;
      });

      setWidgets(updatedWidgets);
    }

    // Ensure valid layout properties (x, y, w, h)
    const validLayout = storedLayout.map((item) => ({
      i: item.i,
      x: item.x || 0, // Default x position
      y: item.y || 0, // Default y position
      w: item.w || 6, // Default width
      h: item.h || 4, // Default height, in case it wasn't persisted
    }));

    setLayout(validLayout);
  }, []);

  // Save widgets and layout to localStorage whenever they change
  useEffect(() => {
    if (widgets.length > 0) {
      localStorage.setItem("widgets", JSON.stringify(widgets));
    }
    if (layout.length > 0) {
      localStorage.setItem("layout", JSON.stringify(layout));
    }
  }, [widgets, layout]);

  // Add new widget
  const addWidget = (widgetData) => {
    const newWidget = {
      id: widgetData.id,
      title: widgetData.title,
      component: widgetData.component, // Store component reference
      size: { w: widgetData.width, h: widgetData.height }, // Default size for the widget
      isOpen: true,
    };

    // Add a new layout item with valid properties
    const newLayout = [
      ...layout,
      {
        i: newWidget.id,
        x: 0, // Initial x position
        y: 0, // Initial y position
        w: widgetData.width, // Width (default)
        h: widgetData.height, // Height (default)
      },
    ];

    setWidgets([...widgets, newWidget]);
    setLayout(newLayout);
    setOpenDialog(false); // Close dialog after adding the widget
  };

  // Remove widget
  const removeWidget = (id) => {
    setWidgets(widgets.filter((widget) => widget.id !== id));
    setLayout(layout.filter((item) => item.i !== id));
  };

  // Toggle widget visibility (collapse/expand)
  const toggleWidget = (id) => {
    // Step 1: Toggle the widget's visibility (isOpen state)
    setWidgets((prevWidgets) =>
      prevWidgets.map((widget) =>
        widget.id === id
          ? { ...widget, isOpen: !widget.isOpen } // Toggle the visibility state
          : widget
      )
    );

    // Step 2: Update the layout after the widget visibility is toggled
    setLayout((prevLayout) => {
      // Find the updated widget in the widgets array
      const updatedWidget = widgets.find((widget) => widget.id === id);
    
      // Define the dynamic height based on your criteria
      const newHeight = updatedWidget 
        ? (updatedWidget.isOpen 
          ? 3 // Use maxHeight if defined, otherwise 12
          : (updatedWidget.height || 12)) // Height when widget is closed
        : 3; // Default height if widget is not found
    
      return prevLayout.map((item) =>
        item.i === id
          ? { ...item, h: newHeight } // Update height of the item
          : item
      );
    });
  };

  // Handle layout change from drag-and-drop
  const handleLayoutChange = useCallback((newLayout) => {
    // Ensure each layout item has valid properties
    const validLayout = newLayout.map((item) => ({
      i: item.i,
      x: item.x || 0,
      y: item.y || 0,
      w: item.w || 6, // Ensure width is defined
      h: item.h || 4, // Ensure height is defined
    }));

    setLayout(validLayout);
  }, []);

  const handleTeamSwitch = () => {
    setIsTeam(!isTeam);
  }

  // Memoize widgets list to avoid unnecessary re-renders
  const memoizedWidgets = useMemo(() => widgets, [widgets]);

  
  return (

  
    <Box
      sx={{
        padding: 3,
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        gap: 2,
        overflowY: "auto",
        width: "100%",
      }}
    >
      {/* Profile Navigation */}
      <Box sx={{ display: "flex" , justifyContent:'flex-end', alignContent:'center', alignItems:'center', gap: 2}}>
        {currentPath === "/sales/dashboard" && (
          <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 0 , width:'80%'}}>
            <ProfileNav />
          </Box>
        )}
        <Grid
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          
            width: "5%",
            alignItems: "center",
          }}
        >
          <SwitchTeamSelfButton isTeam={isTeam} onChange={handleTeamSwitch}/>
        </Grid>
      </Box>

      <Box height={"50vh"}>
        <GridLayout
          className="layout"
          layout={layout}
          cols={12}
          width={1300}
          rowHeight={30}
          onLayoutChange={handleLayoutChange}
          isDraggable={draggingWidgetId} // Only allow drag if dragging is enabled
          isResizable={true}
          style={{
            marginTop: "10px",
            minHeight: "100%",
            height: "auto",
          }}
        >
          {memoizedWidgets.map((widget) => (
            <div
              key={widget.id}
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "10px",
                position: "relative",
              }}
            >
              <Paper
                sx={{
                  padding: 2,
                  marginBottom: 2,
                  backgroundColor: "white",
                  borderRadius: "20px",
                  boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                  position: "relative",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: 1,
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{ color: "#333", fontSize: "14px", fontWeight: "bold" }}
                  >
                    {widget.title}
                  </Typography>
                  <Box>
                    <IconButton onClick={() => toggleWidget(widget.id)}>
                      {widget.isOpen ? (
                        <KeyboardDoubleArrowDownIcon
                          sx={{ color: "grey", marginLeft: 1 }}
                        />
                      ) : (
                        <KeyboardDoubleArrowUpIcon
                          sx={{ color: "green", marginLeft: 1 }}
                        />
                      )}
                    </IconButton>
                    <IconButton onClick={() => removeWidget(widget.id)}>
                      <DeleteIcon sx={{ color: "#f0a8b4", marginLeft: 1 }} />
                    </IconButton>
                  </Box>
                </Box>
                {widget.isOpen && React.createElement(widget.component, {isTeam : isTeam , widget : widget.id}, )}{" "}
                {/* Render the widget component dynamically */}
              </Paper>
            </div>
          ))}
        </GridLayout>
      </Box>

      {/* Floating Action Button for Adding Widgets */}
      <Fab
        color="primary"
        aria-label="add"
        onClick={() => setOpenDialog(true)}
        sx={{
          position: "fixed",
          bottom:40,
          right: 20,
          backgroundColor: theme.palette.primary.main,
          "&:hover": {
            backgroundColor: theme.palette.primary.dark,
          },
        }}
      >
        <AddIcon />
      </Fab>

      {/* Floating Action Button for Dragging Widget */}
      <Fab
        color="primary"
        aria-label="drag"
        onClick={() => setDraggingWidgetId(!draggingWidgetId)}
        sx={{
          position: "fixed",
          bottom: 105,
          right: 20,
          backgroundColor: theme.palette.primary.main,
          "&:hover": {
            backgroundColor: theme.palette.primary.dark,
          },
        }}
      >
        {!draggingWidgetId && <BackHandIcon
        />}
       {draggingWidgetId && <FaHandRock/>}
      </Fab>

      {/* List of Widgets to Add - Dialog */}
      <Dialog
  open={openDialog}
  onClose={() => setOpenDialog(false)}
  maxWidth="md"
  PaperProps={{
    sx: {
      minWidth: "130vh",
      borderRadius: 10, // Increased border radius for rounded corners
      boxShadow: 24,
      padding: 4, // More padding for better spacing
      backgroundColor: "#f5f5f5", // Lighter background color
    },
  }}
>
  <DialogTitle sx={{ fontWeight: "bold", fontSize: "1.5rem", textAlign: "center" }}>
    Select a Widget
  </DialogTitle>
  <DialogContent sx={{ width: "100%" }}>
    <Grid container spacing={3} width={"100%"} mt={2}>
      {memoizedWidgetList.map((widget) => (
        <Grid item xs={12} sm={12} md={12} key={widget.id}>
          <Button
            onClick={() => addWidget(widget)}
            fullWidth
            variant="contained"
            disabled={widgets.some((w) => w.id === widget.id)}
            color="primary"
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: "20px 50px", // Increased padding for more clickable area
              borderRadius: 3, // Rounded button corners
              boxShadow: 4, // Subtle shadow effect
              textAlign: "center",
              fontWeight: "bold", // Make widget titles bold
              fontSize: "0.7rem", // Larger font size for readability
              transition: "all 0.3s ease-in-out", // Smooth transition on hover
              "&:hover": {
                transform: "scale(1.05)", // Scale up the button slightly on hover
                boxShadow: 6, // Enhance shadow on hover
                backgroundColor: "#1976d2", // Change color on hover
              },
              "&:disabled": {
                backgroundColor: "#e0e0e0", // Disabled button color
                boxShadow: "none",
                color: "#9e9e9e",
              },
            }}
          >
            {widget.title}
          </Button>
        </Grid>
      ))}
    </Grid>
  </DialogContent>
  <DialogActions sx={{ justifyContent: "center", padding: 3 }}>
    <Button
      onClick={() => setOpenDialog(false)}
      color="secondary"
      variant="outlined"
      sx={{
        padding: "10px 30px", // Padding for a more clickable "Cancel" button
        borderRadius: 3, // Rounded cancel button
        fontWeight: "bold", // Bold text for clarity
        fontSize: "1rem", // Slightly larger text for readability
        transition: "background-color 0.3s ease", // Smooth color transition
        "&:hover": {
          backgroundColor: "#f44336", // Red background color on hover
          color: "white",
        },
      }}
    >
      Cancel
    </Button>
  </DialogActions>
</Dialog>


      {/* Grid Layout for Widgets */}

      {/* Custom CSS to change resize handle color based on the theme */}
      {/* <style jsx global>{`
        .react-resizable-handle {
          background-color: ${theme.palette.primary.main} !important;
        }
        .react-resizable-handle:hover {
          background-color: ${theme.palette.primary.dark} !important;
        }
      `}</style> */}
    </Box>
  );
};

export default MainDashboard;
