import { useTheme } from "@emotion/react";
import React from 'react';
import ReactApexChart from 'react-apexcharts';
const ApexChartWidget = ({ data, chartType, height }) => {
  const theme = useTheme(); // Access the current theme
  
  // Log data to verify its structure
  console.log("Chart Data:", data);
  
  // Validate data before passing to ReactApexChart
  const seriesData = Array.isArray(data.series) && data.series.length > 0 ? data.series : [{ name: 'No Data', data: [] }];
  const chartOptions = {
    ...data.options || {},
    chart: {
      background: 'transparent', // Chart background color
      toolbar: { show: false }, // Hide toolbar if not needed
      theme: {
        mode: 'dark', // Set chart theme based on mode
      }
    },
    theme: {
      mode: 'dark', 
      palette: 'palette1', 
      monochrome: {
          enabled: false,
          color: '#255aee',
          shadeTo: 'light',
          shadeIntensity: 0.65
      },
  },
    grid: {
      show: false, // Disable grid lines
      padding: {
        left: 0, // or whatever value that works
        right: 0 // or whatever value that works
      }
    },
    stroke: {
      width: 3.5, // Line width for line charts
      curve: 'smooth', // Optional, for smooth curves on line charts
    },
    xaxis: {
      ...data.options.xaxis || {},
      labels: {
        style: {
          colors: 'black', // X-axis label color based on theme
          display:'none'
        },
      },
      axisTicks: {

        show: true, // Show ticks on the y-axis
        borderType: 'solid',
        color: theme.palette.mode === 'dark' ? 'white' : 'black', // Color of the ticks
        width: 2, // Width of the ticks

      },
      
    },
    yaxis: {
      ...data.options.yaxis || {},
      labels: {
        style: {
          colors: 'black', // Y-axis label color based on theme
        },
      },
      axisBorder: {
        show: false,
        color: theme.palette.mode === 'dark' ? 'white' : 'black', // Color of y-axis line
        width: 2, // Width of y-axis line
      },
      axisTicks: {
        show: true, // Show ticks on the y-axis
        borderType: 'solid',
        color: theme.palette.mode === 'dark' ? 'white' : 'black', // Color of the ticks
        width: 2, // Width of the ticks
      },
    },
    tooltip: {
      theme: theme.palette.mode === 'dark' ? 'dark' : 'light', // Change tooltip theme based on the mode
    },
    title: {
      style: {
        color: 'grey', // Title text color based on theme
      },
    },
    plotOptions: {
      bar: {
        columnWidth: '50%', // Customize bar width for bar charts (optional)
      },
    },

  };

  return (
    <div style={{ width: '100%', height: '100%', overflow: 'scroll', zIndex: 999999 }}>
      <ReactApexChart
        options={chartOptions || {}}
        series={seriesData}
        type={chartType}
        height={height}
        width={'100%'}
      />
    </div>
  );
};

export default ApexChartWidget;
