import React, { useState, useEffect } from 'react';
import {
  TextField,
  Typography,
  Box,
  Container,
  Grid,
  Select,
  MenuItem,
  InputLabel,
  Checkbox,
  Button,
  Collapse,
  IconButton,
  FormControl,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useDispatch, useSelector } from 'react-redux';
import { useGet } from '../../../helpers/axios/useApi';

const generateIdFromLabel = (label) => {
  const cleanedLabel = label.trim().replace(/\s+/g, " ");
  return cleanedLabel.includes(" ")
    ? cleanedLabel.split(" ").map((word, index) =>
        index === 0
          ? word.toLowerCase()
          : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
      ).join("")
    : cleanedLabel.toLowerCase();
};

const ColumnFilterPage = () => {
  const [filterValues, setFilterValues] = useState(() => {
    const savedFilters = localStorage.getItem('columnFilters');
    try {
      const parsed = JSON.parse(savedFilters);
      return Array.isArray(parsed) ? parsed : []; // Ensure it's always an array
    } catch {
      return []; // Return empty array on parse error
    }
  });

  const [columns, setColumns] = useState([]);
  const [employees, setEmployees] = useState([]);
  const { column } = useSelector((state) => state.leads);
  const { data: employeeData, isLoading: employeesLoading } = useGet("employee/all", {}, {}, { queryKey: "employees" });
  const [expandedFilters, setExpandedFilters] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    const newColumns = column?.filter((col) => col.visible).map((col) => ({
      ...col,
      id: generateIdFromLabel(col.label),
      filterable: true,
    })) || [];
    setColumns(newColumns);
  }, [column]);

  useEffect(() => {
    if (employeeData) {
      setEmployees(employeeData?.data?.message[0] || []);
    }
  }, [employeeData]);

  const updateFilterValue = (columnId, value, contains) => {
    setFilterValues((prev) => {
      const index = prev.findIndex(filter => filter.id === columnId);
      if (index > -1) {
        // Update existing filter
        const updatedFilters = [...prev];
        updatedFilters[index] = { id: columnId, value, contains };
        return updatedFilters;
      } else {
        // Add new filter
        return [...prev, { id: columnId, value, contains }];
      }
    });
  };

  const applyFilters = async () => {
    const activeFilters = {};
    const contain = {};
    
    filterValues.forEach(filter => {
      if (filter.value) {
        if (activeFilters[filter.id]) {
          activeFilters[filter.id] = Array.isArray(activeFilters[filter.id])
            ? [...new Set([...activeFilters[filter.id], filter.value])]
            : [activeFilters[filter.id], filter.value];
        } else {
          activeFilters[filter.id] = filter.value; // Assign the filter value
        }
        contain[filter.id] = 'true'; // Set contain to 'true'
      }
    });

    const filterData = {
      activeFilters,
      contain,
    };

    localStorage.setItem('columnFilters', JSON.stringify(filterData));
    
    dispatch({
      type: "APPLY_CANVAN_FILTER",
      payload: true,
    });
  };

  const handleFilterChange = (columnId, event) => {
    const { value } = event.target;
    const contains = filterValues?.find(filter => filter.id === columnId)?.contains || false;

    setFilterValues((prev) => {
      const index = prev.findIndex(filter => filter.id === columnId);
      if (index > -1) {
        // Update existing filter, merging values if necessary
        const updatedFilters = [...prev];
        const currentFilterValue = updatedFilters[index].value || [];

        if (Array.isArray(currentFilterValue)) {
          const newValues = Array.isArray(value) ? value : [value];
          updatedFilters[index].value = [...new Set([...currentFilterValue, ...newValues])];
        } else {
          updatedFilters[index].value = value;
        }
        return updatedFilters;
      } else {
        return [...prev, { id: columnId, value: value, contains }];
      }
    });
  };

  const resetFilters = () => {
    setFilterValues([]);
    setExpandedFilters({});
    localStorage.removeItem('columnFilters');

    dispatch({
      type: "APPLY_CANVAN_FILTER",
      payload: false
    });
  };

  const getFilterType = (columnType, columnId) => {
    const currentFilter = Array.isArray(filterValues) 
      ? filterValues.find(filter => filter.id === columnId) 
      : {}; // Safeguard for non-array

    const filterValue = currentFilter?.value || (Array.isArray(currentFilter?.value) ? [] : ""); // Safeguard for undefined value

    switch (columnType) {
      case "text":
      case "number":
        return (
          <TextField
            size="small"
            value={filterValue}
            onChange={(event) => handleFilterChange(columnId, event)}
            type={columnType}
            placeholder="Filter"
            fullWidth
          />
        );

      case "date":
        return (
          <TextField
            size="small"
            value={filterValue}
            onChange={(event) => handleFilterChange(columnId, event)}
            type="date"
            placeholder="Filter"
            fullWidth
          />
        );

      case "select":
        return (
          <>
          {columnId === 'assignee' ? (<>
            <FormControl fullWidth>
            <InputLabel id={`select-label-${columnId}`}>Filter</InputLabel>
            <Select
              labelId={`select-label-${columnId}`}
              value={Array.isArray(filterValue) ? filterValue : []} // Ensure value is an array
              onChange={(event) => handleFilterChange(columnId, event)}
              size="small"
              multiple={columnId === "assignee" || columnId === "owner"}
              renderValue={(selected) => {
                const selectedNames = selected.map(id => employees.find(emp => emp._id === id)?.name || id);
                return selectedNames.join(', ');
              }}
            >
              {columnId === "assignee" || columnId === "owner" ? (
                employees.map((employee) => (
                  <MenuItem key={employee._id} value={employee._id}>
                    <Checkbox checked={filterValue.includes(employee._id)} />
                    {employee.name}
                  </MenuItem>
                ))
              ) : (
                <>
                  <MenuItem value="true">True</MenuItem>
                  <MenuItem value="false">False</MenuItem>
                  <MenuItem value="">All</MenuItem>
                </>
              )}
            </Select>
          </FormControl>
          </>) : (<>
            <FormControl fullWidth>
  <InputLabel id={`select-label-${columnId}`}>
    Filter
  </InputLabel>
  <Select
    labelId={`select-label-${columnId}`}
    value={filterValue[columnId] || []}
    onChange={(event) => handleFilterChange(columnId, event)}
    size="small"
    sx={{
      "& .MuiOutlinedInput-root": {
        borderRadius: "15px",
        "& fieldset": {
          borderColor: "#ced1d6",
        },
        "&:hover fieldset": {
          borderColor: "#398bf7",
        },
        "&.Mui-focused fieldset": {
          borderColor: "#398bf7",
        },
      },
    }}
  >
    {columns
      .find((col) => generateIdFromLabel(col.label) === columnId)
      ?.options?.map((value) => (
        <MenuItem value={value} key={value}>
          {value}
        </MenuItem>
      ))}
  </Select>
</FormControl>
          </>)}
         
          </>
        );

      default:
        return null;
    }
  };

  const toggleFilterExpansion = (columnId) => {
    setExpandedFilters((prev) => ({
      ...prev,
      [columnId]: !prev[columnId],
    }));
  };

  return (
    <Container sx={{ width: '100%', height: '100%', bgcolor: 'white' }}>
      {employeesLoading ? (
        <Typography>Loading employees...</Typography>
      ) : (
        <Box bgcolor="transparent" display="flex" flexDirection="column" gap={0} pt={2} width={'100%'}>
          {columns.map((col) => (
            <Box key={col.id}>
              <Box
                display="flex"
                alignItems="center"
                onClick={() => toggleFilterExpansion(col.id)}
                sx={{
                  cursor: 'pointer',
                  justifyContent: 'space-between',
                  border: '1px solid lightgrey',
                  borderRadius: '5px',
                  padding: '5px',
                }}
              >
                <Typography variant="h6" style={{ marginRight: 5 }} fontSize={"14px"}>
                  {col.label}
                </Typography>
                <IconButton size="small">
                  {expandedFilters[col.id] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </Box>
              <Collapse in={expandedFilters[col.id]} sx={{ display: 'flex', flexDirection: 'column', gap: '1rem', m: 1, maxWidth: '200px' }}>
                <Grid width={"170px"} display={"flex"} flexDirection={"column"} gap={1}>
                  {getFilterType(col.type, col.id)}
                  <Button variant="contained" onClick={applyFilters}>Apply Filter</Button>
                </Grid>
              </Collapse>
            </Box>
          ))}
          <Box display="flex" gap={2} marginTop={1}>
            <Button variant="outlined" onClick={resetFilters}>Reset All Filters</Button>
          </Box>
        </Box>
      )}
    </Container>
  );
};

export default ColumnFilterPage;
