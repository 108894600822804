import React, { useState, useEffect } from "react";
import ApexChartWidget from "./ApexChartWidget";
import { useGet, usePost } from "../../helpers/axios/useApi";
import { useLocation } from "react-router-dom";
import { useAuth } from "../../middlewares/auth";
import { format, getDaysInMonth } from "date-fns";
import { useTheme } from "@emotion/react";
import { Autocomplete, TextField, Typography } from "@mui/material";
import { borderRadius, Box, Grid, positions } from "@mui/system";
import ReactApexChart from "react-apexcharts";
import {
  GaugeContainer,
  GaugeValueArc,
  GaugeReferenceArc,
  useGaugeState,
  gaugeClasses,
} from "@mui/x-charts/Gauge";

const colorArray = [
  "#FF5733",
  "#33FF57",
  "#3357FF",
  "#F1C40F",
  "#9B59B6",
  "#FF6347",
  "#8E44AD",
  "#1F618D",
  "#F39C12",
  "#2ECC71",
  "#E74C3C",
  "#3498DB",
  "#2C3E50",
  "#1ABC9C",
  "#D35400",
  "#9B59B6",
  "#34495E",
  "#7F8C8D",
  "#D5DBDB",
  "#A93226",
  "#5D6D7E",
  "#7FB3D5",
  "#85929E",
  "#F4D03F",
  "#E67E22",
  "#16A085",
  "#2980B9",
  "#8E44AD",
  "#9C27B0",
  "#00BCD4",
  "#8E44AD",
  "#D32F2F",
  "#1976D2",
  "#388E3C",
  "#0288D1",
  "#C2185B",
  "#7B1FA2",
  "#E91E63",
  "#00B8D4",
  "#43A047",
  "#F44336",
  "#9E9D24",
  "#00C853",
  "#9E1B32",
  "#3F51B5",
  "#FF8F00",
  "#00897B",
  "#7B2CBF",
  // Adding more colors
  "#FF00FF",
  "#00FFFF",
  "#FFD700",
  "#7CFC00",
  "#DC143C",
  "#00FF00",
  "#ADFF2F",
  "#FFD700",
  "#800080",
  "#A52A2A",
  "#808000",
  "#FF1493",
  "#00BFFF",
  "#8A2BE2",
  "#B0C4DE",
  "#FF8C00",
  "#FF4500",
  "#20B2AA",
  "#008080",
  "#D2691E",
  "#ADFF2F",
  "#FF1493",
  "#F08080",
  "#B22222",
];

let usedColors = [];

const generateUniqueColor = () => {
  if (usedColors.length === colorArray.length) {
    // If all colors are used up, reset the usedColors array to start over
    usedColors = [];
  }

  let randomIndex;
  do {
    randomIndex = Math.floor(Math.random() * colorArray.length); // Get a random index
  } while (usedColors.includes(randomIndex)); // Ensure it's not a used color

  usedColors.push(randomIndex); // Mark this color as used

  return colorArray[randomIndex]; // Return the color from the array
};

const TotalCallGague = ({ isTeam, widget }) => {
  const [view, setView] = useState("monthly"); // 'daily', 'monthly', or 'yearly'
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth()); // Default to current month
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear()); // Default to current year
  const [selectedDate, setSelectedDate] = useState(new Date()); // Default to today's date
  const [chartCallData, setChartCallData] = useState({
    series: [],
    options: {},
  });
  const [chartSessionData, setChartSessionData] = useState({
    series: [],
    options: {},
  });
  const [chartBookingData, setChartBookingData] = useState({
    series: [],
    options: {},
  });
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [members, setMembers] = useState([]);
  const theme = useTheme();
  const getTotalBookings = usePost("/leads/dashboard/get-bookings");
  const getTotalSessions = usePost("/leads/dashboard/get-sessions");
  const getTotalCalls = usePost("/leads/dashboard/get-calls");
  const {
    data: employees,
    refetch: refetchEmployees,
    isLoading,
  } = useGet("employee/all", {}, {}, { queryKey: "employees" });

  const { user } = useAuth();
  const location = useLocation();
  const currentPath = location.pathname;

  // Fetch the call data based on view and date selection
  useEffect(() => {
    if (widget) {
      fetchCallsData(view);
    }
  }, [
    view,
    selectedMonth,
    selectedYear,
    selectedDate,
    employees,
    selectedMembers,
    isTeam,
  ]); // Refetch when dependencies change

  const handleEmptyData = () => {
    setChartData({
      series: [{ name: "Calls", data: [] }],
      options: {}, // Empty options for now, can add default settings
    });
  };

  // Function to fetch data based on the current view type
  const fetchCallsData = async (view) => {
    const id =
      selectedMembers?.length > 0
        ? selectedMembers
        : !isTeam
        ? [user?._id] // If in sales dashboard, use the logged-in user ID
        : employees?.data?.message[0]?.map((employee) => employee._id); // Use employee IDs if available
    const details = { employeeIds: id };

    let query;
    // Handle different view types (daily, monthly, yearly)
    if (view === "daily") {
      query = { date: format(selectedDate, "yyyy-MM-dd") }; // Send date as 'yyyy-MM-dd'
    } else if (view === "monthly") {
      query = { month: selectedMonth + 1, year: selectedYear }; // Send month (1-12) and year (yyyy)
    } else if (view === "yearly") {
      query = { year: selectedYear }; // Send only the year (yyyy)
    }

    try {
      // Log query to check what data we are requesting
      console.log("Query:", query);

      const [bookingsResponse, sessionsResponse, callsResponse] =
        await Promise.all([
          getTotalBookings.mutateAsync({ ...query, ...details }),
          getTotalSessions.mutateAsync({ ...query, ...details }),
          getTotalCalls.mutateAsync({ ...query, ...details }),
        ]);

      // Check if any of the responses is empty and handle it
      if (
        !bookingsResponse?.data?.data ||
        !sessionsResponse?.data?.data ||
        !callsResponse?.data?.data
      ) {
        handleEmptyData();
      } else {
        // Process data for all metrics
        const callData = processData(callsResponse?.data?.data, "Calls");
        const bookingData = processData(
          bookingsResponse?.data?.data,
          "Bookings"
        );
        const sessionData = processData(
          sessionsResponse?.data?.data,
          "Sessions"
        );

        setChartCallData({
          series: callData.series,
          options: callData.options,
        });
        setChartSessionData({
          series: sessionData.series,
          options: sessionData.options,
        });
        setChartBookingData({
          series: bookingData.series,
          options: bookingData.options,
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getEmployeeName = (id) => {
    console.log(id, "id");
    if (
      !employees?.data?.message ||
      !Array.isArray(employees?.data?.message[0])
    ) {
      return "Unknown";
    }

    let employee = employees?.data?.message[0]?.find(
      (employee) => employee._id === id
    );
    console.log(employee, "employee");
    return employee ? `${employee.name}` : "Unknown";
  };

  // Process the response data to extract categories, call counts, and employee names
  const processData = (data, type) => {
    let categories = [];
    let series = [];
    let total = 0;
    const maxCalls = 5000;
    const maxSessions = 30;
    const maxBookings = 100;
    if (view === "daily") {
      categories = Array.from({ length: 24 }, (_, hour) => `${hour}`);
      data.forEach((employeeData) => {
        const Counts = categories.map(
          (hour) => employeeData?.hourlyCount?.[hour] || 0
        ); // Fallback to 0
        const totalData = Counts.reduce((acc, count) => acc + count, 0); // Aggregate the call counts for this employee
        total += totalData; // Add to the total calls
      });
      series.push(total);
    } else if (view === "monthly") {
      const daysInMonth = getDaysInMonth(new Date(selectedYear, selectedMonth)); // Auto-calculate days
      categories = Array.from(
        { length: daysInMonth ? daysInMonth : 0 },
        (_, day) => day + 1
      );
      data.forEach((employeeData) => {
        const Counts = categories.map(
          (day) => employeeData?.dailyCount?.[day] || 0
        ); // Use 0 if undefined
        const totalData = Counts.reduce((acc, count) => acc + count, 0); // Aggregate the call counts for this employee
        total += totalData; // Add to the total calls
      });
      series.push(total);
    } else if (view === "yearly") {
      categories = Array.from({ length: 12 }, (_, month) => `${month + 1}`);
      data.forEach((employeeData) => {
        const Counts = categories.map(
          (month) => employeeData?.monthlyCount?.[month] || 0
        ); // Fallback to 0
        const totalData = Counts.reduce((acc, count) => acc + count, 0); // Aggregate the call counts for this employee
        total += totalData; // Add to the total calls
      });
      series.push(total);
    }

    // Ensure the series data is a number and not null
    if (series.length === 0 || series[0] === null || series[0] === undefined) {
      series = [0]; // Provide a fallback value if no data is available
    }
    console.log(series, "series");
    // Return series data in the format ApexCharts expects for a radialBar chart
    return {
      series: series, // Use the aggregated `totalCalls` here
      options: {
        chart: {
          type: "radialBar", // Correct chart type for gauge-like visuals
          height: 380,
        },
        stroke: {
          curve: "smooth",
          width: 2,
        },

        tooltip: {
          shared: false,
          intersect: true,
        },
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: true, // Enable data labels
        },
        labels: [type],
        plotOptions: {
          radialBar: {
            max:
              type === "Calls"
                ? maxCalls
                : type === "Sessions"
                ? maxSessions
                : maxBookings, // Set the max value for the radialBar gauge
            dataLabels: {
              name: {
                show: true,
              },
              value: {
                show: false,
              },
            },
            track: {
              background: "#f2f2f2",
            },
            startAngle: -90,
            endAngle: 90,
          },
        },
        colors: [generateUniqueColor()], // Optionally use dynamic colors
      },
    };
  };

  if (!chartCallData || !chartSessionData || !chartBookingData || isLoading) {
    return <div>Loading...</div>;
  }
  console.log(chartCallData, "chartCallData?.series[0]");
  return (
    <>
      <div
        className="flex space-x-4 mb-6"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          gap: "0.2rem",
          borderRadius: "20px",
          margin: "1rem 0",
          maxWidth: "100%",
          postion: "relative",
        }}
      >
        <div
          style={{
            display: "flex",
            position: "absolute",
            left: "20px",
            top: "30px",
            alignItems: "center",
            justifyContent: "flex-start",
            height: "4vh",
            gap: "1rem",
            borderRadius: "10px",
            margin: "1rem 0",
            maxWidth: "100%",
            overflow: "auto",
          }}
        >
          {currentPath !== "/sales/dashboard" && (
            <Box
              sx={{
                width: "40vh",
                fontSize: "10px",
                // height:'5vh',
                // overflow: 'auto',
                // color:'black'
              }}
            >
              <Autocomplete
                multiple
                options={employees?.data?.message[0]?.map((employee) => ({
                  label: `${employee.name} (${employee?.position})`,
                  _id: employee._id,
                }))}
                getOptionLabel={(option) => option.label || ""}
                value={members}
                sx={{
                  color: "black",
                  height: "5vh",
                  overflow: "auto",
                  fontSize: "10px",
                  "& .MuiAutocomplete-input": {
                    fontSize: "10px", // Ensuring the text size of options is 10px
                  },
                }}
                onChange={(_, newValue) => {
                  setMembers(newValue);
                  setSelectedMembers(newValue?.map((item) => item._id));
                }}
                isOptionEqualToValue={(option, value) =>
                  option._id === value._id
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Employees"
                    variant="outlined"
                    sx={{
                      "& .MuiInputLabel-root": {
                        color: "black",
                        fontSize: "10px",
                      },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "#ced1d6",
                          borderRadius: "15px",
                          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                          border: "none",
                          color: "black",
                          fontSize: "10px",
                        },
                        "&:hover fieldset": {
                          borderColor: "#398bf7",
                          color: "black",
                          fontSize: "10px",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#398bf7",
                          color: "black",
                          fontSize: "10px",
                        },
                      },
                    }}
                  />
                )}
              />
            </Box>
          )}
          {["daily", "monthly", "yearly"].map((viewType) => (
            <button
              key={viewType}
              onClick={() => setView(viewType)}
              className={`px-4 py-2 border rounded-lg transition duration-300 ease-in-out transform ${
                view === viewType
                  ? "bg-blue-500 text-white shadow-lg"
                  : "bg-white text-gray-800"
              } hover:bg-blue-200 hover:scale-105 focus:outline-none`}
              style={{
                backgroundColor:
                  view === viewType
                    ? theme.palette.primary.main
                    : "transparent",
                color: view === viewType ? "white" : "black",
                border: "2px solid",
                borderColor:
                  view === viewType ? theme.palette.primary.main : "#dcdcdc",
                borderRadius: "8px",
                cursor: "pointer",
                padding: "5px 6px",
                boxShadow:
                  view === viewType ? "0 4px 12px rgba(0, 0, 0, 0.1)" : "none",
                fontWeight: "500",
                fontSize: "10px",
              }}
            >
              {viewType.charAt(0).toUpperCase() + viewType.slice(1)}
            </button>
          ))}
          {view === "yearly" && (
            <div className="flex space-x-4 mb-6">
              <div>
                <select
                  id="year-select"
                  value={selectedYear}
                  onChange={(e) => setSelectedYear(Number(e.target.value))}
                  className="px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300 ease-in-out"
                  style={{
                    borderColor: "#dcdcdc",
                    borderRadius: "8px",
                    padding: "5px 6px",
                    fontSize: "10px",
                    color: "#333",
                    fontWeight: "500",
                    boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
                    transition:
                      "border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
                  }}
                >
                  {Array.from({ length: 10 }, (_, index) => (
                    <option key={index} value={2020 + index}>
                      {2020 + index}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          )}
          {/* Monthly View - Month and Year Select */}
          {view === "monthly" && (
            <div
              className="flex space-x-4 mb-6"
              style={{
                display: "flex",
                alignItems: "center",
                gap: "1rem",
              }}
            >
              <div>
                <select
                  id="month-select"
                  value={selectedMonth}
                  onChange={(e) => setSelectedMonth(Number(e.target.value))}
                  className="px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300 ease-in-out"
                  style={{
                    borderColor: "#dcdcdc",
                    borderRadius: "8px",
                    padding: "5px 6px",
                    fontSize: "10px",
                    color: "#333",
                    fontWeight: "500",
                    boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  {Array.from({ length: 12 }, (_, i) => (
                    <option key={i} value={i}>
                      {format(new Date(2020, i), "MMMM")}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <select
                  id="year-select"
                  value={selectedYear}
                  onChange={(e) => setSelectedYear(Number(e.target.value))}
                  className="px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300 ease-in-out"
                  style={{
                    borderColor: "#dcdcdc",
                    borderRadius: "8px",
                    padding: "5px 6px",
                    fontSize: "10px",
                    color: "#333",
                    fontWeight: "500",
                    boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  {Array.from({ length: 10 }, (_, i) => 2020 + i).map(
                    (year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    )
                  )}
                </select>
              </div>
            </div>
          )}
          {view === "daily" && (
            <div className="mb-6">
              <input
                type="date"
                id="date-select"
                value={format(selectedDate, "yyyy-MM-dd")}
                onChange={(e) => setSelectedDate(new Date(e.target.value))}
                style={{
                  borderColor: "#dcdcdc",
                  borderRadius: "8px",
                  padding: "5px 6px",
                  fontSize: "8px",
                  color: "#333",
                  fontWeight: "500",
                  boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
                  transition:
                    "border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
                }}
              />
            </div>
          )}
        </div>

        {/* Render Chart */}
      </div>
      <div className="chart-container" style={{ display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap' , padding: '10px'}}>
  <Grid style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' , position: 'relative', boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)', borderRadius: '20px'}}>
    <div >
    <GaugeContainer
  width={220}
  height={220}
  startAngle={-110}
  endAngle={110}
    cornerRadius="50%"
  valueMax={100}
  sx={{
    position: 'relative', // Ensure all elements are properly stacked
    '& .css-1q59952-MuiGauge-referenceArc': {
      fill: '#ff4c61', // Filled color for the gauge
      strokeLinecap: 'round',  // Round the ends of the arc
      strokeLinejoin: 'round', // Round the joints between arc segments
    },
    '& .css-1yxpyi-MuiGauge-referenceArc': {
      fill: '#ff4c61', // Filled color for the gauge
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    },
    '& .css-remaining-MuiGauge-referenceArc': {
      fill: '#FFD0D5', // Lighter shade for the unfilled part of the gauge
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    },
  }}
  value={(chartCallData?.series[0] / 2000) * 100 || 0} // Adjust the value calculation
>
  <GaugeReferenceArc />
  {/* Add another reference arc for the remaining unfilled path */}
  <GaugeReferenceArc className="css-remaining-MuiGauge-referenceArc" />
  <GaugeValueArc />
  {/* <GaugePointer pointer="#ff4c61" /> */}
</GaugeContainer>

    </div>
       <Grid sx={{position: 'absolute', top: '60%', left: '50%', transform: 'translate(-50%, -50%)'}}>
    <Typography sx={{
        padding: "10px 15px", // Padding for a more clickable "Cancel" button
        backgroundColor: "#ff4c61", // Red background color
        borderRadius: 3, // Rounded cancel button
        fontWeight: "bold", // Bold text for clarity
        fontSize: "0.7rem", // Slightly larger text for readability
        transition: "background-color 0.3s ease", // Smooth color transition
        color: "#fff", // White text color
      }}>{`Call (${Math.round((chartCallData?.series[0] / 2000) * 100) || 0}%)`}
</Typography>
       </Grid>
  </Grid>

  <Grid style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' , position: 'relative' ,boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)', borderRadius: '20px'}}>
    <div >
    <GaugeContainer
  width={220}
  height={220}
  startAngle={-110}
  endAngle={110}
  valueMax={100}
    cornerRadius="50%"
  sx={{
    position: 'relative', // Make sure all components are properly stacked
    '& .css-1q59952-MuiGauge-referenceArc': {
      fill: '#ff8800',
      strokeLinecap: 'round',  // Rounds the ends of the arc
      strokeLinejoin: 'round', // Rounds the joints between path segments
    },
    '& .css-1yxpyi-MuiGauge-referenceArc': {
      fill: '#ff8800',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    },
    '& .css-remaining-MuiGauge-referenceArc': {
      fill: '#FFE2C1', // Lighter shade of #ff8800 for the unfilled part
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    },
  }}
  value={(chartBookingData?.series[0] / 100) * 100 || 0}
>
  <GaugeReferenceArc />
  {/* Add another reference arc for the remaining unfilled path */}
  <GaugeReferenceArc className="css-remaining-MuiGauge-referenceArc" />
  <GaugeValueArc />
  {/* <GaugePointer pointer="#ff8800" /> */}
</GaugeContainer>

    </div>
       <Grid sx={{position: 'absolute', top: '60%', left: '50%', transform: 'translate(-50%, -50%)'}}>
    <Typography sx={{
        padding: "10px 10px", // Padding for a more clickable "Cancel" button
        backgroundColor: "#ff8800", // Red background color
        borderRadius: 3, // Rounded cancel button
        fontWeight: "bold", // Bold text for clarity
        fontSize: "0.7rem", // Slightly larger text for readability
        transition: "background-color 0.3s ease", // Smooth color transition
        color: "#fff", // White text color
      }}>{` Bookings (${Math.round((chartBookingData?.series[0] / 100) * 100) || 0}%)`}
</Typography>
       </Grid>
  </Grid>

  <Grid style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' , position: 'relative',boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)', borderRadius: '20px'}}>
    <div >
    <GaugeContainer
  width={220}
  height={220}
  startAngle={-110}
  endAngle={110}
  valueMax={100}
  cornerRadius="50%"
  sx={{
    '& .css-1q59952-MuiGauge-referenceArc': {
      fill: '#8950fc',
      strokeLinecap: 'round',  // Rounds the ends of the arc
      strokeLinejoin: 'round', // Rounds the joints between path segments
    },
    '& .css-1yxpyi-MuiGauge-referenceArc': {
      fill: '#8950fc',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    },
    '& .css-remaining-MuiGauge-referenceArc': {
      fill: '#DAC8FF', // Lighter color for the unfilled part
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    },
  }}
  value={(chartSessionData?.series[0] / 20) * 100 || 0}
>
  <GaugeReferenceArc />
  {/* Add another reference arc for the remaining path */}
  <GaugeReferenceArc className="css-remaining-MuiGauge-referenceArc" />
  <GaugeValueArc />
  {/* <GaugePointer pointer={'#8950fc'} /> */}
</GaugeContainer>

    </div>
       <Grid sx={{position: 'absolute', top: '60%', left: '50%', transform: 'translate(-50%, -50%)'}}>
    <Typography sx={{
        padding: "10px 15px", // Padding for a more clickable "Cancel" button
        backgroundColor: "#8950fc", // Red background color
        borderRadius: 3, // Rounded cancel button
        fontWeight: "bold", // Bold text for clarity
        fontSize: "0.7rem", // Slightly larger text for readability
        transition: "background-color 0.3s ease", // Smooth color transition
        color: "#fff", // White text color
      }}>{` Sessions (${Math.round((chartSessionData?.series[0] / 20) * 100) || 0}%)`}
</Typography>
       </Grid>
  </Grid>
</div>

    </>
  );
};
function GaugePointer({pointer}) {
  const { valueAngle, outerRadius, cx, cy } = useGaugeState();

  if (valueAngle === null) {
    // No value to display
    return null;
  }

  const target = {
    x: cx + outerRadius * Math.sin(valueAngle),
    y: cy - outerRadius * Math.cos(valueAngle),
  };
  return (
    <g>
      <circle cx={cx} cy={cy} r={5} fill="red" />
      <path
        d={`M ${cx} ${cy} L ${target.x} ${target.y}`}
        stroke={pointer}
        strokeWidth={3}
      />
    </g>
  );
}
export default TotalCallGague;

  