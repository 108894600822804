import React, { useState, useEffect } from 'react';
import ApexChartWidget from './ApexChartWidget';
import { useGet, usePost } from '../../helpers/axios/useApi';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../../middlewares/auth';
import { format, getDaysInMonth } from 'date-fns';
import { useTheme } from '@emotion/react';
import { Box } from '@mui/system';
import { Autocomplete, TextField } from '@mui/material';

// Function to generate a random hex color
const colorArray = [
  '#FF5733', '#33FF57', '#3357FF', '#F1C40F', '#9B59B6', '#FF6347', '#8E44AD', '#1F618D', 
  '#F39C12', '#2ECC71', '#E74C3C', '#3498DB', '#2C3E50', '#1ABC9C', '#D35400', '#9B59B6', 
  '#34495E', '#7F8C8D', '#D5DBDB', '#A93226', '#5D6D7E', '#7FB3D5', '#85929E', '#F4D03F', 
  '#E67E22', '#16A085', '#2980B9', '#8E44AD', '#9C27B0', '#00BCD4', '#8E44AD', '#D32F2F', 
  '#1976D2', '#388E3C', '#0288D1', '#C2185B', '#7B1FA2', '#E91E63', '#00B8D4', '#43A047', 
  '#F44336', '#9E9D24', '#00C853', '#9E1B32', '#3F51B5', '#FF8F00', '#00897B', '#7B2CBF',
  // Adding more colors
  '#FF00FF', '#00FFFF', '#FFD700', '#7CFC00', '#DC143C', '#00FF00', '#ADFF2F', '#FFD700',
  '#800080', '#A52A2A', '#808000', '#FF1493', '#00BFFF', '#8A2BE2', '#B0C4DE', '#FF8C00',
  '#FF4500', '#20B2AA', '#008080', '#D2691E', '#ADFF2F', '#FF1493', '#F08080', '#B22222'
];

let usedColors = [];

// Function to generate a unique color from the predefined array
const generateUniqueColor = () => {
  if (usedColors.length === colorArray.length) {
    // If all colors are used up, reset the usedColors array to start over
    usedColors = [];
  }

  let randomIndex;
  do {
    randomIndex = Math.floor(Math.random() * colorArray.length); // Get a random index
  } while (usedColors.includes(randomIndex)); // Ensure it's not a used color
  
  usedColors.push(randomIndex); // Mark this color as used
  
  return colorArray[randomIndex]; // Return the color from the array
};

const TotalBookingsAndSessions = ({isTeam,widget}) => {
  const [view, setView] = useState('monthly'); // 'daily', 'monthly', or 'yearly'
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth()); // Default to current month
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear()); // Default to current year
  const [selectedDate, setSelectedDate] = useState(new Date()); // Default to today's date
  const [chartData, setChartData] = useState({ series: [], options: {} });
  const [loading, setLoading] = useState(true); // Loading state
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [members, setMembers] = useState([]);
  const theme = useTheme();
  const getTotalBookings = usePost('/leads/dashboard/get-bookings');
  const getTotalSessions = usePost('/leads/dashboard/get-sessions');
  const {
    data: employees,
    refetch: refetchEmployees,
    isLoading: employeesLoading,
  } = useGet('employee/all', {}, {}, { queryKey: 'employees' });

  const { user } = useAuth();
  const location = useLocation();
  const currentPath = location.pathname;

  // Fetch the call data based on view and date selection
  useEffect(() => {
    if(widget){
      fetchCallsData(view);
     }
  }, [view, selectedMonth, selectedYear, selectedDate, employees, selectedMembers,isTeam]); // Refetch when dependencies change

  const handleEmptyData = () => {
    setChartData({
      series: [
        { name: 'Calls', data: [] },
      ],
      options: {}
    });
    setLoading(false); // End loading
  };
 
  const fetchCallsData = async (view) => {
    console.log('Fetching calls data...');
    setLoading(true); // Start loading
    const id = selectedMembers?.length > 0 ? selectedMembers :
    !isTeam
      ? [user?._id] // If in sales dashboard, use the logged-in user ID
      : employees?.data?.message[0]?.map((employee) => employee._id); // Use employee IDs if available
      const details = { employeeIds: id };

    let query;
    // Handle different view types (daily, monthly, yearly)
    if (view === 'daily') {
      query = { date: format(selectedDate, 'yyyy-MM-dd') }; // Send date as 'yyyy-MM-dd'
    } else if (view === 'monthly') {
      query = { month: selectedMonth + 1, year: selectedYear }; // Send month (1-12) and year (yyyy)
    } else if (view === 'yearly') {
      query = { year: selectedYear }; // Send only the year (yyyy)
    }
  
    try {
      const [bookingsResponse, sessionsResponse] = await Promise.all([
        getTotalBookings.mutateAsync({ ...query, ...details }),
        getTotalSessions.mutateAsync({ ...query, ...details }),
      ]);
      
      if (!bookingsResponse?.data?.data || !sessionsResponse?.data?.data) {
        handleEmptyData();
      } else {
        
        const newData = processData(bookingsResponse?.data?.data, sessionsResponse?.data?.data);
        newData ? setChartData(newData) : handleEmptyData();
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      handleEmptyData();
    }
  };

  const getEmployeeName = (id) => {
    if (!employees?.data?.message || !Array.isArray(employees?.data?.message[0])) {
      return "Unknown";
    }

    let employee = employees?.data?.message[0]?.find((employee) => employee._id === id);
    return employee ? `${employee.name}` : "Unknown";
  };

  // Process the response data to extract categories, call counts, and employee names
  const processData = (bookingsData, sessionsData) => {
    let categories = [];
    let series = [];
    let colors = [];

    if (view === 'daily') {
      categories = Array.from({ length: 24 }, (_, hour) => `${hour}`);
      sessionsData.forEach((employeeData) => {
        const sessionCounts = categories.map(hour => (employeeData?.hourlyCount?.[hour] || 0)); // Fallback to 0
        series.push({
          name: `${getEmployeeName(employeeData?.employeeId)} - Sessions` || 'Unknown Employee',
          data: sessionCounts,
        });
        colors.push(generateUniqueColor());
        console.log(series,'series')
      });
      bookingsData.forEach((employeeData) => {
        const bookingsCounts = categories.map(hour => (employeeData?.hourlyCount?.[hour] || 0));
        series.push({
          name: `${getEmployeeName(employeeData?.employeeId)} - Bookings` || 'Unknown Employee',
          data: bookingsCounts,
        });
        colors.push(generateUniqueColor());
      });
    } else if (view === 'monthly') {
      const daysInMonth = getDaysInMonth(new Date(selectedYear, selectedMonth));
      categories = Array.from({ length: daysInMonth }, (_, day) => day + 1);
      sessionsData.forEach((employeeData) => {
        const sessionCounts = categories.map(day => (employeeData?.dailyCount?.[day] || 0));
        series.push({
          name: `${getEmployeeName(employeeData?.employeeId)} - Sessions` || 'Unknown Employee',
          data: sessionCounts,
        });
        colors.push(generateUniqueColor());
      });
      bookingsData.forEach((employeeData) => {
        const bookingsCounts = categories.map(day => (employeeData?.dailyCount?.[day] || 0));
        series.push({
          name: `${getEmployeeName(employeeData?.employeeId)} - Bookings` || 'Unknown Employee',
          data: bookingsCounts,
        });
        colors.push(generateUniqueColor());
      });
    } else if (view === 'yearly') {
      categories = Array.from({ length: 12 }, (_, month) => `${month + 1}`);
      sessionsData.forEach((employeeData) => {
        const sessionCounts = categories.map(year => (employeeData?.monthlyCount?.[year] || 0));
        series.push({
          name: `${getEmployeeName(employeeData?.employeeId)} - Sessions` || 'Unknown Employee',
          data: sessionCounts,
        });
        colors.push(generateUniqueColor());
      });
      bookingsData.forEach((employeeData) => {
        const bookingsCounts = categories.map(year => (employeeData?.monthlyCount?.[year] || 0));
        series.push({
          name: `${getEmployeeName(employeeData?.employeeId)} - Bookings` || 'Unknown Employee',
          data: bookingsCounts,
        });
        colors.push(generateUniqueColor());
      });
    }

    return {
      series: series || [],
      options: {
        chart: {
          type: 'area',
          height: 350,
          zoom: {
            enabled: true,
            type: 'xy',
          },
        },
        stroke: {
          curve: 'smooth',
          width: 2,
        },
        xaxis: {
          categories: categories || [],
          title: {
            text: view.charAt(0).toUpperCase() + view.slice(1),
          },
        },
        title: {
          text: `Calls and Sessions Over Time (${view.charAt(0).toUpperCase() + view.slice(1)})`,
          align: 'left',
        },
        dataLabels: {
          enabled: false, // Disable data labels (numbers on the curves)
        },
        tooltip: {
          shared: false,
          intersect: true,
        },
        legend: {
          show: false,
          position: 'top',
          horizontalAlign: 'center',
        },
        colors: colors, // Set the random colors for each employee
      },
    };
  };



  return (
    <div className="container mx-auto p-4">
    {/* View Selection */}
    <div className="flex space-x-4 mb-6" style={{
        display: 'flex',
        alignItems: 'center',
        gap: '1rem',
        borderRadius: '20px',
        backgroundColor:'white',
        margin: '1rem 0',
        maxWidth:'100%',
        overflow:'auto',
        color:'black'
      }}>
            
              <Box sx={{
                width: '40vh',
                fontSize:'10px',
                // height:'5vh',
                // overflow: 'auto',
                color:'black'
              }}>
        
              <Autocomplete
                      multiple
                      options={employees?.data?.message[0]?.map((employee) => ({
                        label: `${employee.name} (${employee?.position})`,
                        _id: employee._id,
                      }))}
                       
                      getOptionLabel={(option) => option.label || ""}
                      value={members}
                      sx={{
                        color: "black",
                        height: "5vh",
                        overflow: "auto",
                        fontSize:'10px',
                        minWidth: '24vh',
                        '& .MuiAutocomplete-input': {
                          fontSize: '10px', // Ensuring the text size of options is 10px
                        },
                      }}
                      onChange={(_, newValue) => {
                        setMembers(newValue);
                        setSelectedMembers(newValue?.map((item) => item._id));
                      }}
                      isOptionEqualToValue={(option, value) => option._id === value._id}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Employees"
                          variant="outlined"
                          sx={{
                            "& .MuiInputLabel-root": {
                              color: "black",
                              fontSize:'10px'
                            },
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                borderColor: "#ced1d6",
                                borderRadius: "15px",
                                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                                border: "none",
                                color: "black",
                                 fontSize:'10px'
                              },
                              "&:hover fieldset": {
                                borderColor: "#398bf7",
                                color: "black",
                                 fontSize:'10px'
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "#398bf7",
                                color: "black",
                                 fontSize:'10px'
                              },
                              "& .css-1dybbl5-MuiChip-label":{
                                color:'black'
                              },
                              "& .css-89mqll-MuiButtonBase-root-MuiChip-root .MuiChip-deleteIcon":{
                                   color:'black'
                              }
                            },
                          }}
                        />
                      )}
                    />
              </Box>

        {['daily', 'monthly', 'yearly'].map((viewType) => (
          <button
            key={viewType}
            onClick={() => setView(viewType)}
            className={`px-4 py-2 border rounded-lg transition duration-300 ease-in-out transform ${
              view === viewType ? 'bg-blue-500 text-white shadow-lg' : 'bg-white text-gray-800'
            } hover:bg-blue-200 hover:scale-105 focus:outline-none`}
            style={{
              backgroundColor: view === viewType ? theme.palette.primary.main : 'transparent',
              color: view === viewType ? 'white' : 'black',
              border: '2px solid',
              borderColor: view === viewType ? theme.palette.primary.main : '#dcdcdc',
              borderRadius: '8px',
              cursor: 'pointer',
              padding: '5px 6px',
              boxShadow: view === viewType ? '0 4px 12px rgba(0, 0, 0, 0.1)' : 'none',
              fontWeight: '500',
              fontSize: '10px',
            }}
          >
            {viewType.charAt(0).toUpperCase() + viewType.slice(1)} 
          </button>
        ))}
          {(view === 'yearly') && (
    <div className="flex space-x-4 mb-6">
      <div>
        <select
          id="year-select"
          value={selectedYear}
          onChange={(e) => setSelectedYear(Number(e.target.value))}
          className="px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300 ease-in-out"
          style={{
            borderColor: '#dcdcdc',
            borderRadius: '8px',
            padding: '5px 6px',
            fontSize: '10px',
            color: '#333',
            fontWeight: '500',
            boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
            transition: 'border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
          }}
        >
          {Array.from({ length: 10 }, (_, index) => (
            <option key={index} value={2020 + index}>
              {2020 + index}
            </option>
          ))}
        </select>
      </div>
    </div>
  )}
        {/* Monthly View - Month and Year Select */}
        {(view === 'monthly') && (
          <div className="flex space-x-4 mb-6" style={{
            display: 'flex',
            alignItems: 'center',
            gap: '1rem',
          }}>
            <div>
              <select
                id="month-select"
                value={selectedMonth}
                onChange={(e) => setSelectedMonth(Number(e.target.value))}
                className="px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300 ease-in-out"
                style={{
                  borderColor: '#dcdcdc',
                  borderRadius: '8px',
                  padding: '5px 6px',
                  fontSize: '10px',
                  color: '#333',
                  fontWeight: '500',
                  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                }}
              >
                {Array.from({ length: 12 }, (_, i) => (
                  <option key={i} value={i}>
                    {format(new Date(2020, i), 'MMMM')}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <select
                id="year-select"
                value={selectedYear}
                onChange={(e) => setSelectedYear(Number(e.target.value))}
                className="px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300 ease-in-out"
                style={{
                  borderColor: '#dcdcdc',
                  borderRadius: '8px',
                  padding: '5px 6px',
                  fontSize: '10px',
                  color: '#333',
                  fontWeight: '500',
                  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                }}
              >
                {Array.from({ length: 10 }, (_, i) => 2020 + i).map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </select>
            </div>
          </div>
        )}
           {view === 'daily' && (
    <div className="mb-6">
      
        
  
      <input
        type="date"
        id="date-select"
        value={format(selectedDate, 'yyyy-MM-dd')}
        onChange={(e) => setSelectedDate(new Date(e.target.value))}
        style={{
          borderColor: '#dcdcdc',
          borderRadius: '8px',
          padding: '5px 6px',
          fontSize: '8px',
          color: '#333',
          fontWeight: '500',
          boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
          transition: 'border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
        }}
      />
    </div>
  )}
      </div>

      {/* Render Chart */}
      <div className="chart-container">
        <ApexChartWidget data={chartData} chartType="area" height={290} />
      </div>
    </div>
  );
};

export default TotalBookingsAndSessions;
