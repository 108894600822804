import React from 'react';
import "./tearm.css";
function Terms() {
  return (
    <div className="terms-container">
      <header className="terms-header">
        <h1>Terms and Conditions</h1>
        <p className="updated-date">Last Updated: October 12, 2024</p>
      </header>

      <section className="intro">
        <p>
          Welcome to <strong>Multiverse</strong> – a software solution offered by FUELITONLINE. The Multiverse platform provides tools for Customer Relationship Management (CRM), Human Resource Management (HRM), Lead Management System (LMS), and other business-related services (collectively, "Services"). By using the platform, you agree to the terms outlined below. If you do not agree to these terms, please refrain from using the Services.
        </p>
      </section>

      <section>
        <h2>1. Introduction</h2>
        <p>
          These Terms and Conditions ("Agreement") govern your use of the Multiverse platform (the "Service"). Please read carefully. By accessing or using the Service, you acknowledge that you have read, understood, and agree to these terms.
        </p>
      </section>

      <section>
        <h2>2. Definitions</h2>
        <ul>
          <li><strong>Service:</strong> The software application "Multiverse" offered by FUELITONLINE.</li>
          <li><strong>User:</strong> Any individual or entity using the Service.</li>
          <li><strong>Account:</strong> The user profile you create to access the Service.</li>
          <li><strong>Company:</strong> FUELITONLINE, the provider of the Service.</li>
        </ul>
      </section>

      <section>
        <h2>3. Eligibility</h2>
        <p>
          To use the Service, you must be at least 18 years old and capable of entering into a legally binding contract.
        </p>
      </section>

      <section>
        <h2>4. Account Registration and Security</h2>
        <p>To use the Service, you must create an account with accurate and complete information. You are responsible for the security of your account.</p>
        <ul>
          <li>Keep your account credentials confidential.</li>
          <li>Notify us of any unauthorized use of your account.</li>
          <li>Follow all applicable laws when using the Service.</li>
        </ul>
      </section>

      <section>
        <h2>5. License to Use the Service</h2>
        <p>
          FUELITONLINE grants you a non-exclusive, non-transferable, revocable license to use the Service. This license is for personal or internal business use only and does not allow:
        </p>
        <ul>
          <li>Modification or creation of derivative works of the Service.</li>
          <li>Reverse engineering or decompiling the Service.</li>
          <li>Reselling or sublicensing the Service.</li>
        </ul>
      </section>

      <section>
        <h2>6. Subscription and Fees</h2>
        <p>
          The Service is subscription-based, and fees vary depending on the plan selected.
        </p>
        <ul>
          <li><strong>Billing:</strong> Payment is due per the selected billing cycle (e.g., monthly, annually).</li>
          <li><strong>Refunds:</strong> Payments are non-refundable unless required by law.</li>
          <li><strong>Price Changes:</strong> We reserve the right to change pricing at any time, with notice provided.</li>
        </ul>
      </section>

      <section>
        <h2>7. Data Privacy and Protection</h2>
        <p>
          By using the Service, you consent to the collection and processing of your data according to our <strong>Privacy Policy</strong>. You retain ownership of your data, but grant FUELITONLINE a license to store and process it for Service-related purposes.
        </p>
      </section>

      <section>
        <h2>8. User Conduct</h2>
        <p>
          You agree to use the Service lawfully and responsibly. Prohibited activities include:
        </p>
        <ul>
          <li>Violating intellectual property rights.</li>
          <li>Harassing or defaming others.</li>
          <li>Introducing harmful software or viruses.</li>
          <li>Violating privacy laws.</li>
        </ul>
      </section>

      <section>
        <h2>9. Support and Maintenance</h2>
        <p>Technical support is available based on your subscription plan. We also reserve the right to perform maintenance or update the Service periodically.</p>
      </section>

      <section>
        <h2>10. Termination</h2>
        <p>
          You may cancel your subscription at any time, but you will lose access at the end of the current billing cycle. FUELITONLINE may suspend or terminate your access if you breach these terms.
        </p>
      </section>

      <section>
        <h2>11. Intellectual Property</h2>
        <p>
          All intellectual property related to the Service is owned by FUELITONLINE or its licensors. You may not use it without permission.
        </p>
      </section>

      <section>
        <h2>12. Limitation of Liability</h2>
        <p>
          FUELITONLINE will not be liable for any indirect, incidental, or consequential damages arising from the use of the Service. Our total liability is limited to the amount paid for the Service in the last 12 months.
        </p>
      </section>

      <section>
        <h2>13. Indemnification</h2>
        <p>
          You agree to indemnify FUELITONLINE from any claims, damages, or legal fees arising from your use of the Service or violations of these terms.
        </p>
      </section>

      <section>
        <h2>14. Changes to the Terms</h2>
        <p>We may modify these Terms at any time. Changes will be effective immediately, and continued use of the Service constitutes acceptance of the new terms.</p>
      </section>

      <section>
        <h2>15. Governing Law</h2>
        <p>This Agreement will be governed by the laws of [Jurisdiction], and disputes will be resolved through binding arbitration in [Location].</p>
      </section>

      <section>
        <h2>16. Force Majeure</h2>
        <p>Neither party will be liable for delays or failures in performance due to causes beyond their control, such as natural disasters, war, or technology failures.</p>
      </section>

      <section>
        <h2>17. Severability</h2>
        <p>If any provision is found invalid, the remaining provisions will still apply.</p>
      </section>

      <section>
        <h2>18. Entire Agreement</h2>
        <p>This Agreement, along with our Privacy Policy, constitutes the entire understanding between you and FUELITONLINE regarding your use of the Service.</p>
      </section>

      <footer>
        <p>If you have any questions or concerns, please contact us at:</p>
        <p><strong>FUELITONLINE</strong></p>
      </footer>
    </div>
  );
}

export default Terms;
