import React, { useState, useEffect, useRef } from 'react';
import {
  List,
  ListItem,
  ListItemText,
  IconButton,
  useMediaQuery,
  useTheme,
  Grid,
  Button,
  Typography,
  Snackbar,
  Alert,
  CircularProgress,
} from '@mui/material';
import {
  LogoutTwoTone,
  Dashboard,
  Leaderboard,
  CalendarMonth,
  Groups2,
  MessageRounded,
  FolderCopyRounded,
  ReceiptRounded,
  
  ChatBubble,
  Login,
  WifiPassword,
} from '@mui/icons-material';
import { styled } from '@mui/system';
import GlassEffect from '../theme/glassEffect';
import supportIMG from '../assets/images/support.png';
import logoIMG from '../assets/images/logo.png';
import darkLogo from '../assets/images/darkLogo.png';
import { useAuth } from '../middlewares/auth';
import { Link, useLocation } from 'react-router-dom';
import Diversity2Icon from '@mui/icons-material/Diversity2';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { usePost } from '../helpers/axios/useApi';

const Navbar = () => {
  const theme = useTheme();
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [expanded, setExpanded] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { user, logout } = useAuth();
  const location = useLocation();
  const navbarRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
 
  const pathToIndex = {
    '/sales/dashboard': 0,
    '/leads': 1,
    '/customers': 2,
    '/calendar': 3,
    '/employees': 4,
    '/messages': 5,
    '/marketing': 6,
    '/invoices': 7,
  };

  // Define route data in an array
  const routes = [
    { path: '/sales/dashboard', name: 'Dashboard', icon: <Dashboard /> },
    { path: '/leads', name: 'Leads', icon: <Leaderboard /> },
    { path: '/customers', name: 'Customers', icon: <Diversity2Icon /> },
    { path: '/calendar', name: 'Calendar', icon: <CalendarMonth /> },
    { path: '/employees', name: 'Employees', icon: <Groups2 /> },
    { path: '/messages', name: 'Messages', icon: <MessageRounded /> },
    { path: '/marketing', name: 'Marketing', icon: <FolderCopyRounded /> },
    { path: '/invoices', name: 'Invoices', icon: <ReceiptRounded /> },
  ];

  // Handle outside click to close menu
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        setExpanded(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [navbarRef]);

  const currentPath = location.pathname;
  // Set selected index based on location
  useEffect(() => {
    let index = null;
    Object.keys(pathToIndex).forEach((path) => {
      if (currentPath === path || currentPath.startsWith(`${path}/`)) {
        index = pathToIndex[path];
      }
    });
    setSelectedIndex(index ?? 0);
  }, [location.pathname]);

  // Handle menu item click
  const handleListItemClick = (index) => {
    setSelectedIndex(index);
    setExpanded(false);
  };

  const createIp = usePost('/company/policy/ip-address-create');
  const handleToggle = () => setExpanded(!expanded);

  const getPublicIp = async () => {
    try {
      const response = await fetch('https://api.ipify.org?format=json');
      const data = await response.json();
      return data.ip;
    } catch (error) {
      console.error('Error retrieving public IP:', error);
      return null;
    }
  };

  const createIpProtocal = async () => {
    setLoading(true);
    setSuccess(false);
    setError(false);

    try {
      const publicIp = await getPublicIp();
      console.log(publicIp);

      if (!publicIp) {
        throw new Error('Failed to retrieve public IP address');
      }

      const apiResponse = await createIp.mutateAsync({ routerIp: publicIp });

      if (apiResponse?.data !== null) {
        setSuccess(true);
        setSnackbarMessage('IP Protocol created successfully!');
        setSnackbarSeverity('success');
      } else {
        setSnackbarMessage(apiResponse?.error?.error || 'Failed to create IP Protocol');
        setSnackbarSeverity('error');
      }
    } catch (err) {
      setError(true);
    } finally {
      setLoading(false);
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const NavbarContainer = styled('div')(({ theme, expanded }) => ({
    width: expanded ? '250px' : '80px',
    transition: 'width 0.3s ease',
    backgroundColor: 'transparent',
    borderRight: '1px solid rgba(255, 255, 255, 0.2)',
    height: '100vh',
    position: 'sticky',
    top: 0,
    display: 'flex',
    flexDirection: 'column',
  }));
  
  const ListItemStyled = styled(ListItem)(({ theme, active, disabled }) => ({
    borderRadius: theme.shape.borderRadius,
    position: 'relative',
    pointerEvents: disabled ? 'none' : 'auto',
    opacity: disabled ? 0.5 : 1,
  }));
  
  const IconContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    '& svg': {
      fontSize: '1.5rem',
    },
  }));

  const drawer = (
    <NavbarContainer ref={navbarRef} expanded={expanded}>
      <GlassEffect.GlassContainer sx={{ height: '100%', position: 'sticky', top: 0 }}>
        <List sx={{ height: '100%' }}>
          <Grid
            height={'10%'}
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-around',
              flexWrap: 'wrap',
            }}
          >
            <img src={theme.palette.mode === 'dark' ? 'https://mutliverse-app-version.s3.ap-south-1.amazonaws.com/Multiverse/darkLogo.png' : 'https://mutliverse-app-version.s3.ap-south-1.amazonaws.com/Multiverse/logo.png'} alt="Logo" height={'70px'} width={'70px'} style={{ marginLeft: '10px' }} onClick={handleToggle}/>
            {expanded && (
              <Typography sx={{ fontSize: '20px', fontWeight: 'bold', color: '#155ed4' }}>
                {user?.loginId}
              </Typography>
            )}
          </Grid>
          <Grid minHeight={'60%'}>
            
         {routes.map((route, index) => {
          return (
            <Link to={route.path} key={route.path} style={{ textDecoration: 'none' }}>
              <ListItemStyled
                button
                selected={selectedIndex === index}
                onClick={() => handleListItemClick(index)}
                active={selectedIndex === index ? 'true' : undefined}
                
              >
                <IconContainer>
                  {/* Conditionally set icon color */}
                  {React.cloneElement(route.icon, {
                    sx: {
                      color: selectedIndex === index ? theme.palette.primary.main : 'grey',
                    }
                  })}
                </IconContainer>
                {expanded && (
                  <ListItemText
                    primary={route.name}
                    sx={{
                      color: selectedIndex === index ? theme.palette.primary.main : 'grey',
                      textAlign: 'start',
                    }}
                  />
                )}
              </ListItemStyled>
            </Link>
          );
        })}
          </Grid>

          <Grid
            height={'10%'}
            width={'100%'}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              marginTop: '20px',
            }}
          >
            <img src={'https://mutliverse-app-version.s3.ap-south-1.amazonaws.com/Multiverse/support.png'} alt="Support" height={'100px'} width={'80px'} style={{ marginBottom: '10px' }} />
            {expanded && (
              <Button sx={{ textTransform: 'none' }}>
                <ChatBubble /> Support
              </Button>
            )}
          </Grid>
        
          {user?.role === 'Admin'  || user?.role === 'superAdmin'  ? (
            <Grid item>
              <Button
                onClick={createIpProtocal}
                variant="contained"
                color="primary"
                size="large"
                startIcon={<WifiPassword />}
                disabled={loading} // Disable button when loading
                sx={{
                  padding: '4px 8px',
                  borderRadius: '8px',
                  textTransform: 'none',
                  display: 'flex',
                  ml: 1,
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '10px',
                  backgroundColor: theme.palette.primary.main,
                }}
              >
                {loading && <CircularProgress size={20} color="inherit" />} 
              </Button>
            </Grid>
          ) : null}

          {/* Snackbar for Success/Error feedback */}
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={4000}
            onClose={handleSnackbarClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            sx={{
              '& .MuiSnackbarContent-root': {
                backgroundColor: snackbarSeverity === 'success' ? 'green' : 'red',
              },
              '& .MuiSnackbarContent-message': {
                color: 'white',
              },
            }}
          >
            <Alert
              onClose={handleSnackbarClose}
              severity={snackbarSeverity}
              iconMapping={{
                success: <CheckCircleIcon />,
                error: <ErrorIcon />,
              }}
            >
              {snackbarMessage}
            </Alert>
          </Snackbar>

          {user ? (
            <Grid
              height={'10%'}
              width={'100%'}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                marginTop: '20px',
              }}
            >
              <Button variant="outlined" onClick={logout}>
                <LogoutTwoTone /> {expanded ? 'Logout' : ''}
              </Button>
            </Grid>
          ) : (
            <Grid
              height={'10%'}
              width={'100%'}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                marginTop: '20px',
              }}
            >
              <Link to="/login" style={{ textDecoration: 'none' }}>
                <Button variant="outlined">
                  <Login /> {expanded ? 'Login' : ''}
                </Button>
              </Link>
            </Grid>
          )}
        </List>
      </GlassEffect.GlassContainer>
    </NavbarContainer>
  );

  return <nav>{drawer}</nav>;
};

export default Navbar;
