import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useTheme } from "@emotion/react";
import { DateTime } from "luxon";
import { Typography } from "@mui/material";
import { useGet } from "../../helpers/axios/useApi";
import HRMPopover from "./HRMPopover";


function HRMDashboard() {
  const [employeeStatus, setEmployeeStatus] = useState({
    latePunchedInCount: [],
    onTimePunchedInCount: [],
    punchedIn: [],
    punchedOut: [],
    absent: []
  });
  const [employeesData, setEmployeeData] = useState({}); // Employee attendance data
  const [employeeIds, setEmployeeIds] = useState([]);
  const [popoverData , setPopoverData] = useState([]);
  const [popoverVisible, setPopoverVisible] = useState(false); // To toggle visibility of the popover
  const { data: dailyRecords, error: recordsError } = useGet(
    "employee/work-tracking/daily-records-multipal",
    {
      userId: employeeIds?.join(","),
      startDate: DateTime.fromObject({
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1,
        day: 1,
      }).toISODate(),
      endDate: DateTime.fromObject({
        year: new Date().getFullYear(),
        month:new Date().getMonth() + 1,
      })
        .endOf("month")
        .toISODate(),
    },
    { enabled: employeeIds?.length > 0 }, // Only make the call when employeeIds is not empty
    { queryKey: "dailyRecords" }
  );

  const { data: policyData, error: policyError } = useGet(
    "company/policy/attendece-get",
    { employeeId: employeeIds?.join(",") },
    { enabled: employeeIds?.length > 0 },
    { queryKey: "policyData" }
  );
  const {
    data: employees,
    refetch: refetchEmployees,
    isLoading,
  } = useGet("employee/all", {}, {}, { queryKey: "employees" });
  const [employeeList, setEmployeeList] = useState(employees?.data?.message[0] || []);
  useEffect(() => {
    const newEmployeeIds = employees?.data?.message[0]?.map(
      (employee) => employee._id
    );
    setEmployeeIds(newEmployeeIds); // Set employee IDs to fetch data for the current page
  }, [employees]);
  useEffect(() => {
    // Close popover if click is outside of it
    const handleClickOutside = (event) => {
        const popover = document.getElementById("popoveHRM");
        if (popover && !popover.contains(event.target)) {
            setPopoverVisible(false); // Close popover when click is outside
        }
    };

    // Add event listener when popover is visible
    if (popoverVisible) {
        document.addEventListener("click", handleClickOutside);
    }

    // Cleanup event listener
    return () => {
        document.removeEventListener("click", handleClickOutside);
    };
}, [popoverVisible]);
  useEffect(() => {
    if (employeeIds?.length > 0) {
      fetchEmployeeData(); // Fetch employee data if employee IDs are available
    }
  }, [employeeIds, dailyRecords, policyData]);
  const deepMerge = (target, source) => {
    if (
      target &&
      source &&
      typeof target === "object" &&
      typeof source === "object"
    ) {
      Object.keys(source).forEach((key) => {
        if (Array.isArray(target[key]) && Array.isArray(source[key])) {
          target[key] = [...target[key], ...source[key]];
        } else if (
          typeof target[key] === "object" &&
          typeof source[key] === "object"
        ) {
          target[key] = deepMerge({ ...target[key] }, source[key]);
        } else {
          target[key] = source[key];
        }
      });
    }
    return target;
  };

  const fetchEmployeeData = async () => {
    if (recordsError || policyError) {
      return;
    }

    const employeeData = policyData?.data?.data || [];
    const attendanceData = dailyRecords?.data?.data?.employees || [];

    // Merge employee data with attendance data
    const mergedData = employeeData.map((employee) => {
      const attendance = attendanceData.find(
        (att) => att.userId === employee.employeeId
      );

      // Merge the employee data with the attendance (currentStatus and records)
      const mergedEmployeeData = deepMerge(
        { ...employee },
        {
          currentStatus: attendance?.currentStatus || {
            isPunchedIn: false,
            punchIn: null,
          },
          records: attendance?.records || [], // Merge the attendance records
          workingDays: attendance?.workingDays || {}, // Merge workingDays (0-6)
        }
      );

      return mergedEmployeeData;
    });
    // Set merged data into state
    setEmployeeData(mergedData);
  };
  const theme = useTheme();

  useEffect(() => {
    const employeeArray = Array.isArray(employeesData) ? employeesData : Object.values(employeesData);

    if (employeeArray.length > 0) {
      employeeArray.forEach(employee => {
        const today = DateTime.now();
        const workingStartTime = employee?.workingHours?.start || "10:00";
        const workingStartDate = today.set({
          hour: parseInt(workingStartTime.split(":")[0]),
          minute: parseInt(workingStartTime.split(":")[1]),
          second: 0,
          millisecond: 0,
        });
        const todayString = today.toISODate();
        const emp = employeeList?.find((emp) => emp?._id === employee?.employeeId);

        const absentEmp = employeeArray?.filter((emp) => {
          const recordForToday = emp?.records?.find((record) => record?.day === todayString);
          return !recordForToday;
        });

        absentEmp.forEach((empo) => {
          const filteredEmp = employeeList?.find((emp) => emp?._id === empo?.employeeId);
          
          if (filteredEmp) {
            setEmployeeStatus(prevState => {
              const isAlreadyAbsent = prevState.absent.some(empInList => empInList?._id === filteredEmp?._id);
              if (!isAlreadyAbsent) {
                return {
                  ...prevState,
                  absent: [...prevState.absent, filteredEmp],
                };
              }
              return prevState;
            });
          }
        });

        employee?.records?.forEach(record => {
          const recordDate = DateTime.fromISO(record?.firstPunchIn);
          if (record?.day === todayString) {
            if (recordDate > workingStartDate) {
              if (!employeeStatus.latePunchedInCount.some(empInList => empInList?._id === emp?._id)) {
                setEmployeeStatus(prevState => ({
                  ...prevState,
                  latePunchedInCount: [...prevState.latePunchedInCount, emp],
                }));
              }
            } else {
              if (!employeeStatus.onTimePunchedInCount.some(empInList => empInList?._id === emp?._id)) {
                setEmployeeStatus(prevState => ({
                  ...prevState,
                  onTimePunchedInCount: [...prevState.onTimePunchedInCount, emp],
                }));
              }
            }

            if (recordDate && record?.lastPunchIn === null) {
              if (!employeeStatus.punchedIn.some(empInList => empInList?._id === emp?._id)) {
                setEmployeeStatus(prevState => ({
                  ...prevState,
                  punchedIn: [...prevState.punchedIn, emp],
                }));
              }
            } else {
              if (!employeeStatus.punchedOut.some(empInList => empInList?._id === emp?._id)) {
                setEmployeeStatus(prevState => ({
                  ...prevState,
                  punchedOut: [...prevState.punchedOut, emp],
                }));
              }
            }
          }
        });
      });
    }
  }, [employeesData]);
console.log(employeesData,'employeesData')
  const chartData = {
    series: [
      employeeStatus.latePunchedInCount.length,
      employeeStatus.onTimePunchedInCount.length,
      employeeStatus.punchedIn.length,
      employeeStatus.punchedOut.length,
      employeeStatus.absent.length
    ],
    options: {
      chart: {
        type: 'donut',
        toolbar: { show: false },
        animations: { enabled: true, easing: 'easeinout', speed: 800, animateGradients: true },
        events : {
             click : (event, chartContext, config) => {
              const index = config.dataPointIndex;
              const status = ['Late Punch In', 'On Time Punch In', 'Punched In', 'Punched Out', 'Absent'][index];
              console.log(status,'status')
              const today = DateTime.now();
              const todayString = today.toISODate();
              if (status === 'Late Punch In') {
                const lateUserIds = employeeStatus.latePunchedInCount?.map((emp) => emp?._id); // Get an array of _id values from latePunchedInCount

                const Records = employeesData?.map((emp) => {
                  const todayRecord = emp?.records?.filter((record) => record?.day === todayString);
                
                  // Filter records once to include only those that match the late users
                  const lateRecords = todayRecord?.filter((record) => lateUserIds.includes(record?.userId));
                
                  // If there are any late records, return them; otherwise, return an empty array
                  return lateRecords?.length > 0 ? lateRecords : null;
                }).filter(Boolean);
                     console.log(Records,'Records')
                  setPopoverData(Records);
                  setPopoverVisible(true);
               }
               if (status === 'On Time Punch In') {
                const lateUserIds = employeeStatus.onTimePunchedInCount?.map((emp) => emp?._id); // Get an array of _id values from latePunchedInCount

                const Records = employeesData?.map((emp) => {
                  const todayRecord = emp?.records?.filter((record) => record?.day === todayString);
                
                  // Filter records once to include only those that match the late users
                  const lateRecords = todayRecord?.filter((record) => lateUserIds.includes(record?.userId));
                
                  // If there are any late records, return them; otherwise, return an empty array
                  return lateRecords?.length > 0 ? lateRecords : null;
                }).filter(Boolean);
                     console.log(Records,'Records')
                  setPopoverData(Records);
                  setPopoverVisible(true);
               }
               if (status === 'Punched In') {
                const lateUserIds = employeeStatus.punchedIn?.map((emp) => emp?._id); // Get an array of _id values from latePunchedInCount

                const Records = employeesData?.map((emp) => {
                  const todayRecord = emp?.records?.filter((record) => record?.day === todayString);
                
                  // Filter records once to include only those that match the late users
                  const lateRecords = todayRecord?.filter((record) => lateUserIds.includes(record?.userId));
                
                  // If there are any late records, return them; otherwise, return an empty array
                  return lateRecords?.length > 0 ? lateRecords : null;
                }).filter(Boolean);
                     console.log(Records,'Records')
                  setPopoverData(Records);
                  setPopoverVisible(true);
               }
               if (status === 'Punched Out') {
                const lateUserIds = employeeStatus.punchedOut?.map((emp) => emp?._id); // Get an array of _id values from latePunchedInCount

                const Records = employeesData?.map((emp) => {
                  const todayRecord = emp?.records?.filter((record) => record?.day === todayString);
                
                  // Filter records once to include only those that match the late users
                  const lateRecords = todayRecord?.filter((record) => lateUserIds.includes(record?.userId));
                
                  // If there are any late records, return them; otherwise, return an empty array
                  return lateRecords?.length > 0 ? lateRecords : null;
                }).filter(Boolean);
                    
                  setPopoverData(Records);
                  setPopoverVisible(true);
               }
               if (status === 'Absent') {
                const lateUserIds = employeeStatus.absent?.map((emp) => {
                  return {
                       userId : emp?._id,
                  }
                })
                  console.log(lateUserIds,'lateUserIds')
                  setPopoverData(lateUserIds);
                  setPopoverVisible(true);
               }
            }
        }
      },
      labels: ['Late Punch In', 'On Time Punch In', 'Punched In', 'Punched Out', 'Absent'],
      plotOptions: {
        pie: {
          donut: {
            size: '85%', // Adjust the size of the donut hole
          },
        },
      },
      dataLabels: { enabled: false },
      colors: ["#FF8560", "#00E796", "#00F396", "#cc716a", "#FF2560"],
      stroke: {
        width: 3,
        colors: [
          "#FFB59A", "#4EE4B2", "#4EE4B2", "#cc716a", "#FF6C91"
        ]
      },
      legend: {
        show: true, position: 'right', horizontalAlign: 'left',
        labels: { colors: 'black', fontSize: '14px', fontFamily: 'Arial, sans-serif' },
        markers: { width: 10, height: 50, radius: 0, offsetX: -5 },
      },
      tooltip: {
        enabled: true,
        theme: "dark",
        y: {
          formatter: (val, { seriesIndex }) => {
            let employeeListForTooltip = [];

            switch (seriesIndex) {
              case 0: employeeListForTooltip = employeeStatus.latePunchedInCount; break;
              case 1: employeeListForTooltip = employeeStatus.onTimePunchedInCount; break;
              case 2: employeeListForTooltip = employeeStatus.punchedIn; break;
              case 3: employeeListForTooltip = employeeStatus.punchedOut; break;
              case 4: employeeListForTooltip = employeeStatus.absent; break;
              default: break;
            }

            const maxNamesToShow = 5;
            const displayedNames = employeeListForTooltip.slice(0, maxNamesToShow).map(emp => emp?.name || "Unknown").join("<br />");
            const remainingCount = employeeListForTooltip.length - maxNamesToShow;
            const remainingMessage = remainingCount > 0 ? `<br /><em>and ${remainingCount} more...</em>` : "";

            return `${val} employees:<br />${displayedNames}${remainingMessage}`;
          },
        },
      },
    },
  };

  // Function to close the selected list


  return (
    <div style={styles.dashboardContainer}>
      <div style={styles.chartContainer}>
        <img src="https://dailyworks.s3.ap-south-1.amazonaws.com/attendenceGrapgh.png" alt="Chart" style={styles.chartImage}/>
        <ReactApexChart
          options={chartData.options}
          series={chartData.series}
          type="donut"
          height={280}
        />
      </div>
       {popoverVisible && (
           <HRMPopover data={popoverData} />
       )}
     
    </div>
  );
}

const styles = {
  dashboardContainer: {
    display: "flex",
    
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",
    fontFamily: "'Arial', sans-serif",
  },
  chartContainer: {
    display: "flex",
    position : "relative",
    justifyContent: "center",
    flexDirection: "column",
    width: "100%",
    maxWidth: "800px",
    margingTop: "70px",
    padding: "10px",
    borderRadius: "20px",
    position: "relative",
  

  },
  chartImage: {
       position: "absolute",
       top: '50%',
       left: '29%',
       boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
       borderRadius : '50%',
       padding: '20px',
       transform: 'translate(-50%, -50%)',
       width: "80px",
       height: "80px",
  },
  employeeList: {
    marginTop: "20px",
    padding: "20px",
    backgroundColor: "#f4f4f4",
    borderRadius: "10px",
    boxShadow: "0 4px 20px rgba(0, 0, 0, 0.2)",
    width: "300px",
    maxHeight: "300px",
    overflowY: "auto",
    transform: "scale(1.05)",
    transition: "transform 0.3s ease",
  },
  listHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  closeButton: {
    background: "transparent",
    border: "none",
    fontSize: "20px",
    cursor: "pointer",
    color: "#FF2560",
    fontWeight: "bold",
  },
};

export default HRMDashboard;
