import React, { useState, useEffect } from "react";
import { Table, TableHead, TableBody, TableRow, TableCell, Button, IconButton, Box, TextField } from "@mui/material";
import { BiTrash } from "react-icons/bi";
import EditableField from "./editAble";

const InvoiceItem = ({
  items,
  onItemizedItemEdit,
  currency,
  onRowDel,
  onRowAdd,
  setModelHeader,
  payment
}) => {
  const [headers, setHeaders] = useState([
    { id: "serial", label: "Serial" },
    { id: "service", label: "service" },
    { id: "amount", label: "PRICE/RATE" },
    { id: "action", label: "ACTION" },
  ]);

  // Automatically add a new item when the component mounts if there are no items
  useEffect(() => {
    if (items.length === 0 && payment?.serviceName && payment?.amount) {
      const newItem = {
        id: Math.random().toString(36).substr(2, 9), // Random id for the item
        service: payment?.serviceName || "", // Set serviceName from payment if available
        amount: payment?.amount || "", // Set amount from payment if available
      };
      onRowAdd(newItem); // Call the parent function to add this row
    }
  }, [items, payment, onRowAdd]);

  // Handle adding a new row/item
  const handleAddRow = () => {
    const newItem = {
      id: Math.random().toString(36).substr(2, 9), // Random id for the item
      service: "", // Empty service name
      amount: "", // Empty amount
    };
    onRowAdd(newItem); // Call the parent function to add this row
  };

  // Handle adding a new header
  const handleAddHeader = () => {
    const newHeader = { id: `header${headers.length + 1}`, label: `NEW HEADER ${headers.length + 1}` };
    setHeaders([...headers.slice(0, -1), newHeader, headers[headers.length - 1]]);
    setModelHeader(headers);
  };

  // Handle changing a header's label
  const handleHeaderChange = (e, index) => {
    const updatedHeaders = headers.map((header, idx) =>
      idx === index ? { ...header, label: e.target.value } : header
    );
    setHeaders(updatedHeaders);
    setModelHeader(headers);
  };

  // Handle removing a header (and its corresponding column)
  const handleRemoveHeader = (index) => {
    const updatedHeaders = headers.filter((_, idx) => idx !== index);
    setHeaders(updatedHeaders);
    setModelHeader(headers);
  };

  return (
    <Box sx={{ boxShadow: 3, borderRadius: "8px", padding: 2 }}>
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            {headers.map((header, index) => (
              <TableCell key={header.id} sx={{ fontWeight: "bold", textAlign: "center" }}>
                {/* Editable header labels */}
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <TextField
                    value={header.label}
                    onChange={(e) => handleHeaderChange(e, index)}
                    fullWidth
                    variant="standard"
                    size="small"
                    sx={{
                      "& .MuiInputBase-root": {
                        fontSize: "1rem",
                        fontWeight: "500",
                        textAlign: "center",
                      },
                    }}
                  />
                  {/* Remove header button */}
                  {header.id !== "action" && header.id !== "amount" && (
                    <IconButton
                      onClick={() => handleRemoveHeader(index)}
                      sx={{
                        color: "red",
                        padding: "0px",
                        "&:hover": {
                          backgroundColor: "transparent",
                        },
                      }}
                    >
                      <BiTrash />
                    </IconButton>
                  )}
                </Box>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((item, rowIndex) => (
            <ItemRow
              key={item.id}
              item={item}
              onItemizedItemEdit={onItemizedItemEdit}
              onDelEvent={onRowDel}
              currency={currency}
              headers={headers}
              rowIndex={rowIndex}
              payment={payment}
            />
          ))}
        </TableBody>
      </Table>
      <Box sx={{ display: "flex", gap: 2, marginTop: 2 }}>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleAddRow} // Add a new row
          sx={{
            fontWeight: "bold",
            textTransform: "none",
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            "&:hover": {
              boxShadow: "0px 6px 10px rgba(0, 0, 0, 0.2)",
            },
          }}
        >
          Add Item
        </Button>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleAddHeader}
          sx={{
            fontWeight: "bold",
            textTransform: "none",
            border: "1px solid #3f51b5",
            "&:hover": {
              borderColor: "#3f51b5",
              backgroundColor: "#e8eaf6",
            },
          }}
        >
          Add Header
        </Button>
      </Box>
    </Box>
  );
};

const ItemRow = ({ item, onItemizedItemEdit, onDelEvent, currency, headers, rowIndex, payment }) => {
  const handleDelete = () => {
    onDelEvent(item);
  };

  return (
    <TableRow>
      {/* Serial Number Column (Not Editable) */}
      <TableCell sx={{ textAlign: "center" }}>
        {rowIndex + 1} {/* Display row number */}
      </TableCell>

      {/* Conditionally render payment details if they exist */}
      {headers.map((header) => {
        if (header.id === "action") {
          return (
            <TableCell key={header.id} sx={{ textAlign: "center" }}>
              <IconButton
                onClick={handleDelete}
                sx={{
                  color: "white",
                  backgroundColor: "red",
                  padding: "8px",
                  "&:hover": {
                    backgroundColor: "darkred",
                    transform: "scale(1.1)",
                  },
                  transition: "all 0.3s ease",
                }}
              >
                <BiTrash />
              </IconButton>
            </TableCell>
          );
        } else if (header.id !== "serial") {
          // Editable fields for other columns except "serial"
          return (
            <TableCell key={header.id} sx={{ textAlign: "center" }}>
              {/* Check if the row is the first one and pre-populate with payment values */}
              <EditableField
                onItemizedItemEdit={onItemizedItemEdit}
                cellData={{
                  type: header.id === "amount" ? "number" : "text",
                  name: header.id,
                  
                  placeholder: header.label,
                  value: item[header.id] || (rowIndex === 0 && header.id === "Service" ? payment?.serviceName :rowIndex === 0 &&  header.id === "amount" ? payment?.amount : ""),
                  id: item.id,
                }}
              />
            </TableCell>
          );
        } else {
          // For serial column, just show the row number (no editable input)
          return null;
        }
      })}
    </TableRow>
  );
};

export default InvoiceItem;
