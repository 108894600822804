import React, { useEffect, useState } from "react";
import {
  Tabs,
  Tab,
  Box,
  Typography,
  MenuItem,
  Select,
  FormControl,
  styled,
  Grid,
  Collapse,
  IconButton,
  Tooltip,
  Popover,
  TextField,
  InputAdornment,
  Snackbar,
  Alert,
  Dialog,
} from "@mui/material";
import { Rating } from "@mui/material";
import { useGet, usePost } from "../../../helpers/axios/useApi";
import { useAuth } from "../../../middlewares/auth";
import ObjectList from "./addinationDetails";
import ReplyIcon from "@mui/icons-material/Reply";
import SendIcon from "@mui/icons-material/Send";
import GlassEffect from "../../../theme/glassEffect";
import InvoiceForm from "./invoice/invoiceForm";
import { Container, padding } from "@mui/system";
import { Button } from "reactstrap";
import { useTheme } from "@emotion/react";
import AddPaymentForm from "./payments/paymentForm";
import PaymentCard from "./payments/PaymentCard";
import ProposalEditor from "./proposels";
import { RemoveRedEyeRounded } from "@mui/icons-material";
import ProposalDialog from "./proposels/render";

const StyledSelect = styled(Select)(({ theme }) => ({
  "& .MuiSelect-select": {
    padding: "5px 8px",
    fontSize: "0.875rem",
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
    borderColor: theme.palette.grey[300],
    "&:hover fieldset": {
      borderColor: theme.palette.primary.main,
    },
    "&.Mui-focused fieldset": {
      borderColor: theme.palette.primary.main,
    },
  },
  "& .MuiInputLabel-root": {
    fontSize: "0.75rem",
  },
}));

const stages = [
  "Create",
  "Follow Up Required",
  "Booking",
  "Session",
  "Payment",
  "Documentation",
  "Success",
  "Not Interested",
  "DNP - Did Not Pick up",
  "Invalid Number",
];

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function TaskTabs({ tasks = [], leadID, leadDetail }) {
  const [value, setValue] = useState(0);
  const [selectedStage, setSelectedStage] = useState("");
  const [openTaskId, setOpenTaskId] = useState(null);
  const [proposelOpen, setProposelOpen] = useState(false);
  const [paymentOpen, setPaymentOpen] = useState(false);
  const [paymentDetails, setPaymentDetails] = useState([]);
  const [openRenderProposal, setOpenRenderProposal] = useState(null)
  const { user } = useAuth();
  const [proposalData, setProposalData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [feedBack, setFeedBack] = useState({
    desc: "",
    points: 1,
    leadId: "",
    taskId: "",
  });
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [feedbackData, setFeedBackData] = useState([]);
  const { data: proposal , refetch : reFetchProposal} = useGet("/leads/proposal/get", {
    leadId: leadID,
  });
  const { data: payments ,refetch : reFetchPayments} = useGet("/leads/payment/get", {
    leadId: leadID,
  });

   const handleOpenRenderProposal = (id)=>{
        setOpenRenderProposal(id)
   }
  const handleProposalClose = ()=>{
    setOpenRenderProposal(null)
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handlePaymentOpen = () => {
    setPaymentOpen(true);
  };

  const handlePaymentClose = () => {
    setPaymentOpen(false);
  };
  const handleProposelOpen = () => {
    setProposelOpen(true);
  };
  const handleProposelClose = () => {
    setProposelOpen(false);
  };
  const handleStageChange = (event) => {
    setSelectedStage(event.target.value);
  };

  const handleDescriptionToggle = (taskId) => {
    setOpenTaskId(openTaskId === taskId ? null : taskId);
  };

  const giveFeedBack = usePost("feedback/create");
  const { data: feedbacks, refetch } = useGet("feedback/get", {
    leadId: leadID,
  });

  useEffect(() => {
    if (feedbacks) {
      setFeedBackData(feedbacks?.data?.message);
    }
    if (payments?.data?.data) {
      setPaymentDetails(payments?.data?.data);
    }
    if (proposal?.data?.data) {
      setProposalData(proposal?.data?.data);
    }
  }, [feedbacks, payments , proposal]);

  const formatDate = (date) => {
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, "0");
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const year = d.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const filteredTasks = tasks.filter((task) => {
    const isCalling = task.taskType.startsWith("Calling");
    return (
      selectedStage === "" ||
      (selectedStage === "Create" &&
        (isCalling || task.taskType === "Create")) ||
      task.taskType === selectedStage
    );
  });

  const { data: employees } = useGet(
    "employee/all",
    {},
    {},
    { queryKey: "employees" }
  );

  const getEmployeeName = (id) => {
    console.log(id, "pro");
    let employee = employees?.data?.message[0]?.find(
      (employee) => employee._id === `${id}`
    );
    return employee ? `${employee.name}` : user?.companyName;
  };

  const handleClick = (event, taskId, leadId) => {
    setAnchorEl(event.currentTarget);
    setFeedBack({ ...feedBack, leadId, taskId });
  };

  const handleClose = () => {
    setAnchorEl(null);
    setFeedBack({ desc: "", points: 1 });
  };

  const handleSendFeedback = async () => {
    try {
      await giveFeedBack.mutateAsync(feedBack);
      setSnackMessage("Feedback submitted successfully!");
      refetch();
    } catch (error) {
      setSnackMessage("Failed to submit feedback. Please try again.");
    } finally {
      setSnackOpen(true);
      handleClose();
    }
  };

  const handleSnackClose = () => {
    setSnackOpen(false);
  };

  const open = Boolean(anchorEl);
const handleUpdated = async()=>{
  reFetchProposal()
}
const handleAdded = async()=>{
  reFetchProposal()
}

const handlePaymentAdded = async()=>{
  reFetchPayments()
}

  return (
    <Box
      sx={{
        width: "100%",
        borderRadius: "10px",
        mt: "10px",
        boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
        padding: "10px",
        height: "80vh",
      }}
    >
      <GlassEffect.GlassContainer>
        <Tabs value={value} onChange={handleChange} aria-label="task tabs">
          <Tab label="Tasks" id="tab-0" aria-controls="tabpanel-0" />
          <Tab label="Payments" id="tab-1" aria-controls="tabpanel-1" />
          <Tab label="Proposals" id="tab-2" aria-controls="tabpanel-2" />
        </Tabs>

        <TabPanel value={value} index={0}>
          <Grid sx={{ height: "70vh", overflowY: "scroll" }}>
            <Grid width={"30%"}>
              <FormControl fullWidth>
                <StyledSelect
                  value={selectedStage}
                  onChange={handleStageChange}
                  size="small"
                  displayEmpty
                >
                  <MenuItem value="">
                    <em>All</em>
                  </MenuItem>
                  {stages.map((stage) => (
                    <MenuItem key={stage} value={stage}>
                      {stage}
                    </MenuItem>
                  ))}
                </StyledSelect>
              </FormControl>
            </Grid>
            <Grid>
              {filteredTasks.length > 0 ? (
                filteredTasks.map((task) => {
                  // Find the feedback associated with the current task
                  const taskFeedback =
                    feedbackData?.filter(
                      (feedback) => feedback.taskId === task._id
                    ) || [];

                  return (
                    <Grid
                      key={task.id}
                      sx={{
                        boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                        borderRadius: "10px",
                        padding: "10px",
                        backgroundColor: "white",
                        display: "flex",
                        flexDirection: "column",
                        mt: 2,
                        mb: 2,
                      }}
                    >
                      <Grid
                        display={"flex"}
                        justifyContent={"space-between"}
                        sx={{ mb: 2 }}
                      >
                        <Typography
                          sx={{
                            fontSize: "0.875rem",
                            fontWeight: "bold",
                            display: "flex",
                            flexWrap: "wrap",
                            gap: 1,
                            lineHeight: 1.5,
                            color: "grey",
                          }}
                        >
                          <span style={{ color: "black" }}>
                            {task.taskType}
                          </span>
                          {getEmployeeName(task.assignee) !==
                          getEmployeeName(task.createdBy) ? (
                            <>
                              was performed by{" "}
                              <span style={{ color: "black" }}>
                                {getEmployeeName(task.createdBy)}
                              </span>{" "}
                              on{" "}
                              <span style={{ color: "black" }}>
                                {formatDate(task.createdAt)}
                              </span>
                            </>
                          ) : (
                            <>
                              was created on{" "}
                              <span style={{ color: "black" }}>
                                {formatDate(task.createdAt)}
                              </span>
                            </>
                          )}
                        </Typography>
                      </Grid>

                      {task.status === "completed" && (
                        <Grid item xs={12} mt={-2.5}>
                          <Grid
                            display={"flex"}
                            justifyContent={"flex-start"}
                            sx={{ mb: 2 }}
                            gap={8}
                          >
                            <Typography
                              sx={{
                                fontSize: "0.875rem",
                                fontWeight: "bold",
                                ml: 2,
                                color: "grey",
                              }}
                            >
                              This stage was completed by{" "}
                              <span style={{ color: "black" }}>
                                {getEmployeeName(task.completedBy)}{" "}
                              </span>
                              On{" "}
                              <span style={{ color: "black" }}>
                                {formatDate(task.completedAt)}
                              </span>
                            </Typography>
                          </Grid>
                        </Grid>
                      )}
                      <Box mt={0}>
                        <Typography
                          variant="subtitle2"
                          sx={{ fontWeight: "bold", textAlign: "left" }}
                        >
                          Feedbacks :
                        </Typography>
                        {taskFeedback.length > 0 ? (
                          taskFeedback.map((feedback) => (
                            <>
                              <Typography
                                key={feedback._id}
                                sx={{
                                  fontSize: "0.865rem",
                                  color: "black",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {/* Show stars for rating */}
                                Descripation : {feedback.desc}
                              </Typography>
                              <Typography
                                sx={{
                                  textAlign: "left",
                                }}
                              >
                                <Rating
                                  value={feedback.points}
                                  readOnly
                                  sx={{ mr: 1, fontSize: "1rem" }}
                                />{" "}
                              </Typography>
                            </>
                          ))
                        ) : (
                          <Typography
                            sx={{ fontSize: "0.875rem", color: "grey" }}
                          >
                            No feedback available.
                          </Typography>
                        )}
                      </Box>
                      <Grid
                        item
                        xs={12}
                        sx={{
                          display: "flex",
                          justifyItems: "space-between",
                          justifyContent: "space-between",
                          mt: -3,
                        }}
                      >
                        <Box>
                          <Typography
                            sx={{
                              fontSize: "0.875rem",
                              fontWeight: "bold",
                              textAlign: "left",
                              cursor: "pointer",
                              color: "#4287f5",
                              mt: 2,
                            }}
                            onClick={() => handleDescriptionToggle(task._id)}
                          >
                            {openTaskId === task._id ? (
                              <>Hide Details</>
                            ) : (
                              <>View Details</>
                            )}
                          </Typography>
                          <Collapse in={openTaskId === task._id}>
                            <Typography
                              sx={{
                                fontSize: "0.875rem",
                                mt: 1,
                                fontWeight: "Bold",
                              }}
                            >
                              {task.descripation}
                            </Typography>
                            <Grid width={"100%"}>
                              <ObjectList data={task?.customFields} />
                            </Grid>

                            {/* Render feedback for the task */}
                          </Collapse>
                        </Box>

                        <Box display={"flex"}>
                          <Typography
                            sx={{
                              fontSize: "0.875rem",
                              mt: 1,
                              height: "1.5rem",
                              width: "1.5rem",
                              backgroundColor: "#1c2d45",
                              textAlign: "center",
                              color: "white",
                              borderRadius: "50%",
                            }}
                          >
                            {task.points}
                          </Typography>
                          {["superAdmin", "Admin", "Manager"].includes(
                            user?.role
                          ) && (
                            <Tooltip title="Feedback">
                              <IconButton
                                onClick={(event) =>
                                  handleClick(event, task._id, task?.leadId)
                                }
                              >
                                <ReplyIcon color="primary" />
                              </IconButton>
                            </Tooltip>
                          )}
                        </Box>
                      </Grid>
                    </Grid>
                  );
                })
              ) : (
                <Typography>
                  No tasks available for the selected stage.
                </Typography>
              )}
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Box
            height={"67vh"}
            sx={{
              overflow: "auto",
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
            }}
          >
            <Grid display={"flex"} justifyContent={"flex-end"}>
              <Button
                style={{
                  backgroundColor: useTheme().palette.primary.main,
                  padding: "15px 20px",
                  fontSize: "14px",
                  borderRadius: "5px",
                  border: "none",
                  cursor: "pointer",
                }}
                onClick={handlePaymentOpen}
              >
                Create Payment
              </Button>
            </Grid>
            <Grid
              display={"flex"}
              flexDirection={"row"}
              gap={2}
              flexWrap={"wrap"}
            >
              {paymentDetails && paymentDetails.length > 0
                ? paymentDetails.map((payment) => (
                    <PaymentCard
                      key={payment._id}
                      payment={payment}
                      leadDetail={leadDetail}
                    />
                  ))
                : "No payments available"}
            </Grid>
          </Box>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Box
            height={"67vh"}
            sx={{
              overflow: "auto",
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              alignItems:'center'
            }}
          >
             <Grid display={'flex'} justifyContent={'flex-end'} width={'90%'}>
            <Button
              style={{
                backgroundColor: useTheme().palette.primary.main,
                padding: "15px 20px",
                fontSize: "14px",
                borderRadius: "5px",
                border: "none",
                cursor: "pointer",
              }}
              onClick={handleProposelOpen}
            >
              Create Proposel
            </Button>  </Grid>

            <Grid width={"90%"} display={'flex'} flexDirection={'column'} gap={2}>
              {proposalData &&
                proposalData?.map((item) => {
                  return (
                    <>
                      <GlassEffect.GlassContainer>
                        <Box
                          sx={{
                            display: "flex",
                            gap: 2,
                            justifyContent: "space-between",
                            alignItems: "center",
                            backgroundColor: "#f9f9f9", // Soft light background color
                            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", // Subtle shadow for depth
                            borderRadius: "8px",
                            padding: "20px",
                          }}
                        >
                          <Grid>
                            <Typography textAlign={"left"} fontWeight={"bold"}>
                              Proposal by
                            </Typography>
                            <Typography textAlign={"left"}>
                              {getEmployeeName(item?.proposalBy)}
                            </Typography>
                          </Grid>
                          <Grid>
                            <Typography textAlign={"left"} fontWeight={"bold"}>
                              Status
                            </Typography>
                            <Typography
                              textAlign={"center"}
                              sx={{
                                backgroundColor:
                                  item?.status === "Pending"
                                    ? "#e8e0c1"
                                    : "#caebcb",
                                color:
                                  item?.status === "Pending"
                                    ? "#f5c60a"
                                    : "#47de4f",
                                padding: "5px 12px",
                                width:'80px',
                                borderRadius: "25px",
                                fontSize: "12px",
                              }}
                            >
                              {item?.status}
                            </Typography>
                          </Grid>
                          <Grid>
                            <Typography textAlign={"left"} fontWeight={"bold"}>
                              Approvel Status
                            </Typography>
                            <Typography
                              textAlign={"center"}
                              sx={{
                                backgroundColor:
                                  item?.approvalStatus === "Pending"
                                    ? "#e8e0c1" // Background for Pending
                                    : item?.approvalStatus === "Review"
                                    ? "#e6c8be" // Background for Review (you can change this to whatever color you prefer)
                                    : "#caebcb", // Background for all other statuses

                                color:
                                  item?.approvalStatus === "Pending"
                                    ? "#f5c60a" // Text color for Pending
                                    : item?.approvalStatus === "Review"
                                    ? "#d95932" // Text color for Review (adjust as needed)
                                    : "#72d932", // Text color for all other statuses
                                    padding: "5px 7px",
                                    borderRadius: "25px",
                                    fontSize: "12px",
                              }}
                            >
                              {item?.approvalStatus}
                            </Typography>
                          </Grid>
                          <Grid>
                            <IconButton
                              onClick={()=>handleOpenRenderProposal(item?._id)}
                            >
                              <RemoveRedEyeRounded
                                sx={{ color: useTheme().palette.primary.main }}
                              />
                            </IconButton>
                          </Grid>
                        
                        </Box>
                      </GlassEffect.GlassContainer>
                    </>
                  );
                })}
            </Grid>
          </Box>
        </TabPanel>

        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          sx={{
            borderColor: "#ced1d6",
            borderRadius: "15px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            border: "none",
          }}
        >
          <Box sx={{ padding: 2, width: 300 }}>
            <TextField
              type="text"
              value={feedBack.desc}
              onChange={(e) =>
                setFeedBack({ ...feedBack, desc: e.target.value })
              }
              placeholder="Enter feedback"
              fullWidth
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#ced1d6",
                  },
                  "&:hover fieldset": {
                    borderColor: "#398bf7",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#398bf7",
                  },
                },
              }}
            />
            <Typography variant="body2" sx={{ mt: 1 }}>
              Rate Task:
            </Typography>
            <Rating
              name="feedback-points"
              value={feedBack.points}
              onChange={(event, newValue) =>
                setFeedBack({ ...feedBack, points: newValue })
              }
              sx={{ mb: 1 }}
            />
            <Grid
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "right",
                mb: 2,
              }}
            >
              <InputAdornment position="end">
                <IconButton
                  onClick={handleSendFeedback}
                  sx={{
                    "&:hover": {
                      color: "#038cfc",
                    },
                  }}
                >
                  <SendIcon />
                </IconButton>
              </InputAdornment>
            </Grid>
          </Box>
        </Popover>

        <Snackbar
          open={snackOpen}
          autoHideDuration={6000}
          onClose={handleSnackClose}
        >
          <Alert
            onClose={handleSnackClose}
            severity="success"
            sx={{ width: "100%", mb: 20 }}
          >
            {snackMessage}
          </Alert>
        </Snackbar>
      </GlassEffect.GlassContainer>

      <Dialog
        open={paymentOpen}
        onClose={handlePaymentClose}
        PaperProps={{ sx: { minWidth: "100vh", padding: 2 } }}
      >
        <Container>
          <AddPaymentForm leadDetail={leadDetail} paymentAdded={handlePaymentAdded}/>
        </Container>
      </Dialog>
      <Dialog
        open={proposelOpen}
        onClose={handleProposelClose}
        PaperProps={{ sx: { minWidth: "150vh", padding: 2, height: "70vh" } }}
      >
        <ProposalEditor leadId={leadDetail?._id} added={handleAdded} />
      </Dialog>


     
        {proposalData && proposalData.map((item)=>(
           <ProposalDialog proposal={item} open={openRenderProposal === item?._id ? true :false} close={handleProposalClose} leadDetail={leadDetail}  updated={handleUpdated}/>
        ))}
      
    </Box>
  );
}

export default TaskTabs;
