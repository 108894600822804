import { useTheme } from "@emotion/react";
import { CircularProgress, IconButton, Typography } from "@mui/material";
import { border, flexbox, Grid, height } from "@mui/system";
import { color } from "framer-motion";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useGet, usePost } from "../../helpers/axios/useApi";
import LeavePopover from "./LeavePopover";


function Leaves() {
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1); // Default to current month
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear()); // Default to current year
  const theme = useTheme();
  const [leaves, setLeave] = useState([]);
  const [series, setSeries] = useState([]);
  const [popoverData, setPopoverData] = useState(null); // To hold data of clicked donut slice
  const [popoverVisible, setPopoverVisible] = useState(false); // To toggle visibility of the popover
  useEffect(() => {
    // Close popover if click is outside of it
    const handleClickOutside = (event) => {
        const popover = document.getElementById("popover");
        if (popover && !popover.contains(event.target)) {
            setPopoverVisible(false); // Close popover when click is outside
        }
    };

    // Add event listener when popover is visible
    if (popoverVisible) {
        document.addEventListener("click", handleClickOutside);
    }

    // Cleanup event listener
    return () => {
        document.removeEventListener("click", handleClickOutside);
    };
}, [popoverVisible]);
  const {
    data: leave,
    refetch,
    loading,
  } = useGet(
    "employee/leave/get-all-by-month",
    {
      month: selectedMonth,
      year: selectedYear,
    },
    {}
  );

  const handleLeaveRefetch = () => {
    refetch();
    setPopoverVisible(false);
    getSeries();
  };
  useEffect(() => {
    if (leave?.data?.data) {
      setLeave(leave?.data?.data);
      getSeries();
    } else {
      setLeave([]);
    }
  }, [leaves, selectedMonth, selectedYear]);

  const hanldeMonthChange = (e) => {
    setSelectedMonth(e.target.value);
    refetch();
  };
  const hanldeYearChange = (e) => {
    setSelectedYear(e.target.value);
    refetch();
  };

  const getSeries = () => {
    if (!loading) {
      setSeries([
        leaves?.filter((leave) => leave.status === "Approved").length,
        leaves?.filter((leave) => leave.status === "Pending").length,
        leaves?.filter((leave) => leave.status === "Rejected").length,
      ]);
    }
  };
  // Donut Chart Data
  const donutChartData = {
    series: series,
    options: {
      chart: {
        type: "donut",
        height: 200,
        toolbar: { show: false },
        animations: {
          enabled: true,
          easing: "easeinout",
          speed: 800,
          animateGradients: true,
        },
        events: {
          click: (event, chartContext, config) => {
            const index = config.dataPointIndex;
            if (index >= 0 && index < series.length) {
              const status = ["Approved", "Pending", "Rejected"][index];
              setPopoverData({ status, count: series[index] });
              setPopoverVisible(true); // Show popover
            } else {
              console.error("Invalid slice index:", index);
            }
          },
        },
      },
      tooltip: {
        enabled: true,
        followCursor: true,
        offsetX: 10,
        theme: "dark",
        y: {
          formatter: (val, { seriesIndex }) => {
            switch (seriesIndex) {
              case 0:
                return `${val} Approved`;
              case 1:
                return `${val} Pending`;
              case 2:
                return `${val} Rejected`;
              default:
                return `${val}`;
            }
          },
        },
      },

      legend: {
        show: true,
        position: "right",
        horizontalAlign: "left",
        labels: {
          colors: "black",
          fontSize: "14px",
          fontFamily: "Arial, sans-serif",
        },
        markers: { width: 10, height: 10, radius: 0, offsetX: -5 },
      },
      dataLabels: {
        enabled: false, // Disable data labels (including percentages)
        markers: {
          width: 10,
          height: 10,
          radius: 0,
        },
      },
      labels: ["Approved", "Pending", "Rejected"], // Updated labels
      plotOptions: {
        pie: {
          donut: {
            size: "76%",
          },
        },
      },
      colors: ["#00E396", "#FF4560", "#FF914D"], // Added a color for "Rejected"
      responsive: [
        {
          breakpoint: 600,
          options: {
            chart: {
              height: 250,
            },
          },
        },
      ],
    },
  };

  // Handle month/year selection change

  return (
    <div style={styles.dashboardContainer}>
      <div style={styles.chartContainer}>
        {/* Date Selection */}
        <div style={styles.dateSelection}>
          <select
            value={selectedMonth}
            onChange={(e) => hanldeMonthChange(e)}
            style={styles.select}
          >
            {[
              "January",
              "February",
              "March",
              "April",
              "May",
              "June",
              "July",
              "August",
              "September",
              "October",
              "November",
              "December",
            ].map((monthName, index) => (
              <option key={index} value={index + 1}>
                {monthName}
              </option>
            ))}
          </select>
          <select
            value={selectedYear}
            onChange={(e) => hanldeYearChange(e)}
            style={styles.select}
          >
            {Array.from({ length: 10 }, (_, i) => (
              <option key={i} value={2020 + i}>
                {2020 + i}
              </option>
            ))}
          </select>
        </div>
        <img
          src="https://dailyworks.s3.ap-south-1.amazonaws.com/leavesGraph.png"
          alt="Chart"
          style={styles.chartImage}
        />
        {/* 3D Donut Chart */}
        <div id="donutChart" style={styles.chartWrapper}>
          <ReactApexChart
            options={donutChartData.options}
            series={donutChartData.series}
            type="donut"
            height={160}
          />
        </div>
        {popoverVisible && <LeavePopover data={popoverData} leaves={leaves} refetch={handleLeaveRefetch}/>}
      </div>
    </div>
  );
}

// Styles for the component
const styles = {
  dashboardContainer: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    marginTop: "-20px",
    width: "100%",
    maxWidth: "800px",
    borderRadius: "20px",
  },
  chartContainer: {
    display: "flex",
    position: "relative",
    justifyContent: "center",
    flexDirection: "column",
    width: "100%",
    maxWidth: "800px",
    padding: "10px",
    borderRadius: "20px",
    position: "relative",
  },
  chartImage: {
    position: "absolute",
    top: "57%",
    left: "36%",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
    borderRadius: "50%",
    padding: "20px",
    transform: "translate(-50%, -50%)",
    width: "70px",
    height: "70px",
  },
  chartWrapper: {
    width: "100%",
    marginTop: "-10px",
  },
  dateSelection: {
    display: "flex",
    justifyContent: "left",
    color: "grey",
    height: "30px",
    marginBottom: "20px",
    alignItems: "top",
  },
  select: {
    fontSize: "14px",
    borderRadius: "5px",
    outLine: "none",
    border: "none",
    color: "grey",
    backgroundColor: "transparent",
  },
  button: {
    padding: "8px 15px",
    backgroundColor: "#00E396",
    color: "#fff",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },
};







export default Leaves;
