import React, { useEffect, useState } from "react";
import CalendarView from "../../components/emplyoee/calenderView";
import {
  Box,
  CircularProgress,
  Collapse,
  Grid,
  IconButton,
  Popover,
  TextField,
  Typography,
} from "@mui/material";
import ProfileNav from "../../components/user/profiveNav";
import { useGet, usePost } from "../../helpers/axios/useApi";
import { AccessTime, Event, ExpandLess, ExpandMore } from "@mui/icons-material";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import InfoIcon from "@mui/icons-material/Info";
import PersonPinIcon from "@mui/icons-material/PersonPin";
import EventNoteIcon from "@mui/icons-material/EventNote";
import moment from "moment";
import LinkIcon from "@mui/icons-material/Link";
import { Link } from "react-router-dom";
import { useAuth } from "../../middlewares/auth";
import { useTheme } from "@emotion/react";
import GetFileThumbnail from "../../components/emplyoee/getFileThumnail";
import FeedbackIcon from "@mui/icons-material/Feedback";
import { Button } from "reactstrap";
import { color } from "framer-motion";
import CryptoJS from "crypto-js";
function Calender() {
  const [currentMonth, setCurrentMonth] = useState(moment().month() + 1);
  const [currentYear, setCurrentYear] = useState(moment().year());
  const [events, setEvents] = useState([]);
  const [dailyWork, setDailyWork] = useState([]);
  const [selected, setSelected] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [feedback, setFeedback] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const { user } = useAuth();
  const theme = useTheme();
  const { data: getMeetings } = useGet("meetings/get", {
    month: currentMonth,
    year: currentYear,
  });
  const [openFeedback, setOpenFeedback] = useState(null); // State to control collapse

  // Toggle collapse on feedback
  const handleToggleFeedback = (id) => {
    setOpenFeedback((prev) => prev !== null ? null : id);
  };

  const handleFeedbackIconClick = () => {
    setShowFeedbackInput(!showFeedbackInput);
  };

  const open = Boolean(anchorEl);

  // File drop handler

  const { data: getDailyWorkData, refetch } = useGet(
    "/emplyoee/daily-work/get",
    {
      employeeId: user?._id,
      month: currentMonth,
      year: currentYear,
    }
  );

  console.log(getDailyWorkData, " dnkadlsfgbkjdsaf");
  const handleGiveFeedback = usePost("/emplyoee/daily-work/update");
  const handlePopoverOpen = (event, file, id) => {
    setSelected(id);
    setAnchorEl(event.currentTarget);
    setSelectedFile(file);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setSelectedFile(null);
  };
  useEffect(() => {
    if (getDailyWorkData?.data?.data) {
      setDailyWork(getDailyWorkData.data?.data);
    }
  }, [getDailyWorkData]);

  const { data: employees } = useGet(
    "employee/all",
    {},
    {},
    { queryKey: "employees" }
  );
  const handleFeedbackChange = (e) => {
    setFeedback(e.target.value);
  };

  const handleSubmitFeedback = async () => {
    setLoading(true);
    const data = {
      feedback: feedback,
      id: selected,
    };
    const res = await handleGiveFeedback.mutateAsync(data);

    console.log(res);
    setLoading(false);
    refetch();
  };
  useEffect(() => {
    if (getMeetings?.data?.data) {
      setEvents(getMeetings.data.data);
    }
  }, [getMeetings]);

  const getEmployeeName = (id) => {
    let employee = employees?.data?.message[0]?.find(
      (employee) => employee._id === id
    );
    return employee ? `${employee.name}` : "Imperial Milestones";
  };

  // Combine dailyWork and events
  const combinedData = [...dailyWork, ...events].sort((a, b) => {
    // Assuming 'date' field for daily work and 'meetingDate' for events
    const dateA = a.date ? new Date(a.date) : new Date(a.createdAt);
    const dateB = b.date ? new Date(b.date) : new Date(b.createdAt);
    return dateA - dateB;
  });
  console.log(combinedData, "combinedData");

  const handleTimes = (month, year) => {
    setCurrentMonth(month);
    setCurrentYear(year);
  };
  const id = open ? "file-thumbnail-popover" : undefined;
  return (
    <>
      <Box
        width={"93%"}
        height={"100vh"}
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "2rem",
        }}
      >
        <Box
          pt={3}
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "2rem",
          }}
        >
          <Grid
            container
            spacing={2}
            sx={{
              width: "100%",
              display: "flex",
              position: "sticky",
              top: 0,
              zIndex: 999,
            }}
          >
            <Grid item xs={12} md={12} container justifyContent="flex-end">
              <ProfileNav />
            </Grid>
          </Grid>
        </Box>
        <Grid
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            gap: "1rem",
          }}
        >
          <Grid
            height={"85vh"}
            sx={{
              backgroundColor: "white",
              borderRadius: "15px",
              boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
              width: "65%",
              padding: "20px",
            }}
          >
            <CalendarView
              size={{
                width: "100%",
                height: "80vh",
              }}
              getTimes={handleTimes}
            />
          </Grid>
          <Grid
            sx={{
              backgroundColor: "white",
              borderRadius: "15px",
              boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
              width: "33%",
              padding: "20px",
              mr: "1rem",
            }}
          >
            <Typography
              variant="h6"
              sx={{
                width: "100%",
                fontSize: "1.5rem",
                fontWeight: "bold",
                marginBottom: "1rem",
                textAlign: "left",
                display: "flex",
                alignItems: "center",
                gap: "0.5rem",
                padding: "5px 20px ",
                boxShadow: "0 2px 0px rgba(0,0,0,0.1)",
                backgroundColor: "white",
              }}
            >
              Events{" "}
              <EventNoteIcon sx={{ fontSize: "1.5rem", color: "#edac07" }} />
            </Typography>
            <Grid
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                mt: 1,
                gap: "0.5rem", // Increase gap for better separation
                backgroundColor: "white",
                height: "73vh",
                overflow: "scroll",
              }}
            >
              {combinedData.length > 0 ? (
                combinedData.map((item, index) => (
                  <Box key={index}>
                    {/* Render dailyWork or event details here */}
                    {item.date ? (
                      <Box>
                        <Grid
                        onClick={() => handleToggleFeedback(item._id)}
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            cursor: "pointer",
                            gap: 2,
                            marginTop: 2,
                            marginLeft: 2,
                            width: "90%",
                            alignContent: "center",
                            justifyContent: "space-between",
                            backgroundColor:
                              useTheme().palette.background.default,
                            padding: 2,
                            fontSize: "16px",
                            textAlign: "left",
                            boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                            position: "sticky",
                            top: 0,
                            zIndex: 1,
                            transition:
                              "transform 0.3s ease, color 0.3s ease, box-shadow 0.3s ease", // Smooth transition for all effects
                            borderRadius: 5,
                            color: theme.palette.text.primary,
                            "&:hover": {
                              transform: "scale(1.05)", // Slight zoom effect on hover
                              boxShadow: "0 8px 20px rgba(0, 0, 0, 0.2)", // Increase shadow size for a floating effect
                            },

                            "&:active": {
                              transform: "scale(1.02)", // A slight zoom in when clicked (active state)
                            },
                          }}
                        >
                          <Box>
                            <Typography>{item.description}</Typography>
                            <Typography>
                              Date: {new Date(item.date).toLocaleDateString()}
                            </Typography>
                      
                        
                          </Box>

                          <Box
                            sx={{
                              display: "flex",
                              alignItem: "center",
                              gap: 2,
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                cursor: "pointer",
                                transition: "transform 0.3s, box-shadow 0.3s",
                                "&:hover": {
                                  transform: "scale(1.05) rotateY(10deg)",
                                  boxShadow: "0 10px 20px rgba(0, 0, 0, 0.1)",
                                },
                              }}
                              onClick={(e) => handlePopoverOpen(e, item.file)} // Ensure this function is defined to handle the popover state
                            >
                              <Link
                                to={`${item.file}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <GetFileThumbnail
                                  fileType={item.fileType}
                                  fileUrl={item.file}
                                />
                              </Link>
                            </Box>
                            <IconButton
                              color="primary"
                              onClick={(e) =>
                                handlePopoverOpen(e, item.file, item?._id)
                              }
                            >
                              <FeedbackIcon />
                            </IconButton>
                          </Box>
                          <Collapse in={openFeedback === item._id}>
                          <Typography sx={{ fontSize: "16px" }}>
                              Feedbacks:
                            </Typography>
                              {item?.feedBack &&
                                item?.feedBack.map((feedback, index) => (
                                  <Typography
                                    key={index}
                                    fontSize={"12px"}
                                    ml={2}
                                  >
                                    {feedback?.feedback} ~{" "}
                                    {feedback?.feedbackGiverName ||
                                      "Imperial Milestones"}
                                  </Typography>
                                ))}
                            </Collapse>
                        </Grid>

                        {/* Feedback Section */}
                        <Popover
                          id={id} // Ensure `id` is dynamically generated if necessary
                          open={open} // Make sure open state is managed properly
                          anchorEl={anchorEl} // Anchor element for popover
                          onClose={handlePopoverClose} // Function to close popover
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                        >
                          <Box sx={{ margin: 2 }}>
                            <TextField
                              label="Add Feedback"
                              value={feedback} // Feedback state value
                              onChange={handleFeedbackChange} // Ensure this handles state change properly
                              fullWidth
                              multiline
                              margin="normal"
                            />
                            <Button
                              onClick={handleSubmitFeedback} // Ensure this submits feedback properly
                              color="primary"
                              variant="contained"
                              style={{
                                backgroundColor: theme.palette.primary.main,
                                color: "white",
                                padding: "5px 8px",
                                borderRadius: "10px",
                                border: "none",
                                cursor: "pointer",
                              }}
                              fullWidth
                              disabled={loading} // Loading state to disable the button while submitting
                              sx={{ marginTop: 2 }}
                            >
                              {loading ? (
                                <CircularProgress size={24} />
                              ) : (
                                "Submit Feedback"
                              )}
                            </Button>
                          </Box>
                        </Popover>
                      </Box>
                    ) : (
                      <Typography
                        sx={{
                          fontSize: "1.2rem",
                          lineHeight: 1.5,
                          padding: 2,
                          backgroundColor: "white",
                          borderRadius: 2,
                          display: "flex",
                          flexWrap: "wrap",
                          gap: 2,
                          boxShadow: 3,
                          transition: "0.3s", // Smooth hover effect
                          "&:hover": {
                            backgroundColor: "#f9f9f9", // Change background on hover
                          },
                        }}
                      >
                        <Box display="flex" alignItems="center" gap={1}>
                          <MeetingRoomIcon
                            sx={{ marginRight: 1, fontSize: "0.9rem" }}
                          />{" "}
                          {/* Event icon */}
                          <strong style={{ fontSize: "1rem" }}>
                            {item.meetingName}
                          </strong>
                        </Box>
                        <Box
                          display="flex"
                          alignItems="center"
                          marginRight={1}
                          gap={1}
                        >
                          <InfoIcon sx={{ marginRight: 0, fontSize: "1rem" }} />{" "}
                          <span
                            style={{ fontSize: "1rem", fontSize: "0.9rem" }}
                          >
                            <em style={{ fontSize: "1rem" }}>
                              {item.meetingAgenda}
                            </em>
                          </span>
                        </Box>
                        <Box display="flex" alignItems="center" gap={1}>
                          <TimelapseIcon
                            sx={{ marginRight: 0.5, fontSize: "0.9rem" }}
                          />{" "}
                          {/* Clock icon */}
                          <strong
                            style={{ marginRight: 0.5, fontSize: "0.9rem" }}
                          >
                            {item.meetingDuration} min
                          </strong>
                        </Box>
                        <Box display="flex" alignItems="center" gap={1}>
                          <HourglassBottomIcon
                            sx={{ marginRight: 0.5, fontSize: "0.9rem" }}
                          />{" "}
                          <strong style={{ fontSize: "0.9rem" }}>
                            {moment
                              .utc(item?.meetingDate)
                              .format("DD-MM-YYYY - HH:mm")}
                          </strong>
                        </Box>
                        <Box display="flex" alignItems="center" gap={1}>
                          <PersonPinIcon
                            sx={{ marginRight: 0, fontSize: "0.9rem" }}
                          />{" "}
                          <strong style={{ fontSize: "0.9rem" }}>
                            {getEmployeeName(item.meetingBy)}
                          </strong>
                        </Box>
                        <Box display="flex" alignItems="center" gap={1}>
                          <LinkIcon
                            sx={{
                              marginRight: 0,
                              fontSize: "1.2rem",
                              color: "green",
                            }}
                          />
                          <strong
                            style={{
                              fontSize: "0.9rem",
                              overflow: "hidden", // Hide overflow text
                              whiteSpace: "nowrap", // Prevent text from wrapping
                              textOverflow: "ellipsis", // Show ellipsis for overflow
                              maxWidth: "300px", // Set a max width for the link container
                              display: "block", // Make it a block element
                            }}
                          >
                            <a
                              href={item.meetingLink}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {item.meetingLink}
                            </a>
                          </strong>
                        </Box>
                      </Typography>
                    )}
                  </Box>
                ))
              ) : (
                <Typography>No events or daily work found.</Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default Calender;
