import React from "react";
import { useAuth } from "../../middlewares/auth";
import { useGet, usePost } from "../../helpers/axios/useApi";
import { Grid } from "@mui/system";
import { CircularProgress, IconButton } from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';  // Approve icon
import CancelIcon from '@mui/icons-material/Cancel';  // Reject icon
const LeavePopover = ({ data, leaves, refetch }) => {
    if (!data) return null;
    const {user} = useAuth() 
    const selectedLeave = leaves.filter((leave) => leave.status === data.status);
    const [loading, setLoading] = React.useState(null);
    const {
      data: employees,
      refetch: refetchEmployees,
      isLoading,
    } = useGet("employee/all", {}, {}, { queryKey: "employees" });
  
    // Log to ensure correct data
    console.log(selectedLeave, 'selectedLeave');
    const handleUpdateLeave = usePost("/employee/leave/update");
    const getEmployeeName = (id) => {
      if (!employees?.data?.message || !Array.isArray(employees?.data?.message[0])) {
        return "Unknown";
      }
  
      const employee = employees?.data?.message[0].find((emp) => emp._id === id);
      return employee ? `${employee.name}` : "Unknown";
    };
    const handleActionLeave = async (_id, action) => {
      try{
      setLoading({
        id: _id,
        action: action
      });
      const leaveDetails = {
        _id,
        status: action
      };
      const res = await handleUpdateLeave.mutateAsync(leaveDetails); // Use leaveDetails, not just leaveData
     
      if (res.data !== null) {
        setLoading(null);
        refetch();
      } else {
        setLoading(null);
      }
    } catch (error) {
      setLoading(null);
      console.error("Error submitting leave request:", error);
    }
  
    }
    return (
      <div id="popover"
        style={{
          position: "absolute",
  
          top: "45%",
          left: "83%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "#fff",
          width: "250px",  // Adjusted width to be smaller
          maxHeight: "280px",  // Maximum height fixed at 280px
          color: "#333",
          padding: "12px",
          borderRadius: "20px",
          boxShadow: "0 4px 12px rgba(0, 0, 0, 0.2)",
          zIndex: 10,
          fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
          overflowY: "auto", // Allows scrolling if content exceeds height
        }}
      >
        {selectedLeave?.map((leave) => (
          <div
            key={leave._id}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
              marginBottom: "12px", // Reduced margin for a smaller card
              padding: "12px",  // Reduced padding
              borderRadius: "8px",
              backgroundColor: "#f4f6f9",
              boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
              fontSize: "14px",
              fontWeight: "400",
              transition: "transform 0.2s, box-shadow 0.2s",
            }}
          >
            <div style={{ flex: 1 }}>
              <div
                style={{
                  fontSize: "12px",
                  fontWeight: "600",
                  color: "#333",
                  marginBottom: "5px",
                }}
              >
                {getEmployeeName(leave.employeeId)} {/* Employee Name */}
              </div>
  
              
            </div>
  
            <div
              style={{
                marginLeft: "12px", // Reduced margin for the right side
                textAlign: "right",
                flexShrink: 0,
              }}
            >
              <div
                style={{
                  fontSize: "12px",  // Reduced font size
                  color: "#444",
                  marginBottom: "5px",
                  fontWeight: "bold",
                }}
              >
                {new Date(leave.date).toLocaleDateString()} {/* Format the date */}
              </div>
  
              <div
                style={{
                  fontSize: "12px",  // Reduced font size
                  color: "#444",
                  display: "flex",
                  gap: "5px",
                }}
              >
                <strong>Status:</strong>
                <span
                  style={{
                    color: leave.status === "Rejected" ? "#d9534f" : "#5bc0de",
                    fontWeight: "500",
                  }}
                >
                  {leave.status}
                </span>
              </div>
            </div>
            {leave.employeeId !== user._id && leave.status === "Pending" ? (
               <Grid item xs={2}>
               <Grid container spacing={1} justifyContent="center">
                 {/* Approve button */}
                 <Grid item>
                 {loading && loading?.id === leave._id && loading?.action === 'Approved' ? (
                     <CircularProgress size={30} />
                   ):(
                     <IconButton
                     color="success"
                     sx={{
                       padding: 0.2,
                       boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",  // 3D shadow on icon button
                       transition: "all 0.3s ease-in-out",
                       "&:active": {
                         transform: "scale(0.95)",  // Slight scale on click
                       },
                     }}
                     aria-label="approve"
                     onClick={() => handleActionLeave(leave._id, 'Approved')}  // Placeholder function for approval action
                   >
                     <CheckCircleIcon sx={{ fontSize: 30 }} />
                   </IconButton>
                   )}
                 
                 </Grid>
       
                 {/* Reject button */}
                 <Grid item>
                   {loading && loading?.id === leave._id && loading?.action === 'Rejected' ? (
                     <CircularProgress size={30} />
                   ):(
                     <IconButton
                     color="error"
                     sx={{
                       padding: 0.2,
                       boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",  // 3D shadow on icon button
                       transition: "all 0.3s ease-in-out",
                       "&:active": {
                         transform: "scale(0.95)",  // Slight scale on click
                       },
                     }}
                     aria-label="reject"
                     onClick={() => handleActionLeave(leave._id, 'Rejected')}  // Placeholder function for rejection action
                   >
                     <CancelIcon sx={{ fontSize: 30 }} />
                   </IconButton>
                   )}
         
                 </Grid>
               </Grid>
             </Grid>
        ) : (null)}
   
          </div>
          
        ))}
      </div>
    );
  };

  export default LeavePopover