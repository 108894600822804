import React from "react";
import ReactDOM from "react-dom/client";
import { ThemeProvider } from "./theme/themeProvider.js";
import App from "./App.js";
import "./App.css";
import { QueryClient, QueryClientProvider } from "react-query";
import store from "./redux/store.js";
import { Provider } from "react-redux";
import { AuthProvider } from "./middlewares/auth/authContext.js";
import { BrowserRouter as Router } from "react-router-dom"; 

// Create a new instance of QueryClient
const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root"));

// Render the app within the root.
root.render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <ThemeProvider>
        <Router>
          <AuthProvider>
            <App />
          </AuthProvider>
        </Router>
      </ThemeProvider>
    </QueryClientProvider>
  </Provider>
);
