import React, { useState } from "react";
import {
  TextField,
  Button,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Box,
  Card,
  CardContent,
  Snackbar,
  Alert,
} from "@mui/material";
import { BiSave } from "react-icons/bi";
import { useAuth } from "../../../../middlewares/auth";
import { usePost } from "../../../../helpers/axios/useApi";

const AddPaymentForm = ({ leadDetail ,paymentAdded}) => {
  const user = useAuth();
  const [formData, setFormData] = useState({
    leadId: "",
    transactionId: "",
    amount: "",
    paymentDate: "",
    paymentMethod: "",
    currency: "USD", // Default to USD
    taxPercent: 0,
    discountPercent: 0,
    paymentNotes: "",
    senderAccountNumber: "",
    receiverAccountNumber: "",
    customCurrency: "", // To store custom currency input
    serviceName: "",
  });

  const [errors, setErrors] = useState({});
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success"); // 'error' or 'success'

  const handleSubmitPayment = usePost("/leads/payment/create");

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Validate form
  const validateForm = () => {
    const newErrors = {};
    if (!formData.transactionId) newErrors.transactionId = "Transaction ID is required.";
    if (!formData.amount || formData.amount <= 0) newErrors.amount = "Amount is required and must be greater than zero.";
    if (!formData.paymentDate) newErrors.paymentDate = "Payment date is required.";
    if (!formData.paymentMethod) newErrors.paymentMethod = "Payment method is required.";
    if (!formData.senderAccountNumber) newErrors.senderAccountNumber = "Sender account number is required.";
    if (!formData.receiverAccountNumber) newErrors.receiverAccountNumber = "Receiver account number is required.";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      // If the form is valid, call the API to send the data
      const data = {
        ...formData,
        leadId: leadDetail._id,
        processedBy: user?._id,
      };

      try {
        const response = await handleSubmitPayment.mutateAsync(data);
        console.log(response?.error, "response");
        // If successful
        if (response?.data) {
          setSnackbarSeverity("success");
          setSnackbarMessage("Payment saved successfully!");
          paymentAdded()
            // Clear form after submission (optional)
      setFormData({
        leadId: "",
        transactionId: "",
        amount: "",
        paymentDate: "",
        paymentMethod: "",
        currency: "USD",
        taxPercent: 0,
        discountPercent: 0,
        paymentNotes: "",
        senderAccountNumber: "",
        receiverAccountNumber: "",
        customCurrency: "",
        serviceName: "",
      });
        }
        else {
          setSnackbarSeverity("error");
          setSnackbarMessage(response?.error);
        }
      } catch (error) {
        // If error occurs
        setSnackbarSeverity("error");
        setSnackbarMessage("Failed to save payment.");
      }

    

      // Show Snackbar
      setOpenSnackbar(true);
    }
  };

  // Handle closing the Snackbar
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <>
    <Card sx={{ width: "100%", padding: 2, boxShadow: 3 }}>
      <CardContent>
        <Typography variant="h5" fontWeight="bold" gutterBottom>
          Add Payment
        </Typography>

        <form onSubmit={handleSubmit}>

        <Grid item xs={12} md={6} mb={2}>
              <TextField
                label="Service Name"
                
                fullWidth
                name="serviceName"
                value={formData.serviceName}
                onChange={handleChange}
                error={!!errors.serviceName}
                helperText={errors.serviceName}
              />
            </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                label="Transaction ID"
                fullWidth
                name="transactionId"
                value={formData.transactionId}
                onChange={handleChange}
                error={!!errors.transactionId}
                helperText={errors.transactionId}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                label="Amount"
                type="number"
                fullWidth
                name="amount"
                value={formData.amount}
                onChange={handleChange}
                error={!!errors.amount}
                helperText={errors.amount}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                label="Payment Date"
                type="date"
                fullWidth
                name="paymentDate"
                value={formData.paymentDate}
                onChange={handleChange}
                error={!!errors.paymentDate}
                helperText={errors.paymentDate}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl fullWidth error={!!errors.paymentMethod}>
                <InputLabel>Payment Method</InputLabel>
                <Select
                  label="Payment Method"
                  name="paymentMethod"
                  value={formData.paymentMethod}
                  onChange={handleChange}
                >
                  <MenuItem value="Credit Card">Credit Card</MenuItem>
                  <MenuItem value="Bank Transfer">Bank Transfer</MenuItem>
                  <MenuItem value="PayPal">PayPal</MenuItem>
                  <MenuItem value="Cash">Cash</MenuItem>
                  <MenuItem value="Cash">UPI Transfer</MenuItem>
                  <MenuItem value="Other">Other</MenuItem>
                </Select>
                {errors.paymentMethod && <FormHelperText>{errors.paymentMethod}</FormHelperText>}
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                label="Tax (%)"
                type="number"
                fullWidth
                name="taxPercent"
                value={formData.taxPercent}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                label="Discount (%)"
                type="number"
                fullWidth
                name="discountPercent"
                value={formData.discountPercent}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                label="Sender Account Number"
                fullWidth
                name="senderAccountNumber"
                value={formData.senderAccountNumber}
                onChange={handleChange}
                error={!!errors.senderAccountNumber}
                helperText={errors.senderAccountNumber}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                label="Receiver Account Number"
                fullWidth
                name="receiverAccountNumber"
                value={formData.receiverAccountNumber}
                onChange={handleChange}
                error={!!errors.receiverAccountNumber}
                helperText={errors.receiverAccountNumber}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl fullWidth error={!!errors.currency}>
                <InputLabel>Currency</InputLabel>
                <Select
                  label="Currency"
                  name="currency"
                  value={formData.currency}
                  onChange={handleChange}
                >
                  <MenuItem value="USD">USD</MenuItem>
                  <MenuItem value="EUR">EUR</MenuItem>
                  <MenuItem value="GBP">GBP</MenuItem>
                  <MenuItem value="INR">INR</MenuItem>
                  <MenuItem value="AUD">AUD</MenuItem>
                  {/* Add more currencies as needed */}
                </Select>
                {errors.currency && <FormHelperText>{errors.currency}</FormHelperText>}
              </FormControl>
            </Grid>

            {formData.currency === "Custom" && (
              <Grid item xs={12} md={6}>
                <TextField
                  label="Custom Currency Code"
                  fullWidth
                  name="customCurrency"
                  value={formData.customCurrency}
                  onChange={handleChange}
                />
              </Grid>
            )}

            <Grid item xs={12}>
              <TextField
                label="Notes"
                multiline
                rows={4}
                fullWidth
                name="paymentNotes"
                value={formData.paymentNotes}
                onChange={handleChange}
              />
            </Grid>
          </Grid>

          <Box sx={{ display: "flex", justifyContent: "flex-end", marginTop: 2 }}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              startIcon={<BiSave />}
              sx={{ fontWeight: "bold" }}
            >
              Save Payment
            </Button>
          </Box>
        </form>
      </CardContent>
    </Card>

    {/* Snackbar for success/error messages */}
    <Snackbar
      open={openSnackbar}
      autoHideDuration={6000}
      onClose={handleCloseSnackbar}
    >
      <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: "100%" }}>
        {snackbarMessage}
      </Alert>
    </Snackbar>
    </>
  );
};

export default AddPaymentForm;
