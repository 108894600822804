import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
} from "@mui/material";
import { BiCloudDownload } from "react-icons/bi";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import SendAndArchiveIcon from '@mui/icons-material/SendAndArchive';
import { server_url } from "../../../../utils/server";
import CryptoJS from "crypto-js";
import axios from "axios";
const InvoiceModal = ({
  showModal,
  closeModal,
  info,
  currency,
  total,
  items,
  taxAmount,
  discountAmount,
  subTotal,
  header,
  logo,
  currentDate,
  leadId,
  paymentId,
}) => {
  const itemKeys = items.length > 0 ? Object.keys(items[0]) : [];
  console.log(items, "items");

  const formatCurrency = (value) => {
    if (typeof value === "number") {
      return `${currency} ${value.toFixed(2)}`;
    }
    return value;
  };

  const [loading, setLoading] = React.useState(null);

  const GenerateInvoice = async (actionType) => {
    try {
      setLoading({
        actionType: actionType,
      }); // Show loading spinner
  
      // Step 1: Capture the HTML content as an image using html2canvas
      console.log("Capturing HTML to canvas...");
      const canvas = await html2canvas(document.querySelector("#invoiceCapture"), {
        scale: 2, // Improve the resolution of the captured content
      });
  
      const imgData = canvas.toDataURL("image/png", 1.0); // Convert canvas to image
      console.log("Image data captured");
  
      // Step 2: Initialize jsPDF
      const pdf = new jsPDF({
        unit: "pt",
        format: "a4", // Use A4 format for the PDF
      });
  
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
  
      // Step 3: Add the captured image to the PDF
      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
  
      // Step 4: Prepare the PDF Blob (output as Blob for upload)
      const pdfBlob = pdf.output("blob"); // Use blob for uploading
      console.log("Generated PDF Blob", pdfBlob);
  
      // Check if the Blob is valid and not empty
      if (pdfBlob.size === 0) {
        throw new Error("Generated PDF file is empty.");
      }
  
      // Step 5: Prepare FormData for uploading the PDF
      const formData = new FormData();
      formData.append("file", pdfBlob, "invoice-001.pdf");
      formData.append("send", actionType === "save" ? "false" : "true");
      formData.append("leadId", leadId);
  
      // Ensure date is correctly formatted
      const formattedDate = new Date(currentDate); // Convert date to ISO string format
      formData.append("invoiceDate", formattedDate);
  
      formData.append("invoiceNumber", info.invoiceNumber);
      formData.append("paymentId", paymentId);
  
      // Debug log the FormData content
      console.log("FormData content:");
      for (let pair of formData.entries()) {
        console.log(pair[0] + ": " + pair[1]);
      }
  
      // Step 6: Get the auth token and decrypt it
      const tokenDate = localStorage.getItem("authToken");
      console.log("Auth Token from LocalStorage:", tokenDate);
  
      const decryptData = (encryptedData) => {
        const secretKey = process.env.REACT_APP_SECRET_KEY
        try {
          const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
          const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
          
          // If decryption failed (empty string), return null
          if (!decryptedData) {
            throw new Error('Decryption failed or malformed data');
          }
  
          return JSON.parse(decryptedData); // Assuming the token is a JSON string
        } catch (error) {
          console.error('Decryption Error:', error);
          return null;
        }
      };
      const token = decryptData(tokenDate);
      if (!token) {
        throw new Error("Invalid or missing auth token.");d
      }
  
      console.log("Decrypted token:", token);
  
      // Step 7: Configure axios request with Authorization header
      const config = {
        headers: {
          "Content-Type": "multipart/form-data", // Ensure the proper content type is set
          Authorization: `Bearer ${token}`, // Add the Authorization token
        },
      };
  
      // Step 8: Debug the request payload
      console.log("Axios Config:", config);
  
      // Step 9: Upload the invoice to the server
      const response = await axios.post(
        `${server_url}/leads/payment/invoice-save-and-send`,
        formData,
        config
      );
  
      console.log("Server response:", response); // Log the server response for debugging
      alert("File uploaded successfully!"); // Success message
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error generating or uploading the invoice:", error?.response?.data?.error);
      alert(error?.response?.data?.error); // Error message
    } 
  };
  

  return (
    <Dialog
      open={showModal}
      onClose={closeModal}
      maxWidth="lg"
      fullWidth
      sx={{ backdropFilter: "blur(6px)", padding: "16px" }}
      PaperProps={{ sx: { width: "90vh" } }}
    >
      <div id="invoiceCapture">
        <DialogTitle
          sx={{
            background: "#3f51b5",
            color: "white",
            padding: 0,
          }}
        >
          <div className="d-flex justify-content-between">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "5vh",
              }}
            >
              {logo && (
                <img
                  src={logo}
                  alt="Logo"
                  style={{ width: 50, marginBottom: 0 }}
                />
              )}
              <Typography
                variant="h5"
                fontWeight="bold"
                sx={{
                  fontSize: "14px",
                  fontFamily: "Arial",
                  color: "white",
                }}
              >
                {info.billFrom || "John Uberbacher"}
              </Typography>
            </div>
          </div>
        </DialogTitle>

        <div
          className="p-4"
          style={{
            backgroundColor: "#ffffff",
            borderRadius: "8px",
          }}
        >
          {/* Billing Information Table */}
          <Table sx={{ marginTop: 2 }}>
            <TableHead>
              <TableRow sx={{ backgroundColor: "#f5f5f5", width: "100%" }}>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    textAlign: "left",
                    border: "1px solid black",
                  }}
                  colSpan={2}
                >
                  Invoice Date : {currentDate}
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    textAlign: "left",
                    border: "1px solid black",
                  }}
                  colSpan={2}
                >
                  Invoice Number: {info.invoiceNumber}
                </TableCell>
              </TableRow>
              <TableRow sx={{ backgroundColor: "#f5f5f5", width: "100%" }}>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    textAlign: "center",
                    border: "1px solid black",
                  }}
                  colSpan={4}
                >
                  Billing Information
                </TableCell>
              </TableRow>
              <TableRow sx={{ backgroundColor: "#f5f5f5" }}>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    textAlign: "center",
                    border: "1px solid black",
                  }}
                  colSpan={2}
                >
                  Billing From Address
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    textAlign: "center",
                    border: "1px solid black",
                  }}
                  colSpan={2}
                >
                  Billing To Address
                </TableCell>
              </TableRow>
            </TableHead>

            {/* Table Body */}
            <TableBody>
              {/* Billing Address */}
              <TableRow sx={{ backgroundColor: "#f5f5f5", border: "1px solid black" }}>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    textAlign: "center",
                    border: "1px solid black",
                  }}
                >
                  Name
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "400",
                    textAlign: "center",
                    border: "1px solid black",
                  }}
                >
                  {info.billFrom || "John Uberbacher"}
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    textAlign: "center",
                    border: "1px solid black",
                  }}
                >
                  Name
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "400",
                    textAlign: "center",
                    border: "1px solid black",
                  }}
                >
                  {info.billTo || "Jane Doe"}
                </TableCell>
              </TableRow>
              <TableRow sx={{ backgroundColor: "#f5f5f5", border: "1px solid black" }}>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    textAlign: "center",
                    border: "1px solid black",
                  }}
                >
                  Email
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "400",
                    textAlign: "center",
                    border: "1px solid black",
                  }}
                >
                  {info.billFromEmail || "john@example.com"}
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    textAlign: "center",
                    border: "1px solid black",
                  }}
                >
                  Email
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "400",
                    textAlign: "center",
                    border: "1px solid black",
                  }}
                >
                  {info.billToEmail || "jane@example.com"}
                </TableCell>
              </TableRow>
              <TableRow sx={{ backgroundColor: "#f5f5f5", border: "1px solid black" }}>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    textAlign: "center",
                    border: "1px solid black",
                  }}
                >
                  Phone
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "400",
                    textAlign: "center",
                    border: "1px solid black",
                  }}
                >
                  {info.billFromPhone || "john@example.com"}
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    textAlign: "center",
                    border: "1px solid black",
                  }}
                >
                  Phone
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "400",
                    textAlign: "center",
                    border: "1px solid black",
                  }}
                >
                  {info.billToPhone || "jane@example.com"}
                </TableCell>
              </TableRow>
              <TableRow sx={{ backgroundColor: "#f5f5f5", border: "1px solid black" }}>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    textAlign: "center",
                    border: "1px solid black",
                  }}
                >
                  Address
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "400",
                    textAlign: "center",
                    border: "1px solid black",
                  }}
                >
                  {info.billFromAddress || "john@example.com"}
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    textAlign: "center",
                    border: "1px solid black",
                  }}
                >
                  Address
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "400",
                    textAlign: "center",
                    border: "1px solid black",
                  }}
                >
                  {info.billToAddress || "jane@example.com"}
                </TableCell>
              </TableRow>
              <TableRow sx={{ backgroundColor: "#f5f5f5", border: "1px solid black" }}>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    textAlign: "center",
                    border: "1px solid black",
                  }}
                >
                  GST Number
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "400",
                    textAlign: "center",
                    border: "1px solid black",
                  }}
                >
                  {info.billFromGst || "john@example.com"}
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    textAlign: "center",
                    border: "1px solid black",
                  }}
                >
                  GST Number
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "400",
                    textAlign: "center",
                    border: "1px solid black",
                  }}
                >
                  {info.billToGst || "jane@example.com"}
                </TableCell>
              </TableRow>
              
              {/* Other rows for GST and Address */}
            </TableBody>
          </Table>

          {/* Invoice Items Table */}
          <TableContainer sx={{ borderRadius: 3, mt: 2, width: "60vh", background: "transparent" }}>
            <Table>
              <TableHead>
                <TableRow>
                  {header.map((key) => (
                    <TableCell
                      key={key.id}
                      sx={{
                        fontWeight: "bold",
                        textAlign: "center",
                        border:
                          key?.label === "ACTION" || key?.label === "Serial"
                            ? "none"
                            : "1px solid black",
                      }}
                    >
                      {key.label === "ACTION" || key.label === "Serial" ? null : key.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {items.map((item, i) => (
                  <TableRow key={i} sx={{ "&:hover": { backgroundColor: "#f5f5f5" } }}>
                    {itemKeys.map((key) => {
                      return (
                        <TableCell
                          key={key}
                          sx={{
                            textAlign: "center",
                            border:
                              key?.label === "ACTION" || key === "id"
                                ? "none"
                                : "1px solid black",
                          }}
                        >
                          {key === "action" || key === "id" ? null : formatCurrency(item[key])}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          {/* Summary Table */}
          <Table sx={{ marginTop: 2, marginRight: 5, width: "85%", ml: 5, border: "1px solid black" }}>
            <TableBody sx={{ width: "30vh", mr: 5, ml: 5, border: "1px solid black" }}>
              <TableRow sx={{ border: "1px solid black" }}>
                <TableCell colSpan={2}></TableCell>
                <TableCell align="right" fontWeight="bold" sx={{ fontWeight: "bold" }}>
                  TOTAL
                </TableCell>
                <TableCell align="right" sx={{ fontWeight: "bold" }}>
                  {currency} {subTotal}
                </TableCell>
              </TableRow>
              {taxAmount !== 0.0 && (
                <TableRow sx={{ border: "1px solid black" }}>
                  <TableCell colSpan={2}></TableCell>
                  <TableCell align="right" fontWeight="bold" sx={{ fontWeight: "bold" }}>
                    TAX
                  </TableCell>
                  <TableCell align="right" sx={{ fontWeight: "bold" }}>
                    {currency} {taxAmount}
                  </TableCell>
                </TableRow>
              )}
              {discountAmount !== 0.0 && (
                <TableRow sx={{ border: "1px solid black" }}>
                  <TableCell colSpan={2}></TableCell>
                  <TableCell align="right" fontWeight="bold" sx={{ fontWeight: "bold" }}>
                    DISCOUNT
                  </TableCell>
                  <TableCell align="right" sx={{ fontWeight: "bold" }}>
                    {currency} {discountAmount}
                  </TableCell>
                </TableRow>
              )}
              <TableRow sx={{ border: "1px solid black" }}>
                <TableCell colSpan={2}></TableCell>
                <TableCell align="right" fontWeight="bold" sx={{ fontWeight: "bold" }}>
                  SUBTOTAL
                </TableCell>
                <TableCell align="right" sx={{ fontWeight: "bold" }}>
                  {currency} {total}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>

          {info.notes && (
            <Typography
              variant="body2"
              sx={{
                marginTop: 2,
                backgroundColor: "#f5f5f5",
                padding: 2,
                borderRadius: 1,
              }}
            >
              {info.notes}
            </Typography>
          )}

          {info.tearmAndCondition && (
            <Grid>
              <Typography
                variant="body2"
                sx={{
                  marginTop: 2,
                  backgroundColor: "#f5f5f5",
                  padding: 2,
                  borderRadius: 1,
                  fontWeight: "bold",
                }}
              >
                Term And Condition: {info.tearmAndCondition}
              </Typography>
            </Grid>
          )}
        </div>
      </div>

      <DialogActions sx={{ padding: 2 }}>
        <Grid container spacing={2}>
        <Grid container spacing={2}>
      {/* Save Invoice Button */}
      <Grid item xs={12} md={4}>
        <Button
          variant="contained"
          fullWidth
          startIcon={<BiCloudDownload />}
          onClick={() => GenerateInvoice("save")} // Save only
          disabled={loading} // Disable button when loading
          sx={{
            fontWeight: "bold",
            textTransform: "none",
            backgroundColor: "#3f51b5",
            "&:hover": {
              backgroundColor: "#303f9f",
            },
          }}
        >
          {loading?.actionType === "save" ? <CircularProgress size={24} color="inherit" /> : "Save Invoice"}
        </Button>
      </Grid>

      {/* Save & Send Invoice Button */}
      <Grid item xs={12} md={4}>
        <Button
          variant="contained"
          fullWidth
          startIcon={<SendAndArchiveIcon />}
          onClick={() => GenerateInvoice("send")} // Save & send
          disabled={loading} // Disable button when loading
          sx={{
            fontWeight: "bold",
            textTransform: "none",
            backgroundColor: "#3f51b5",
            "&:hover": {
              backgroundColor: "#303f9f",
            },
          }}
        >
          {loading?.actionType === "send" ? <CircularProgress size={24} color="inherit" /> : "Save & Send Invoice"}
        </Button>
      </Grid>
    </Grid>
       
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default InvoiceModal;
