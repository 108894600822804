import React from "react";
import { TextField, InputAdornment } from "@mui/material";

const EditableField = ({ cellData, onItemizedItemEdit,disabled }) => {


  return (
    <TextField
      fullWidth
      disabled={disabled}
      variant="outlined"
      className={cellData.textAlign}
      type={cellData.type}
      placeholder={cellData.placeholder}
      min={cellData.min}
      name={cellData.name}
      id={cellData.id}
      value={cellData.value}
      step={cellData.step}
      precision={cellData.precision}
      aria-label={cellData.name}
      onChange={onItemizedItemEdit}
      required
      InputProps={{
        startAdornment:
          cellData.leading != null ? (
            <InputAdornment position="start">
              <span
                style={{
                  width: "24px",
                  height: "24px",
                  borderRadius: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  border: "2px solid #6c757d", // border color similar to Bootstrap's secondary
                  backgroundColor: "#f8f9fa", // light background
                  color: "#6c757d", // secondary text color
                  fontSize: "small",
                  transition: "all 0.3s ease", // Smooth transition
                }}
              >
                {cellData.leading}
              </span>
            </InputAdornment>
          ) : null,
      }}
      sx={{
        "& .MuiOutlinedInput-root": {
          borderRadius: "12px", // Rounded corners for modern design
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", // 3D shadow effect
          transition: "all 0.3s ease", // Smooth transition for focus and hover
         
          "&:hover": {
            boxShadow: "0 6px 10px rgba(0, 0, 0, 0.2)", // Hover effect
          },
          "&.Mui-focused": {
            boxShadow: "0 6px 12px rgba(0, 0, 0, 0.3)", // Focused state with stronger shadow
            borderColor: "#3f51b5", // Color when focused (optional)
          },
        },
        "& .MuiInputBase-input": {
          fontSize: "1rem", // Larger font size for better readability
          fontWeight: "500", // Slightly bolder text for emphasis
          padding: "12px 16px", // Add padding for better input field aesthetics
        },
      }}
    />
  );
};

export default EditableField;
