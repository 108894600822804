import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import PuchInData from "./Leaves";
import { Grid } from "@mui/system";
import { useGet } from "../../../helpers/axios/useApi";
import { DateTime } from "luxon";
import HRMDashboard from "./HRMDashboard";
import GlassEffect from "../../../theme/glassEffect";
import { Paper, Typography } from "@mui/material";
import { useTheme } from "@emotion/react";
import MainDashboard from "../../../pages/DashBoard";
const currentYear = new Date().getFullYear();
const currentMonth = new Date().getMonth();
function DashBoard({ employees }) {
  const [employeesData, setEmployeeData] = useState({}); // Employee attendance data
  const [selectedMonth, setSelectedMonth] = useState(currentMonth); // Default to October
  const [selectedYear, setSelectedYear] = useState(currentYear); // Default to 2024
  const [employeeIds, setEmployeeIds] = useState([]);
  const [leaves, setLeave] = useState([]);
  const { data: leave, refetch } = useGet(
    "employee/leave/get-all-by-month",
    {
      month: selectedMonth + 1,
      year: selectedYear,
    },
    {},
    { queryKey: "leave" }
  );

  useEffect(() => {
    if (leave?.data?.data) {
      setLeave(leave?.data?.data);
    }
  }, [leaves]);

  // API Call to fetch daily records and policy data
  const { data: dailyRecords, error: recordsError } = useGet(
    "employee/work-tracking/daily-records-multipal",
    {
      userId: employeeIds?.join(","),
      startDate: DateTime.fromObject({
        year: selectedYear,
        month: selectedMonth + 1,
        day: 1,
      }).toISODate(),
      endDate: DateTime.fromObject({
        year: selectedYear,
        month: selectedMonth + 1,
      })
        .endOf("month")
        .toISODate(),
    },
    { enabled: employeeIds?.length > 0 }, // Only make the call when employeeIds is not empty
    { queryKey: "dailyRecords" }
  );

  const { data: policyData, error: policyError } = useGet(
    "company/policy/attendece-get",
    { employeeId: employeeIds?.join(",") },
    { enabled: employeeIds?.length > 0 },
    { queryKey: "policyData" }
  );

  // Deep merge function for handling nested objects and arrays
  const deepMerge = (target, source) => {
    if (
      target &&
      source &&
      typeof target === "object" &&
      typeof source === "object"
    ) {
      Object.keys(source).forEach((key) => {
        if (Array.isArray(target[key]) && Array.isArray(source[key])) {
          target[key] = [...target[key], ...source[key]];
        } else if (
          typeof target[key] === "object" &&
          typeof source[key] === "object"
        ) {
          target[key] = deepMerge({ ...target[key] }, source[key]);
        } else {
          target[key] = source[key];
        }
      });
    }
    return target;
  };

  const fetchEmployeeData = async () => {
    if (recordsError || policyError) {
      return;
    }

    const employeeData = policyData?.data?.data || [];
    const attendanceData = dailyRecords?.data?.data?.employees || [];

    // Merge employee data with attendance data
    const mergedData = employeeData.map((employee) => {
      const attendance = attendanceData.find(
        (att) => att.userId === employee.employeeId
      );

      // Merge the employee data with the attendance (currentStatus and records)
      const mergedEmployeeData = deepMerge(
        { ...employee },
        {
          currentStatus: attendance?.currentStatus || {
            isPunchedIn: false,
            punchIn: null,
          },
          records: attendance?.records || [], // Merge the attendance records
          workingDays: attendance?.workingDays || {}, // Merge workingDays (0-6)
        }
      );

      return mergedEmployeeData;
    });
    // Set merged data into state
    setEmployeeData(mergedData);
  };
  // Fetch data for the current page's employee IDs
  useEffect(() => {
    if (employeeIds?.length > 0) {
      fetchEmployeeData(); // Fetch employee data if employee IDs are available
    }
  }, [employeeIds, dailyRecords, policyData]);

  // Update employeeIds based on the current page and rowsPerPage
  useEffect(() => {
    const newEmployeeIds = employees?.data?.message[0]?.map(
      (employee) => employee._id
    );
    setEmployeeIds(newEmployeeIds); // Set employee IDs to fetch data for the current page
  }, [employees]);



  const Holiday = [
    { id: 1, date: "Nov 8, 2024", day: "Friday", name: "Diwali" },
    { id: 2, date: "Dec 25, 2024", day: "Wednesday", name: "Christmas" },
  ];
  

  return (
    <>
      <Grid height={"80vh"} width={"100%"} overflow={'auto'}>
        <Grid  sx={{  width:"100%", display:"flex", gap:"1rem"}}>
             
             <MainDashboard employeesData={employeesData} employees={employees} leaves={leaves}/>

        </Grid>
      </Grid>
    </>
  );
}

export default DashBoard;
