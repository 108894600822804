import React, { useState, useEffect, useRef } from 'react';
import { Button, Box, Typography, CircularProgress, Paper, TextField, Snackbar, Container, IconButton } from '@mui/material';
import axios from 'axios';
import { server_url } from '../../../../utils/server'; 
import { io } from 'socket.io-client'; 
import { useAuth } from '../../../../middlewares/auth';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import MarkdownIt from 'markdown-it'; // Import markdown-it
import markdownItAnchor from 'markdown-it-anchor'; // Import anchor plugin
import markdownItToc from 'markdown-it-table-of-contents'; // Import table of contents plugin
import markdownItHighlightJs from 'markdown-it-highlightjs'; // Import syntax highlighting plugin
import markdownItFootnote from 'markdown-it-footnote'; // Import footnotes plugin
import markdownItTable from 'markdown-it-table-of-contents'; // Import table plugin
import { usePost } from '../../../../helpers/axios/useApi';

const ProposalGenerator = ({leadId,added}) => {
    const { user } = useAuth();
    const userId = user?._id;
    const socket = useRef(null);
    const [proposal, setProposal] = useState('');
    const [loading, setLoading] = useState(false);
    const [dots, setDots] = useState('');
    const [isDone, setIsDone] = useState(false);
    const [prompt, setPrompt] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [isEditing, setIsEditing] = useState(false); // Edit mode state
    const [parsedContent, setParsedContent] = useState(''); // Parsed HTML content
    const handleSaveProposal = usePost('/leads/proposal/create')
    // Initialize markdown-it with plugins
    const md = new MarkdownIt({ html: true, linkify: true })
        .use(markdownItAnchor)  // Enable anchor plugin for headers
        .use(markdownItToc, { 
            includeLevel: [1, 2, 3],  // Only include h1, h2, and h3
            containerClass: 'table-of-contents', // Customize TOC class
            slugify: (s) => encodeURIComponent(String(s).trim().toLowerCase().replace(/\s+/g, '-')), // Custom slugify
            markerPattern: /^\[\[toc\]\]/im, // Define marker pattern for TOC
            listType: 'ul', // Use unordered list for TOC
            format: (content, md) => md.renderInline(content), // Use inline markdown rendering
        })
        .use(markdownItHighlightJs)  // Enable syntax highlighting
        .use(markdownItFootnote)  // Enable footnotes support
        .use(markdownItTable);  // Enable table plugin

    // Effect to handle socket connection
    useEffect(() => {
        socket.current = io("http://localhost:6500");
        socket.current.emit("joinRoom", userId);

        socket.current.on("proposalProgress", (proposalText) => {
            setProposal((prevProposal) => prevProposal + proposalText);
            const markdownHTML = md.render(proposalText); // Render Markdown
            setParsedContent(markdownHTML); // Store parsed content
        });

        socket.current.on("proposalComplete", (finalProposal) => {
            setProposal(finalProposal);
            setLoading(false);
            setIsDone(true);
            showSnackbar("Proposal generated successfully!", "success");
        });

        return () => {
            socket.current.disconnect(); // Cleanup on unmount
        };
    }, [userId]);

    useEffect(() => {
        const dotInterval = setInterval(() => {
            if (!loading) return;
            setDots((prev) => (prev.length < 3 ? prev + '.' : ''));
        }, 500);
        return () => clearInterval(dotInterval);
    }, [loading]);

    const showSnackbar = (message, severity) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };


    const saveProposal = async()=>{
          const data = {
            leadId,
            proposalData:parsedContent
          }

          const result = await handleSaveProposal.mutateAsync(data)

           added()
    }
    const generateProposal = async () => {
        if (!prompt.trim()) {
            showSnackbar('Please provide a prompt!', 'warning');
            return;
        }

        setProposal('');
        setLoading(true);
        setIsDone(false);
        setDots('');

        try {
            const response = await axios.post(`${server_url}/leads/genret-propose`, {
                prompt,
                userId,
            });

            if (response.status === 200) {
                setLoading(false);
                setIsDone(true);
                showSnackbar('Proposal generated successfully!', 'success');
            }
        } catch (error) {
            console.error('Error generating proposal:', error);
            setLoading(false);
            showSnackbar('Error generating proposal. Please try again.', 'error');
        }
    };

    const regenerateProposal = () => {
        setProposal('');
        setLoading(true);
        setIsDone(false);
        setDots('');
        generateProposal();
    };

    // Handle Edit action
    const handleEdit = () => {
        setIsEditing(true);
    };

    // Handle Save action
    const handleSave = () => {
        setIsEditing(false);
        showSnackbar('Proposal saved successfully!', 'success');
    };

    // Handle change in contentEditable div
    const handleChange = (e) => {
        setProposal(e.target.innerHTML);
    };

    return (
        <Container sx={{ padding: 0, width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <Paper elevation={3} sx={{ padding: 3, width: '110%', height: '105%' }}>
                <TextField
                    label="Enter your prompt"
                    variant="outlined"
                    fullWidth
                    value={prompt}
                    onChange={(e) => setPrompt(e.target.value)}
                    sx={{ marginBottom: 2 }}
                />

                <Box sx={{ padding: 2, border: '1px solid #ccc', borderRadius: 2, minHeight: '200px', position: 'relative', backgroundColor: '#f9f9f9' }}>
                    {loading && (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography variant="body1" component="span">
                                Generating proposal{dots}
                            </Typography>
                          
                        </Box>
                    )}
                    <Box sx={{ height: '46vh', overflow: 'auto' }}>
                        {!isEditing ? (
                            <Box
                                sx={{
                                  padding: 2,
                                  border: '1px solid #ccc',
                                  borderRadius: 1,
                                  minHeight: 200,
                                  backgroundColor: '#f9f9f9',
                                  whiteSpace: 'pre-wrap',
                                  wordWrap: 'break-word',
                                }}
                                dangerouslySetInnerHTML={{ __html: parsedContent }} // Render parsed HTML
                            />
                        ) : (
                            <Box
                                sx={{
                                  padding: 2,
                                  border: '1px solid #ccc',
                                  borderRadius: 1,
                                  minHeight: 200,
                                  backgroundColor: '#f9f9f9',
                                  whiteSpace: 'pre-wrap',
                                  wordWrap: 'break-word',
                                }}
                                contentEditable={true} // Allow editing
                                onInput={handleChange}
                                dangerouslySetInnerHTML={{ __html: parsedContent }} // Display parsed HTML
                            />
                        )}
                    </Box>
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, marginBottom: 2, mt: 2 }}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={generateProposal}
                        sx={{ paddingX: 4 }}
                    >
                        Generate Proposal
                    </Button>

                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={regenerateProposal}
                        sx={{ paddingX: 4 }}
                        disabled={loading || !isDone}
                    >
                        Regenerate
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={saveProposal}
                        sx={{ paddingX: 4 }}
                        disabled={loading || !isDone}
                    >
                        Save & Ask For Review
                    </Button>

                    {isDone && !isEditing && (
                        <IconButton onClick={handleEdit}>
                            <EditIcon />
                        </IconButton>
                    )}

                    {isEditing && (
                        <IconButton onClick={handleSave}>
                            <SaveIcon />
                        </IconButton>
                    )}
                </Box>
            </Paper>

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={() => setSnackbarOpen(false)}
                message={snackbarMessage}
                severity={snackbarSeverity}
            />
        </Container>
    );
};

export default ProposalGenerator;
