import React, { useState } from "react";
import {
  Avatar,
  Box,
  Grid,
  Typography,
  IconButton,
} from "@mui/material";
import WorkIcon from "@mui/icons-material/Work"; // Office icon
import HomeIcon from "@mui/icons-material/Home"; // Remote work icon
import DeleteIcon from "@mui/icons-material/Delete"; // Delete icon
import EditNoteIcon from '@mui/icons-material/EditNote';
import { useNavigate } from "react-router-dom";
import ConfirmationDialog from "../../confirmations/confirmDailog";
import { toast, ToastContainer } from "react-toastify";
import { usePost } from "../../../helpers/axios/useApi";
import AddOrUpdateEmp from "./addOrUpdateEmp"; // Ensure this is the correct import path

const calculateExperience = (createdAt) => {
  const createdDate = new Date(createdAt);
  const now = new Date();

  let years = now.getFullYear() - createdDate.getFullYear();
  let months = now.getMonth() - createdDate.getMonth();

  if (months < 0) {
    years--;
    months += 12;
  }

  let experience = "";
  if (years > 0) {
    experience += `${years}y `;
  }
  if (months > 0) {
    experience += `${months}m`;
  } else if (years === 0) {
    experience += "0.2y"; // To show something for less than a year
  }

  return experience.trim();
};

function EmployeeList({ employees }) {
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);
  const [employeeToDelete, setEmployeeToDelete] = useState(null);
  const [deleteableEmployee, setDeleteableEmployee] = useState(null);
  const [openModalUpdate, setOpenModalUpdate] = useState(false);
  const [editEmpId, setEditEmpId] = useState(null);
  
  const { mutate: deleteEmployee } = usePost('/employee/delete');

  const handleProfileClick = (id) => {
    navigate(`/employees/${id}`);
  };

  const handleDeleteClick = (employeeId) => {
    setEmployeeToDelete(employeeId);
    setOpenDialog(true);
  };

  const handleCloseUpdateModal = () => {
    setOpenModalUpdate(false);
    setEditEmpId(null);
  };

  const handleEditClick = (employeeId) => {
    setOpenModalUpdate(true);
    setEditEmpId(employeeId);
  };

  const handleConfirmDelete = async () => {
    const id = deleteableEmployee.id;
    const result = await deleteEmployee({ id });

    if (result) {
      toast.success(result?.data?.message);
      setOpenDialog(false);
      setEmployeeToDelete(null);
      // refetch(); // Uncomment if you have a refetch function
    } else {
      toast.error(result?.error?.error);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setEmployeeToDelete(null);
  };
  const formatPositionAbbreviation = (position) => {
    if (!position) return ''; // Return empty string if no position
  
    // Split the position into words
    const words = position.split(' ');
  
    // If there are 2 or fewer words, return the full position
    if (words.length <= 1) {
      return position;
    }
  
    // Otherwise, create the abbreviation (first letter of each word, uppercase)
    return words
      .map(word => word.charAt(0).toUpperCase())  // Get first letter of each word and capitalize it
      .join('');  // Join the letters without spaces
  };
  return (
    <>
      <Box sx={{
        height: "70vh",
        overflow: "auto",
        padding: "20px",
      }}>
      <ToastContainer />
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          height: "30vh",
          gap: "2rem",
        }}
      >
        {employees?.map((data) => (
          <Grid
            container
            spacing={2}
            key={data._id}
            alignContent={"center"}
            sx={{
              width: "100%",
              backgroundColor: "white",
              padding: "0px",
              paddingLeft: "20px",
              cursor: "pointer",
              borderRadius: "25px",
              transition: "background-color 0.3s",
              "&:hover": {
                backgroundColor: "#f0f0f0",
              },
            }}
            onClick={() => handleProfileClick(data._id)}
          >
            <Grid item xs={3} container spacing={2} alignItems="center" gap={2}>
              <Avatar
                src={data.avatar || ''}
                sx={{ width: 30, height: 30 }}
              />
              <Grid>
                <Typography variant="h6" sx={{ fontSize: "14px", color: "black", textAlign: "left" }}>
                  {data?.name}
                </Typography>
                <Typography variant="h6" sx={{ fontSize: "14px", color: "grey", textAlign: "left" }}>
                  {data?.email}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={2} mb={2}>
              <Typography textAlign={"left"} fontSize={"12px"} color={"grey"}>
                Role
              </Typography>
              <Grid sx={{ display: "flex", gap: "1rem" }}>
                <Typography variant="h5" sx={{ fontSize: "16px", textAlign: "left" }}>
                  {data.role}
                </Typography>
                <Typography sx={{
                  fontSize: "12px",
                  border: "1px solid grey",
                  textAlign: "center",
                  width: "max-content",
                  height: "max-content",
                  padding: "1px 3px",
                  color: "grey",
                  borderRadius: "6px",
                }}>
                  {formatPositionAbbreviation(data?.position)}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="h6" sx={{ fontSize: "14px", color: "grey", textAlign: "left" }}>
                Status
              </Typography>
              <Typography variant="h5" sx={{
                fontSize: "16px",
                textAlign: "left",
                display: "flex",
                alignItems: "center",
                gap: "8px",
              }}>
                <Box sx={{
                  width: "8px",
                  height: "8px",
                  borderRadius: "50%",
                  backgroundColor: data.status === "active" ? "green" : "red",
                }} />
                {data?.status}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="h6" sx={{ fontSize: "14px", color: "grey", textAlign: "left" }}>
                Experience
              </Typography>
              <Typography variant="h5" sx={{ fontSize: "16px", textAlign: "left" }}>
                {calculateExperience(data.createdAt)}
              </Typography>
            </Grid>
            <Grid item xs={1.5}>
              <Typography variant="h6" sx={{ fontSize: "14px", color: "grey", textAlign: "left" }}>
                Type
              </Typography>
              <Grid container spacing={0} alignItems="center" sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
                <Typography variant="h5" sx={{
                  fontSize: "16px",
                  textAlign: "left",
                  display: "flex",
                  alignItems: "center",
                  gap: "12px",
                }}>
                  {data.workType === "remote" ? (
                    <>
                      <HomeIcon sx={{ color: "blue", fontSize: "18px" }} />
                      Remote
                    </>
                  ) : data.workType === "office" ? (
                    <>
                      <WorkIcon sx={{ color: "grey", fontSize: "18px" }} />
                      Office
                    </>
                  ) : (
                    <>
                      <WorkIcon sx={{ color: "grey", fontSize: "18px" }} />
                      <HomeIcon sx={{ color: "blue", fontSize: "18px" }} />
                      Hybrid
                    </>
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={0.5}>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  handleDeleteClick(data._id);
                  setDeleteableEmployee({
                    id: data._id,
                    name: data.name,
                  });
                }}
                sx={{ color: "red" }}
              >
                <DeleteIcon />
              </IconButton>
            </Grid>
            <Grid item xs={0.5}>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  handleEditClick(data._id);
                }}
                sx={{ color: "green" }}
              >
                <EditNoteIcon />
              </IconButton>
            </Grid>
          </Grid>
        ))}
         <ConfirmationDialog
        open={openDialog}
        onClose={handleCloseDialog}
        onConfirm={handleConfirmDelete}
        message={`Are you sure you want to delete ${deleteableEmployee?.name}?`}
      />
      <AddOrUpdateEmp 
        openModalUpdate={openModalUpdate}
        onClose={handleCloseUpdateModal}
        empId={editEmpId} // Updated to use editEmpId
      />
      </Box>
     
      </Box>
    </>
    
  );
}

export default EmployeeList;
