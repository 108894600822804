import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography, Grid, Box, Dialog } from '@mui/material';
import { Container, styled } from '@mui/system';
import { useGet } from '../../../../helpers/axios/useApi';
import { useAuth } from '../../../../middlewares/auth';
import { Button } from 'reactstrap';
import { useTheme } from '@emotion/react';
import InvoiceForm from '../invoice/invoiceForm';

// Styled Card with soft background, rounded corners, and subtle shadow
const StyledCard = styled(Card)(({ theme }) => ({
  width: '250px', // Smaller card size
  borderRadius: '12px', // Rounded corners
  boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1)', // Soft shadow
  background: 'linear-gradient(145deg, #f1f4f7, #e0e8f0)', // Soft gradient background
  padding: '15px', // Padding for spacing
  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
  '&:hover': {
    transform: 'translateY(-5px)', // Slight lift on hover
    boxShadow: '0 6px 12px rgba(0, 0, 0, 0.15)', // Increase shadow on hover
  },
}));

// Styled Grid Item for modern spacing and layout
const StyledGridItem = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  padding: '6px',
  textAlign: 'left',
}));

// Enhanced Typography with modern styles
const StyledTypography = styled(Typography)(({ theme }) => ({
  fontWeight: '400',
  fontSize: '14px',
  lineHeight: '1.5',
  letterSpacing: '0.5px',
  color: '#555', // Soft dark gray color
}));

// PaymentCard Component
function PaymentCard({ payment ,leadDetail}) {
  const {user} = useAuth()
  const [invoiceOpen, setInvoiceOpen] = useState(false);
  const [invoiceData, setInvoiceData] = useState([]);
  // Format the payment date
  const formattedDate = new Date(payment.paymentDate).toLocaleDateString();
  const { data: employees } = useGet(
    "employee/all",
    {},
    {},
    { queryKey: "employees" }
  );

  const {data:invoice} = useGet('leads/payment/invoice-get',{leadId : leadDetail._id},{}, {queryKey: 'invoice'})
  console.log(invoice)

  useEffect(() => {
      if (invoice?.data?.data) {
          setInvoiceData(invoice?.data?.data)
      }
  })
  const handleInvoiceClose = () => {
    setInvoiceOpen(false);
}

const handleInvoiceOpen = () => {
  setInvoiceOpen(true);
}
const handleDownload = () => {
  // Create an invisible link element
  const link = document.createElement("a");
  link.target = "_blank";
  // Set the download attribute and PDF URL
  link.href = invoiceData?.invoiceFile;
  link.download = "Invoice.pdf";  // You can customize the filename
  
  // Trigger the download by simulating a click on the link
  link.click();
};

  const getEmployeeName = (id) => {
    let employee = employees?.data?.message[0]?.find(
      (employee) => employee._id === `${id}`
    );
    return employee ? `${employee.name}` : user?.companyName;
  };

  return (
    <>
    <StyledCard>

           
     
      <CardContent>
        <Typography variant="h6" fontWeight="bold" gutterBottom>
          Payment Details
        </Typography>

        <Grid container spacing={2}>
        <StyledGridItem item xs={12}>
            <StyledTypography>
              <strong>Service Name:</strong> {payment.serviceName}
            </StyledTypography>
          </StyledGridItem>
          {/* Transaction ID */}
          <StyledGridItem item xs={12}>
            <StyledTypography>
              <strong>Transaction ID:</strong> {payment.transactionId}
            </StyledTypography>
          </StyledGridItem>

          {/* Payment Amount */}
          <StyledGridItem item xs={12}>
            <StyledTypography>
              <strong>Amount:</strong> {payment.amount} {payment.currency}
            </StyledTypography>
          </StyledGridItem>

          {/* Payment Date */}
          <StyledGridItem item xs={12}>
            <StyledTypography>
              <strong>Payment Date:</strong> {formattedDate}
            </StyledTypography>
          </StyledGridItem>

          {/* Payment Method */}
          <StyledGridItem item xs={12}>
            <StyledTypography>
              <strong>Payment Method:</strong> {payment.paymentMethod}
            </StyledTypography>
          </StyledGridItem>

          {/* Sender and Receiver Account Numbers */}
          <StyledGridItem item xs={12}>
            <StyledTypography>
              <strong>Sender Account:</strong> {payment.senderAccountNumber}
            </StyledTypography>
            <StyledTypography>
              <strong>Receiver Account:</strong> {payment.receiverAccountNumber}
            </StyledTypography>
          </StyledGridItem>

          {/* Tax and Discount */}
          <StyledGridItem item xs={12}>
            <StyledTypography>
              <strong>Tax (%):</strong> {payment.taxPercent}%
            </StyledTypography>
            <StyledTypography>
              <strong>Discount (%):</strong> {payment.discountPercent}%
            </StyledTypography>
          </StyledGridItem>

          {/* Payment Notes */}
          <StyledGridItem item xs={12}>
            <StyledTypography>
              <strong>Notes:</strong> {payment.paymentNotes}
            </StyledTypography>
          </StyledGridItem>

          {/* Processed By */}
          <StyledGridItem item xs={12}>
            <StyledTypography>
              <strong>Processed By:</strong> {getEmployeeName(payment?.processedBy) }
            </StyledTypography>
          </StyledGridItem>
        </Grid>
      </CardContent>
           <Grid sx={{
            display:'flex',
            flexDirection:'row',
            justifyContent:'space-between',
            gap:'1rem'
           }}>
           <Button style={{
              backgroundColor:useTheme().palette.primary.main,
              padding:'5px 10px',
              fontSize:'14px',
              borderRadius:'5px',
              border:'none',
              color:'white',
              cursor:'pointer'
               
            }}
            onClick={handleInvoiceOpen}
            >
                  Create Invoice
            </Button>
            <Button
       style={{
        backgroundColor:useTheme().palette.primary.main,
        padding:'5px 10px',
        fontSize:'14px',
        borderRadius:'5px',
        border:'none',
        color:'white',
        cursor:'pointer'
         
      }}
        onClick={handleDownload}
        
      >
        Download PDF
      </Button>
           </Grid>

    </StyledCard>
    <Dialog open={invoiceOpen} onClose={handleInvoiceClose} PaperProps={{sx:{minWidth:'100vh', padding:2}}}>
      <Container>
        <InvoiceForm leadDetail={leadDetail} payment={payment}/>
      </Container>
      </Dialog>
    </>
  );
}

export default PaymentCard;
