import { useState } from "react";
import { usePost } from "../../../helpers/axios/useApi";
import { useAuth } from "../../../middlewares/auth";

const ApproveAbsent = ({employee, date, approved}) => {
    const {user} = useAuth()
    const { mutate: saveRecord, isLoading: isSavingRecord } = usePost('employee/work-tracking/save', {}, 'dailyRecords');

    // Handle the approval and rejection actions (update the status in your system)
 const handleAction = async (_id, action) => {
       try { 
        const save = await saveRecord({
            employeeId: employee._id,
            approvedBy : user._id,
            date : date,
        })
        approved()
    } catch (error) {
        console.log(error)
    }
       
 }

    return (
        <>
    
                <div
                    style={{
                        display: 'inline-block',
                        position: 'relative',
                        padding: '10px',
                        borderRadius: '5px',
                        cursor: 'pointer',
                    }}
                    className="leave-status"
               
                >
             <div
                            className="popover"
                            style={{
                                position: 'absolute',
                                top: '100%',
                                right: '0',
                                borderRadius: '5px',
                                padding: '10px',
                                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                                zIndex: '1000',
                                width: '150px',
                                textAlign: 'center',
                            }}
                        >
                           
                            <button
                                onClick={() => handleAction(employee._id, 'Approved')}
                                style={{
                                    backgroundColor: 'green',
                                    color: '#fff',
                                    border: 'none',
                                    padding: '5px',
                                    marginBottom: '5px',
                                    cursor: 'pointer',
                                    width: '100%',
                                }}
                            >
                                ✔️ Approve
                            </button>
                           
                        </div>
                    
                </div>

        </>
    );
};

export default ApproveAbsent;